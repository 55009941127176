import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  Table,
} from 'react-bootstrap';
import valid from 'card-validator';
import XAlertMessage from '../../../Common/AlertMessage/XAlertMessage';
import InLineErrorMessage from '../../../Common/AlertMessage/InLineErrorMessage';
import {
  RENEW_SUBSCRIPTION_DETAIL,
  RENEW_SUBSCRIPTION,
  COUPON_APPLIED,
  RENEW_SUBSCRIPTION_PAYMENT,
} from '../../../../GraphApi/Checkout';
import { COUNTRY_LIST } from '../../../../GraphApi/ContactList';
import {
  getNumberByUserFormat,
  getCurrencyByUserFormat,
} from '../../../../Utils/CurrentSetting';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  getValidationMessage,
  detectCardType,
  cardTypes,
  scrollToTop,
  getMonthList,
  monthList,
  yearList,
  isObjectEmpty,
  createMarkup,
  getTermsAnPolicy,
  currentTeamId,
} from '../../../../Utils/utils';
import EqupRoundLoader from '../../../Common/Loader/RoundLoader';
import { nonGroupColourStyles } from '../../../Common/DropDown/Style';
import {
  curentDateObject,
  getDateformat,
} from '../../../../Utils/EqupTimezone';

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/im;
const nameRegExp = /^[a-zA-Z0-9-.\\s ]*$/gm;
export const UpgradePlan = ({
  subscriptionId,
  locale,
  upgradeToPlan = null,
  subscribedPlan,
  selectedPlanPrice,
  futureMonth = null,
  planType = 'monthly',
  upgradeError = null,
  upgradePlanSubscription = () => {},
}) => {
  const refDrawer = useRef(null);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  /*****/
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState();
  const [oneTimeFee, setOneTimeFee] = useState('');
  const [discount, setDiscount] = useState('');
  const [subTotal, setSubTotal] = useState('');
  const [couponApplied, setCouponApplied] = useState('');
  const addressField = ['address', 'country', 'city', 'state', 'zip_code'];
  const [userSetting, setUserSetting] = useState(null);
  const [countries, setCountries] = useState([]);
  const [status, setStatus] = useState(null);
  const [states, setStates] = useState([]);
  const [countryWiseStates, setCountryWiseStates] = useState([]);
  const [redirectUrl] = useState(null);
  const [isRedirect, setIsRedirect] = useState(false);
  const [pageType] = useState('checkout');
  const [settlementDetail, setSettlementDetail] = useState(null);
  const [walletAmount] = useState(0);
  /** QUERIES & MUTATION DECLARATION **/
  /** Country List **/
  const [getCountry, { data: allCountries, loading: fetchingCountries }] =
    useLazyQuery(COUNTRY_LIST);

  const [
    getSubscriptionDetailsForRenew,
    { data: subscriptionData, loading: pageLoading },
  ] = useLazyQuery(RENEW_SUBSCRIPTION_DETAIL, { fetchPolicy: 'network-only' });
  const [renewSubscription] = useMutation(RENEW_SUBSCRIPTION);
  const [checkCoupon] = useMutation(COUPON_APPLIED);
  const [renewSubscriptionPayment] = useMutation(RENEW_SUBSCRIPTION_PAYMENT);

  useEffect(() => {
    getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /******/
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    last_name: Yup.string()
      .required('Last Name is required')
      .min(2, 'Last name min 2 character long!')
      .max(50, 'Last name max 50 character long!')
      .matches(
        nameRegExp,
        'Last name can contains characters numbers space . -'
      ),
    first_name: Yup.string()
      .max(50, 'First name max 50 character long!')
      .matches(
        nameRegExp,
        'First name can contains characters numbers space . -'
      ),
    phone: Yup.string()
      .nullable(true)
      .matches(phoneRegExp, 'Phone number is not valid'),

    address: Yup.string()
      .max(255, 'Address max 255 character long!')
      .matches(
        '^[a-zA-Z0-9-#,_.\\s /]*$',
        'Address can contains # , - _  / . characters only.'
      ),
    city: Yup.string()
      .max(50, 'City name max 50 character long!')
      .matches('^[a-zA-Z0-9 /s]*$', 'Special character not allowed.'),
    state: Yup.string()
      .max(50, 'State name max 50 character long!')
      .matches('^[a-zA-Z0-9 /s]*$', 'Special character space not allowed.'),

    country: Yup.string()
      .max(50, 'Country name max 50 character long!')
      .matches('^[a-zA-Z0-9 /s]*$', 'Special character space not allowed.'),

    zip_code: Yup.string()
      .min(5, 'Zip code should be min 5 character long!')
      .matches(
        '^[a-zA-Z0-9]*$',
        'Special character and white space not allowed.'
      ),
    nameOnCard: Yup.string().test(
      'match', // this is used internally by yup
      'Name on card is invalid', //validation message
      (value) => {
        if (isObjectEmpty(value)) {
          return false;
        } else {
          return true;
        }
      }
    ),
    card_number: Yup.string().test(
      'match', // this is used internally by yup
      'Credit Card number is invalid', //validation message
      (value) => {
        if (
          subscriptionDetail &&
          (subscriptionDetail.trial_period > 0 ||
            subscriptionDetail.price === 0)
        ) {
          return true;
        } else {
          return valid.number(parseInt(value)).isValid;
        }
      }
    ),
    month: Yup.string()
      .max(2, 'Month must be four digit long')
      .test(
        'match', // this is used internally by yup
        'Month is invalid', //validation message
        (value) => {
          if (
            subscriptionDetail &&
            (subscriptionDetail.trial_period > 0 ||
              subscriptionDetail.price === 0)
          ) {
            return true;
          }
          return valid.expirationMonth(value).isValid;
        }
      ),
    year: Yup.string()
      .max(4, 'Year must be four digit long')
      .test(
        'match', // this is used internally by yup
        'Year is invalid', //validation message
        (value) => {
          if (
            subscriptionDetail &&
            (subscriptionDetail.trial_period > 0 ||
              subscriptionDetail.price === 0)
          ) {
            return true;
          }
          return valid.expirationYear(value).isValid;
        }
      ),
    cvv: Yup.string()
      .max(3, 'CVV must be four digit long')
      .test(
        'match', // this is used internally by yup
        'CVV number is invalid', //validation message
        (value) => {
          if (
            subscriptionDetail &&
            (subscriptionDetail.trial_period > 0 ||
              subscriptionDetail.price === 0)
          ) {
            return true;
          }
          const maxLength = formik.values.card_type === 'amex' ? 4 : 3;
          return valid.cvv(value, maxLength).isValid;
        }
      ),
    accept_recurring: Yup.bool().oneOf(
      [true],
      'Check the checkbox, To authorize this card to be charged for recurring billing.'
    ),
    tos: Yup.bool().oneOf(
      [true],
      'Check the checkbox, To agree the terms and conditions.'
    ),
    non_refundable: Yup.bool().oneOf(
      [true],
      'Check the checkbox, To confirm you understand this transaction is non refundable.'
    ),
  });

  /*** CALL USE-EFFECT METHODS****/
  useEffect(() => {
    if (allCountries && Object.keys(allCountries).length) {
      let countryList = [];
      let states = [];
      allCountries.countryList.country.forEach((country) => {
        /** Set all Country List **/
        countryList.push({
          id: country.id,
          value: country.code,
          label: country.name,
          code: country.code,
        });
      });

      allCountries.countryList.state.forEach((state) => {
        /** Set All States **/
        states.push({
          value: state.name,
          label: state.name,
          countryId: state.country_id,
        });
      });
      setCountries(countryList);
      setStates(states);
    }
  }, [allCountries]);

  /** CALL SUBSCRIPTIOn DETAIL **/
  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionDetailsForRenew({
        variables: {
          subscription_id: subscriptionId,
          upgrade_to_plan: upgradeToPlan,
        },
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId]);

  /** FETCH SUBSCRIPTION DETAIL **/
  useEffect(() => {
    if (subscriptionData) {
      const details = subscriptionData.subscriptionDetailForRenewSubscription;
      if (details) {
        /****/
        const setting = {
          number_format: details.number_format,
          currency: details.currency,
          sandbox_mode: details.sandbox_mode,
        };
        setUserSetting(setting);
        setStatus(details.status && !details.plan_detail ? 'paid' : false);
        setSubscriptionDetail(details);
        let fee = 0;
        if (
          details.fee_name &&
          details.fee_value &&
          details.fee_charge_on === -1
        ) {
          fee = details.fee_value;
          setOneTimeFee({ name: details.fee_name, value: details.fee_value });
        }

        setSubTotal(fee + details.price);
        // setTotal(fee + details.price);
        /*****/
        Object.keys(details).forEach((item) => {
          if (item === 'billing_address') {
            addressField.forEach((addressItem) => {
              if (addressItem === 'country') {
                handleCountryByValue(
                  details['billing_address']['billing_country']
                );
              } else if (
                addressItem === 'billing_zip' ||
                addressItem === 'zip_code'
              ) {
                formik.setFieldValue(
                  [addressItem],
                  details[item]['billing_zip']
                    ? details[item]['billing_zip']
                    : ''
                );
              } else {
                formik.setFieldValue(
                  [addressItem],
                  details[item]['billing_' + addressItem]
                    ? details[item]['billing_' + addressItem]
                    : ''
                );
              }
            });
          } else {
            formik.setFieldValue([item], details[item]);
          }
        });
      } else {
        setStatus('error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData, countries]);

  /******/
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      nameOnCard: userSetting && userSetting.sandbox_mode ? 'Dummy card' : '',

      card_number:
        userSetting && userSetting.sandbox_mode ? '4111111111111111' : '',
      cvv: userSetting && userSetting.sandbox_mode ? '121' : '',
      month: userSetting && userSetting.sandbox_mode ? '12' : '',
      year: userSetting && userSetting.sandbox_mode ? '2030' : '',
      phone: '',
      job_title: '',
      date_of_birth: '',
      mobile: '',
      website: '',
      fax: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      card_type: userSetting && userSetting.sandbox_mode ? 'visa' : '',
      coupon: '',
      accept_recurring: false,
      tos: false,
      non_refundable: false,
    },
    validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      'nameOnCard',
      userSetting && userSetting.sandbox_mode ? 'Dummy card' : ''
    );
    formik.setFieldValue(
      'card_number',
      userSetting && userSetting.sandbox_mode ? '4111111111111111' : ''
    );
    formik.setFieldValue(
      'cvv',
      userSetting && userSetting.sandbox_mode ? '121' : ''
    );
    formik.setFieldValue(
      'month',
      userSetting && userSetting.sandbox_mode ? '12' : ''
    );
    formik.setFieldValue(
      'year',
      userSetting && userSetting.sandbox_mode ? '2030' : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSetting]);

  useEffect(() => {
    if (formik.values.card_number) {
      formik.setFieldValue(
        'card_type',
        detectCardType(formik.values.card_number)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.card_number]);

  const processData = (data) => {
    setIsSubmitting(true);
    let dataToBeProcess = {
      ...data,
      coupon: couponApplied,
      subscription_id: subscriptionId,
      billing_address: {
        billing_address: data.address,
        billing_city: data.city,
        billing_state: data.state,
        billing_zip: data.zip_code,
        billing_country: data.country,
      },
    };
    // console.log("Here::DATA::", pageType, dataToBeProcess);
    if (upgradeToPlan) {
      dataToBeProcess = {
        ...dataToBeProcess,
        plan_id: upgradeToPlan,
        billing_type: planType,
        team_id: currentTeamId,
      };
      console.log('dataToBeProcess: ', dataToBeProcess);
      upgradePlanSubscription(dataToBeProcess);
      setIsSubmitting(false);
    } else if (pageType === 'failedSubscription') {
      renewSubscriptionPayment({
        variables: dataToBeProcess,
      })
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.checkoutPaymentFailedSubscription &&
            result.data.checkoutPaymentFailedSubscription.status
          ) {
            handleAlertMessage('success', 'Subscription renew successfully');
            setStatus('completed');
            window.location.reload();
          } else if (
            result &&
            result.data &&
            result.data.checkoutPaymentFailedSubscription &&
            !result.data.checkoutPaymentFailedSubscription.status &&
            result.data.checkoutPaymentFailedSubscription.message
          ) {
            handleAlertMessage(
              'error',
              'result.data.checkoutPaymentFailedSubscription.message'
            );
          }
          formik.resetForm();
          setIsSubmitting(false);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(error, graphQLErrors);
          handleAlertMessage('error', getValidationMessage(graphQLErrors));
          setIsSubmitting(false);
        });
    } else {
      renewSubscription({
        variables: dataToBeProcess,
      })
        .then((result) => {
          handleAlertMessage('success', 'Subscription purchased successfully');
          formik.resetForm();
          setStatus('completed');
          setIsSubmitting(false);
          window.location.reload();
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log(error, graphQLErrors);
          handleAlertMessage('error', getValidationMessage(graphQLErrors));
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (upgradeError) {
      handleAlertMessage('error', upgradeError);
    }
  }, [upgradeError]);

  /** This Methods use in Formik Only ***/
  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
    scrollToTop(refDrawer);
  };
  /** GetPlan Value  **/
  const getPlanValue = () => {
    return subscriptionDetail.price;
  };

  const resetAmount = () => {
    setCouponApplied('');
    setDiscount('');
    formik.setFieldValue('coupon', '');
  };

  useEffect(() => {
    calculateAmountForUpgrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedPlan, selectedPlanPrice, walletAmount, planType]);

  const calculateAmountForUpgrade = () => {
    if (subscribedPlan) {
      const startDate = getDateformat(subscribedPlan.starts_at);
      const endDate = getDateformat(subscribedPlan.expiry_date);
      const result = endDate.diff(startDate, 'days');
      const planDurationInDays = result + 1;
      const remainingDaysOfPlan =
        endDate.diff(curentDateObject().utc(), 'days') + 1;
      let amountForRemainingDays = 0;

      if (subscribedPlan.actual_amount_charged > 0) {
        const perDayCost = subscribedPlan.price / planDurationInDays;
        amountForRemainingDays = perDayCost * remainingDaysOfPlan;
      }

      const planPrice =
        selectedPlanPrice &&
        selectedPlanPrice.plan &&
        selectedPlanPrice.plan.price > 0
          ? planType === 'yearly'
            ? selectedPlanPrice.plan.yearly_price
            : selectedPlanPrice.plan.price
          : 0;

      let amountToPay = 0;
      let remainingWalletAmount = 0;
      const totalWalletAmount =
        (walletAmount ? walletAmount : 0) + amountForRemainingDays;

      if (planPrice >= totalWalletAmount) {
        amountToPay = planPrice - totalWalletAmount;
        remainingWalletAmount = 0;
      } else {
        remainingWalletAmount = totalWalletAmount - planPrice;
      }
      setSettlementDetail({
        remainingDaysOfPlan: remainingDaysOfPlan,
        amountForRemainingDays: parseFloat(amountForRemainingDays.toFixed(2)),
        amountToPay: parseFloat(amountToPay.toFixed(2)),
        planPrice: '$' + planPrice,
        remainingWalletAmount: parseFloat(remainingWalletAmount.toFixed(2)),
      });
    }
  };

  /** CHECK COUPON **/
  const applyCoupon = () => {
    checkCoupon({
      variables: {
        layout: subscriptionId,
        coupon: formik.values.coupon,
        email: formik.values.email,
        planId: parseInt(subscriptionDetail.plan_id),
        type: 'renew',
      },
    })
      .then((result) => {
        setCouponApplied(formik.values.coupon);
        const appliedCoupon = result.data.couponCheckCode;
        if (appliedCoupon.discount_type === 'fixed') {
          if (['plan', 'product'].indexOf(appliedCoupon.applies_to) > -1) {
            const totalAmount = getPlanValue();
            setDiscount(
              parseFloat(appliedCoupon.amount) > totalAmount
                ? totalAmount
                : parseFloat(appliedCoupon.amount)
            );
          } else {
            const totalAmount = subTotal;
            setDiscount(
              parseFloat(appliedCoupon.amount) > totalAmount
                ? totalAmount
                : parseFloat(appliedCoupon.amount)
            );
          }
        } else if (
          appliedCoupon.discount_type === 'percent' &&
          ['plan', 'product'].indexOf(appliedCoupon.applies_to) > -1
        ) {
          const totalAmount = getPlanValue();
          setDiscount((parseInt(appliedCoupon.amount) * totalAmount) / 100);
        } else {
          let totalAmount = subTotal;
          setDiscount((parseInt(appliedCoupon.amount) * totalAmount) / 100);
        }
        handleAlertMessage('success', 'Coupon Applied successfully');
      })
      .catch((error) => {
        resetAmount();
        let { graphQLErrors } = error;
        handleAlertMessage('error', getValidationMessage(graphQLErrors));
      });
  };
  /***Set Formik Field Error ***/
  const hasError = (name) => {
    return formik.touched[name] && formik.errors[name] ? 'is-invalid' : '';
  };
  /***Set Formik Field Value ***/
  const hasValue = (name) => {
    return formik.values[name] ? formik.values[name] : '';
  };

  const setValue = (name, event) => {
    formik.setFieldValue(name, event.target.value);
  };

  const setNumberFormat = (value, type = 'number') => {
    if (value > 0) {
      return getNumberByUserFormat(userSetting, value, type);
    } else {
      return getCurrencyByUserFormat(userSetting) + '0';
    }
  };
  /**Country Bound with Country DialCode **/
  const handleCountry = (county) => {
    countries.forEach((country) => {
      if (county.id === country.id) {
        formik.setFieldValue('country', country.value);
      }
    });
    const stateList = states.filter(
      (state) => parseInt(state.countryId) === parseInt(county.id) && state
    );
    formik.setFieldValue('state', '');
    setCountryWiseStates(stateList);
  };

  const handleCountryByValue = (value) => {
    let countryId = null;
    countries.forEach((country) => {
      if (value === country.value || value === country.id) {
        countryId = country.id;
        formik.setFieldValue('country', country.value);
      }
    });

    const stateList = states.filter(
      (state) => parseInt(state.countryId) === parseInt(countryId) && state
    );

    formik.setFieldValue('state', '');
    setCountryWiseStates(stateList);
  };
  /*** RENDER HTML *****/

  if (fetchingCountries && pageLoading && countries && countries.length === 0) {
    return <EqupRoundLoader />;
  }
  if (status === 'error') {
    return (
      <div
        className='container'
        style={{ textAlign: 'center', margin: '100px auto' }}
      >
        <h5 className='text-center border-all p-2 bg-light m-5'>
          Subscription not found.
        </h5>
      </div>
    );
  }

  if (['paid', true].indexOf(status) > -1) {
    return (
      <div
        className='container'
        style={{ textAlign: 'center', margin: '100px auto' }}
      >
        <h5 className='text-center border-all p-2 bg-light m-5'>
          Subscription already paid.
        </h5>
      </div>
    );
  }

  if (status === 'completed') {
    if (redirectUrl !== null) {
      setTimeout(() => {
        setIsRedirect(true);
      }, 3000);
    }
    return (
      <div
        className='container'
        style={{ textAlign: 'center', margin: '100px auto' }}
      >
        <h5 className='text-center border-all p-2 bg-light m-5'>
          Subscription paid successfully.
        </h5>
        {redirectUrl !== null && <p>Redirecting...</p>}
      </div>
    );
  }

  if (isRedirect && status === 'completed' && redirectUrl !== null) {
    window.location.href = redirectUrl;
  }

  return (
    <>
      <Container>
        <Row className={''}>
          <Col className={''}>
            <Card className={'form-white-card'}>
              <Card.Body ref={refDrawer}>
                <XAlertMessage
                  type={alertType}
                  message={alertMessage}
                  setAlertShow={setAlertShow}
                  alertShow={alertShow}
                />
                <Row className={''}>
                  <Col className={''}>
                    <h4 className={'font-weight-semibold mb-4 text-primary'}>
                      Your payment information
                    </h4>
                    <Form onSubmit={formik.handleSubmit}>
                      {userSetting && userSetting.sandbox_mode && (
                        <div className='note-box'>
                          This is under SandBox for test Payment
                        </div>
                      )}
                      <Row>
                        <Col>
                          <Form.Group className='form-group'>
                            <Form.Label>Name on Card</Form.Label>
                            <div className='field-icon-right'>
                              <Form.Control
                                className={hasError('nameOnCard')}
                                id={'nameOnCard'}
                                type='text'
                                placeholder='Enter name on card'
                                value={hasValue('nameOnCard')}
                                onChange={(e) => {
                                  setValue('nameOnCard', e);
                                }}
                              />
                            </div>
                            <InLineErrorMessage
                              type='error'
                              name={'nameOnCard'}
                              formik={formik}
                              id={'nameOnCard'}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='form-group'>
                            <Form.Label>Card Number</Form.Label>
                            <div className='field-icon-right'>
                              <Form.Control
                                className={hasError('card_number')}
                                id={'card_number'}
                                type='text'
                                placeholder='Enter card number'
                                defaultValue={hasValue('card_number')}
                                onChange={(e) => {
                                  setValue('card_number', e);
                                }}
                              />
                              {formik.values.card_type && (
                                <span className='icon-right'>
                                  <img
                                    src={cardTypes[formik.values.card_type]}
                                    alt={formik.values.card_type}
                                  />
                                </span>
                              )}
                            </div>
                            <InLineErrorMessage
                              type='error'
                              name={'card_number'}
                              formik={formik}
                              id={'card_number'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs='6'
                          sm='4'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>Month</Form.Label>
                            <Select
                              options={getMonthList()}
                              id='id41'
                              placeholder='Select Month'
                              className={hasError('month')}
                              value={monthList.filter(
                                (item) =>
                                  formik.values.month &&
                                  parseInt(item.value) ===
                                    parseInt(formik.values.month)
                              )}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'month',
                                  e.value.toString()
                                );
                              }}
                            />

                            <InLineErrorMessage
                              type='error'
                              name={'month'}
                              formik={formik}
                              id={'month'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='6'
                          sm='4'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>Year</Form.Label>
                            <Select
                              id='id46'
                              options={yearList}
                              placeholder='Select Year'
                              className={hasError('year')}
                              value={yearList.filter(
                                (item) =>
                                  formik.values.year &&
                                  parseInt(item.value) ===
                                    parseInt(formik.values.year)
                              )}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'year',
                                  e.value.toString()
                                );
                              }}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'year'}
                              formik={formik}
                              id={'year'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='12'
                          sm='4'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>CVV</Form.Label>
                            <InputGroup className='mb-2'>
                              <FormControl
                                className={hasError('cvv')}
                                id={'cvv'}
                                placeholder='Enter CVV'
                                value={hasValue('cvv')}
                                onChange={(e) => setValue('cvv', e)}
                              />

                              <InputGroup.Text>
                                <i className={'ri-bank-card-line ri-lg'}></i>
                              </InputGroup.Text>
                            </InputGroup>
                            <InLineErrorMessage
                              type='error'
                              name={'cvv'}
                              formik={formik}
                              id={'cvv'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4
                        className={
                          'font-weight-semibold mt-4 mb-4 title-with-count text-primary'
                        }
                      >
                        Billing address
                      </h4>
                      <Row>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>Street</Form.Label>
                            <Form.Control
                              className={hasError('address')}
                              type='text'
                              id={'address'}
                              placeholder='Enter street'
                              value={hasValue('address')}
                              onChange={(e) => setValue('address', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'address'}
                              formik={formik}
                              id={'address'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              className={hasError('address')}
                              type='text'
                              placeholder='Enter city'
                              id={'city'}
                              value={hasValue('city')}
                              onChange={(e) => setValue('city', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'city'}
                              formik={formik}
                              id={'city'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>Country</Form.Label>
                            <Select
                              className={hasError('country')}
                              styles={nonGroupColourStyles}
                              options={countries}
                              placeholder={'Select Country'}
                              id='country'
                              name='country'
                              value={countries.filter(
                                (item) => item.value === formik.values.country
                              )}
                              onChange={(e) =>
                                // formik.setFieldValue("country", e.value);
                                handleCountry(e)
                              }
                              isSearchable
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'country'}
                              formik={formik}
                              id={'country'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>State</Form.Label>
                            <Select
                              styles={nonGroupColourStyles}
                              className={hasError('state')}
                              options={countryWiseStates}
                              placeholder={'Select State'}
                              id='state'
                              name='state'
                              value={countryWiseStates.filter(
                                (item) => item.value === formik.values.state
                              )}
                              onChange={(e) =>
                                formik.setFieldValue('state', e.value)
                              }
                              isSearchable
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'state'}
                              formik={formik}
                              id={'state'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>Zip code</Form.Label>
                            <Form.Control
                              className={hasError('zip_code')}
                              type='text'
                              id={'zip_code'}
                              placeholder='Enter zip code'
                              value={hasValue('zip_code')}
                              onChange={(e) => setValue('zip_code', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'zip_code'}
                              formik={formik}
                              id={'zip_code'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col>
                          <Form.Group className='form-group mb-3'>
                            <Form.Check // prettier-ignore
                              type={'checkbox'}
                              id='terms_and_condition'
                              checked={formik.values.non_refundable}
                              label={
                                'I understand that this amount is not refundable.'
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  'non_refundable',
                                  !formik.values.non_refundable
                                );
                              }}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'non_refundable'}
                              formik={formik}
                              id={'non_refundable'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col>
                          <Form.Group className='form-group mb-3'>
                            <Form.Check // prettier-ignore
                              type={'checkbox'}
                              id='terms_and_condition'
                              checked={formik.values.tos}
                              label={createMarkup(getTermsAnPolicy(locale))}
                              onChange={() => {
                                formik.setFieldValue('tos', !formik.values.tos);
                              }}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'tos'}
                              formik={formik}
                              id={'tos'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col>
                          <Form.Group className='form-group mb-3'>
                            <Form.Check // prettier-ignore
                              type={'checkbox'}
                              id='accept_recurring'
                              checked={formik.values.accept_recurring}
                              label={
                                'I authorize to charge this card for recurring payment until I cancel.'
                              }
                              onChange={() => {
                                formik.setFieldValue(
                                  'accept_recurring',
                                  !formik.values.accept_recurring
                                );
                              }}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'accept_recurring'}
                              formik={formik}
                              id={'accept_recurring'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='mb-3 mb-md-0 text-end'>
                          <Button
                            variant='primary btn-lg mt-5 ps-5 pe-5 btn-ms-icon'
                            type='submit'
                            disabled={isSubmitting}
                          >
                            <span>Checkout Now</span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col sm={4}>
                    <h4 className={'font-weight-semibold mb-4 text-primary'}>
                      Purchase Details
                    </h4>
                    <Table className={'custom-table'}>
                      <tbody>
                        <tr>
                          <td className={'ps-0 pe-0 pt-3'}>
                            <h5 className={'font-weight-semibold mb-0'}>
                              Plan Name
                            </h5>
                            <small className={'text-extra-light'}>
                              {subscriptionDetail &&
                              subscriptionDetail.plan_detail
                                ? subscriptionDetail.plan_detail.name
                                : subscriptionDetail &&
                                    subscriptionDetail.plan_name
                                  ? subscriptionDetail.plan_name
                                  : ''}
                            </small>
                          </td>
                          <td className={'ps-0 pe-0 pt-3 text-end'}>
                            {settlementDetail
                              ? settlementDetail.planPrice
                              : subscriptionDetail && subscriptionDetail.price
                                ? setNumberFormat(
                                    subscriptionDetail.price,
                                    'currency'
                                  )
                                : ''}
                          </td>
                        </tr>

                        {futureMonth && (
                          <tr>
                            <td className={'ps-0 pe-0 pt-3'}>
                              <h5 className={'font-weight-semibold mb-0'}>
                                Next Bill Date
                              </h5>
                              <small className={'text-extra-light'}>
                                {planType ? planType : ''}
                              </small>
                            </td>
                            <td className={'ps-0 pe-0 pt-3 text-end'}>
                              {futureMonth}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td className={'ps-0 pe-0 pt-3 pb-3'}>
                            <span className='d-block'>Tax</span>
                            <span className='pt-2 d-block'>
                              {oneTimeFee.name ? oneTimeFee.name : ''}
                            </span>
                            <span className='pt-2 d-block'>Subtotal</span>
                            <span className='pt-2 d-block'>Discount</span>
                            {settlementDetail && (
                              <span className='pt-2 d-block'>
                                Credit Balance
                              </span>
                            )}
                            {settlementDetail && (
                              <span className='pt-2 d-block'>
                                Credit Adjustment
                              </span>
                            )}
                          </td>
                          <td className={'ps-0 pe-0 pt-3 pb-3 text-end'}>
                            <span className='d-block'>
                              {subscriptionDetail && subscriptionDetail.tax
                                ? setNumberFormat(
                                    subscriptionDetail.tax,
                                    'currency'
                                  )
                                : 'Not Applicable'}
                            </span>
                            <span className='pt-2 d-block'>
                              {oneTimeFee.value
                                ? setNumberFormat(oneTimeFee.value, 'currency')
                                : ''}
                            </span>
                            <span className='pt-2 d-block'>
                              {settlementDetail
                                ? settlementDetail.planPrice
                                : subscriptionDetail && subscriptionDetail.price
                                  ? setNumberFormat(
                                      subscriptionDetail.price,
                                      'currency'
                                    )
                                  : ''}
                            </span>
                            <span className='pt-2 d-block'>
                              {setNumberFormat(discount, 'currency')}
                            </span>
                            {settlementDetail && (
                              <span className='pt-2 d-block'>
                                {setNumberFormat(
                                  settlementDetail.amountForRemainingDays +
                                    settlementDetail.remainingWalletAmount,
                                  'currency'
                                )}
                              </span>
                            )}
                            {settlementDetail && (
                              <span className='pt-2 d-block'>
                                {setNumberFormat(
                                  settlementDetail.amountForRemainingDays +
                                    settlementDetail.remainingWalletAmount,
                                  'currency'
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                        {couponApplied ? (
                          <tr>
                            <td className='align-middle ps-0 font-weight-semibold'>
                              Coupon Applied
                            </td>
                            <td className='align-middle text-end pe-0'>
                              <span className='coupon-apply'>
                                <strong>{couponApplied}</strong>
                                <i
                                  className='ri-close-fill'
                                  onClick={resetAmount}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        ) : pageType === 'failedSubscription' ? (
                          <React.Fragment>
                            <tr>
                              <td
                                colSpan='2'
                                className={'ps-0 pe-0 pt-3 pb-3'}
                              >
                                Have a coupon code?
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan='2'
                                className={'ps-0 pe-0 pt-3 pb-3'}
                              >
                                <Row>
                                  <Col className={'pe-0'}>
                                    <Form.Control
                                      placeholder='Coupon code'
                                      size='sm'
                                      maxLength={16}
                                      value={formik.values.coupon}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          'coupon',
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col xs='auto'>
                                    <Button
                                      type='submit'
                                      variant='primary mb-2'
                                      onClick={applyCoupon}
                                    >
                                      Apply
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : (
                          ''
                        )}

                        <tr>
                          <td
                            className={
                              'ps-0 pe-0 pt-3 pb-3 font-weight-semibold'
                            }
                          >
                            Total
                          </td>
                          <td
                            className={
                              'ps-0 pe-0 pt-3 pb-3 text-end font-weight-semibold'
                            }
                          >
                            {'$' +
                              (settlementDetail
                                ? settlementDetail.amountToPay
                                : 0)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <div className={''}>
              <div className={''}></div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
