import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { G2_REVIEW, G2_REVIEW_SUBMIT } from '../../GraphApi/Review';
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from '../../Utils/utils';
import { EqupRoundLoaderWithOverlay } from '../../Components/Common/Loader/RoundLoader';
import Session from '../../Utils/Session';

const defaultCss = { margin: '0 auto', background: '#fff', display: 'table' };
const width = {
  desktop: {
    ...defaultCss,
    position: 'relative',
    width: '100%',
    height: '500px',
  },
};
const ReviewPopup = () => {
  const iFrameRef = useRef(null);
  const { t: locale } = useTranslation(['common', 'pages']);
  const [show, setShow] = useState(false);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [url, setUrl] = useState(null);
  /******************/
  const [getG2ReviewForm, { loading }] = useMutation(G2_REVIEW);
  const [setReviewStatus] = useMutation(G2_REVIEW_SUBMIT);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_REVIEW === 'true') {
      const popupShowInMinutes = 2 * 60 * 1000;
      setTimeout(() => {
        checkSessionExist();
      }, popupShowInMinutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  useEffect(() => {
    if (url && url.length > 0) {
      setShow(true);
      setShowReviewForm(true);
    }
  }, [url]);

  const remindMeLater = () => {
    handleClose('do_it_later');
  };

  const handleClose = (status = 'completed') => {
    setReviewStatus({
      variables: { team_id: currentTeamId, status: status },
    })
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.setReviewStatus &&
          result.data.setReviewStatus.status
        ) {
          if (status !== 'do_it_later') {
            Session.storeSessionVar('g2-review-submission', status);
          } else {
            const age = 7 * 24 * 60 * 60;
            Session.storeSessionVar('g2-review-submission', 'do_it_later', age);
          }
          setShowReviewForm(false);
        }
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log(
          'error',
          locale('messages:backend.' + getValidationMessage(graphQLErrors))
        );
      });
    setShow(false);
  };

  const checkSessionExist = () => {
    const submissionStatus = Session.getSessionVar('g2-review-submission');

    if (
      ['do_it_later', 'completed', 'not_this_time'].indexOf(
        submissionStatus
      ) === -1
    ) {
      handleClick();
    }
  };

  const handleClick = () => {
    getG2ReviewForm({
      variables: {
        team_id: currentTeamId,
      },
      fetchPolicy: 'no-cache',
    })
      .then((result) => {
        if (
          result.data &&
          result.data.getG2ReviewForm &&
          result.data.getG2ReviewForm.url &&
          result.data.getG2ReviewForm.url.length > 0
        ) {
          setUrl(result.data.getG2ReviewForm.url);
          Session.storeSessionVar(
            'g2-review-url',
            result.data.getG2ReviewForm.url,
            24 * 60 * 60
          );
        } else if (result.data.getG2ReviewForm.url === null) {
          setShow(false);
          setShowReviewForm(false);
        }
        Session.storeSessionVar(
          'g2-review-submission',
          'not_this_time',
          24 * 60 * 60
        );
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log(
          'error',
          locale('messages:backend.' + getValidationMessage(graphQLErrors))
        );
      });
  };

  const handleShowForm = () => {
    if (!isObjectEmpty(url)) {
      setShowReviewForm(true);
    }
  };

  /***** Render HTML *****/
  return (
    <Modal
      className={'common ' + (showReviewForm ? 'modalXl' : '')}
      show={show}
      backdrop='static'
      keyboard={false}
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header className={'bg-dark'}>
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          {locale('Review')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <EqupRoundLoaderWithOverlay />}
        {!showReviewForm ? (
          <div className='text-center'>
            <p className='text-center font-weight-light mt-4'>
              We have partnered with G2 to provide you with an opportunity to
              share your thoughts and feedback about EQUP.
            </p>
          </div>
        ) : (
          <iframe
            ref={iFrameRef}
            src={url}
            title='Page Preview'
            id='reviewFrame'
            className={'desktop'}
            style={width['desktop']}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        {!showReviewForm ? (
          <Button
            variant='outline-primary'
            onClick={handleShowForm}
          >
            Submit Now
          </Button>
        ) : (
          <Button
            variant='outline-secondary'
            onClick={remindMeLater}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default ReviewPopup;
