import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import './navigation.scss';
import { AuthContext } from '../Context/AuthContext';
// import Session from "../Utils/Session";
import {
  currentTeamId,
  getUserIndustry,
  getUserTeams,
  getValidationMessage,
  hasClient,
} from '../Utils/utils';
import ChooseCompany from '../Components/Pages/SwitchCompany/ChooseCompany';
import { SearchListing } from '../Components/Common/Search/SearchListing';
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  FormControl,
  Modal,
} from 'react-bootstrap';
import { globalSearch } from '../RestApi/RestApi';
import { Notification } from './Notification';
import { useTranslation } from 'react-i18next';
import { GENERATE_PIN } from '../GraphApi/TeamMembers';
import { TopNotification } from '../Components/Common/Notification/TopNotification';
import DuplicateContactsNotification from '../Components/Common/Notification/DuplicateContactsNotification';
import { ANNOUNCEMENTS } from '../GraphApi/Announcement';
import { AnnouncementPreview } from '../Components/Pages/Settings/Announcements/AnnouncementPreview';
import Session from '../Utils/Session';
import UnverifiedContactsNotification from '../Components/Common/Notification/TeamNotification/UnverifiedContactsNotification';
import { getSettings } from '../Utils/CurrentSetting';
import { ModulePermission } from '../Context/ModulePermission';
import copy from 'copy-to-clipboard';
import { equpIndustryInfo } from '../Utils/commonParams';

const urlSchema = {
  crm: ['crm', 'contact-details', 'company-management', 'company-details'],
  dashboard: ['dashboard', 'edit-dashboard', 'client-dashboard'],
  contact: ['crm', 'contact-details'],
  owners: ['owners'],
  company: ['company-management', 'company-details'],
  pipeline: ['pipeline'],
  jobs: ['jobs', 'create-job'],
  campaign: [
    'campaign',
    'campaign-builder',
    'campaign-analytics',
    'choose-prebuilt-campaigns',
  ],
  workflow: ['workflow', 'workflow-builder', 'choose-prebuilt-workflows'],
  engagements: ['engagements'],
  catalog: [
    'services',
    'subscriptions',
    'ecommerce',
    'subscription',
    'add-plan',
    'add-layout',
  ],
  profile: ['my-profile'],
  settings: ['settings'],
  social: [
    'social-media',
    'social-media/add-account',
    'social-media/social-post',
    'social-media/monitoring',
  ],
};

const Navigation = ({ companyLogo, userImage }) => {
  const { pathname } = useLocation();
  const { t: locale } = useTranslation(['common', 'pages']);
  const [isActive, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!isActive);
  };

  const [state, setState] = useState({
    typingTimeout: 0,
  });

  const [industryInfo, setIndustryInfo] = useState(equpIndustryInfo);
  const [showSwitchCompany, setShowSwitchCompany] = useState(false);
  const [industry, setIndustry] = useState('');
  const { logOut, userData } = useContext(AuthContext);
  const { setOnPage, isShowAll, allowedPages } = useContext(ModulePermission);
  const [handleSearch, setHandleSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentCompanySetting, setCurrentCompanySetting] = useState(null);
  const [savedPin, setSavedPin] = useState(null);
  const [showAnnouncements, setShowAnnouncements] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [pathName, setPathName] = useState('');

  /*****/
  const [getNewPin] = useMutation(GENERATE_PIN);
  const [allAnnouncements] = useLazyQuery(ANNOUNCEMENTS);

  const fetchAnnouncements = () => {
    allAnnouncements({
      variables: { team_id: currentTeamId },
      fetchPolicy: 'network-only',
    }).then((result) => {
      if (result.data && !result.error) {
        setAnnouncements(result.data.allAnnouncements);
        // dataArrangeByDate(result.data.allAnnouncements);
        if (result.data.allAnnouncements.length > 0) handleShow();
      }
    });
  };

  const handleClose = () => {
    setShowAnnouncements(false);
    Session.storeSessionVar('equp_announcements', true);
  };

  const handleShow = () => setShowAnnouncements(true);

  useEffect(() => {
    if (pathname) {
      setPathName(pathname.replace('/', ''));
    }
  }, [pathname]);

  useEffect(() => {
    if (currentTeamId && !Session.getSessionVar('equp_announcements')) {
      fetchAnnouncements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  useEffect(() => {
    if (userData && userData.authData) {
      const userIndustry = getUserIndustry(userData);
      if (userIndustry) {
        setIndustry(userIndustry);
        setIndustryInfo({
          ...equpIndustryInfo,
          [userIndustry === 'saas' ? 'subscription' : userIndustry]: true,
        });
        // console.log("Here:", userData.authData);
        document.title = userData.authData.company
          ? userData.authData.company
          : 'EQUP';
      }

      setSavedPin(
        userData.authData.pin_data ? userData.authData.pin_data : null
      );
      if (
        userData.authData.currentTeam &&
        userData.authData.currentTeam.settings
      ) {
        const companySettings = getSettings(
          userData.authData.currentTeam.settings
        );
        setCurrentCompanySetting(companySettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const updateBodyClass = () => {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
  };

  const [searchData, setSearchData] = useState(null);

  const logOutUser = async () => {
    await logOut();
  };

  const handleCloseSwitchCompany = () => setShowSwitchCompany(false);
  const handleShowSwitchCompany = () => setShowSwitchCompany(true);

  const [expanded, setExpanded] = useState(false);

  /* Handle Search */
  const getSearchValue = (searchedText) => {
    if (searchedText.length >= 3) {
      clearTimeout(state.typingTimeout);
      setState({
        typingTimeout: setTimeout(function () {
          setLoading(true);
          globalSearch({
            team_id: currentTeamId,
            q: searchedText,
          })
            .then((data) => {
              // console.log(data);
              setSearchData(data.data);
              setLoading(false);
            })
            .catch((error) => {
              console.log('search Error:', error);
              setLoading(false);
            });
          setHandleSearch(true);
        }, 600),
      });
    } else if (searchedText.length === 0 || !searchedText) {
      clearTimeout(state.typingTimeout);
      setLoading(false);
      setSearchData(null);
    }
  };

  const navigationDrawerHide = () => {
    if (document.body.clientWidth <= 991) {
      setExpanded(expanded ? false : 'expanded');
      updateBodyClass();
    }
  };

  const refreshPin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getNewPin({
      variables: { team_id: currentTeamId },
      fetchPolicy: 'network-only',
    })
      .then((result) => {
        result.data.updatePin.status && setSavedPin(result.data.updatePin.pin);
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log('error', getValidationMessage(graphQLErrors));
      });
  };

  /**
   * Show Navigation Menu Items
   **/
  const showMenuItems = (name) => {
    if (allowedPages.length > 0 && allowedPages.indexOf(name) > -1) {
      return true;
    } else {
      return isShowAll;
    }
  };

  const onCopyMessage = (value) => {
    copy(value, {
      debug: true,
      message: locale('Press #{key} to copy'),
    });
    // handleAlertMessage(
    //   "success",
    //   locale("messages:common.Copied to clipboard!")
    // );
  };

  /*******/
  return (
    <React.Fragment>
      {pathName !== 'engagements' && (
        <>
          <TopNotification />
          {!hasClient(userData) && (
            <DuplicateContactsNotification url={pathName} />
          )}
          <UnverifiedContactsNotification
            url={pathName}
            locale={locale}
            teamId={currentTeamId}
          />
        </>
      )}
      <Navbar
        bg='dark'
        variant='dark'
        expand='lg'
        expanded={expanded}
        className={hasClient(userData) ? 'justify-content-between' : ''}
      >
        <div>
          <Link
            className='navbar-brand'
            to='/dashboard'
          >
            <img
              src={companyLogo}
              alt=''
            />
          </Link>
          <Navbar.Toggle onClick={() => navigationDrawerHide()} />
        </div>
        {!hasClient(userData) && (
          <React.Fragment>
            <Navbar.Collapse
              id='basic-navbar-nav'
              className={'primary-navbar'}
            >
              <Nav className='me-auto ms-xl-3 ps-xl-3'>
                <Link
                  to='/dashboard'
                  state={{ industry: industry }}
                  className={`nav-link ${
                    urlSchema['dashboard'].indexOf(pathName) > -1
                      ? 'active'
                      : ''
                  }`}
                  role='button'
                  onClick={() => navigationDrawerHide()}
                >
                  {locale('pages:navigation.Dashboard')}
                </Link>

                {showMenuItems('crm') && (
                  <NavDropdown
                    title={locale('pages:navigation.CRM')}
                    renderMenuOnMount={true}
                    className={
                      urlSchema['crm'].indexOf(pathName) > -1 ? 'active' : ''
                    }
                  >
                    <Link
                      to='/crm'
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        urlSchema['contact'].indexOf(pathName) > -1
                          ? 'active'
                          : ''
                      }`}
                      role='button'
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale('pages:navigation.Contacts')}
                    </Link>
                    {showMenuItems('companies') && (
                      <Link
                        to='/company-management'
                        state={{ industry: industry }}
                        className={`dropdown-item ${
                          urlSchema['company'].indexOf(pathName) > -1
                            ? 'active'
                            : ''
                        }`}
                        role='button'
                        onClick={() => navigationDrawerHide()}
                      >
                        {locale('pages:navigation.Companies')}
                      </Link>
                    )}
                    {userData &&
                    userData.authData &&
                    userData.authData.is_super_admin ? (
                      <Link
                        to='/owners'
                        state={{ industry: industry }}
                        className={`dropdown-item ${
                          urlSchema['owners'].indexOf(pathName) > -1
                            ? 'active'
                            : ''
                        }`}
                        role='button'
                        onClick={() => navigationDrawerHide()}
                      >
                        {locale('pages:navigation.Owners')}
                      </Link>
                    ) : (
                      ''
                    )}
                  </NavDropdown>
                )}
                {showMenuItems('pipelines') && (
                  <Link
                    to='/pipeline'
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema['pipeline'].indexOf(pathName) > -1
                        ? 'active'
                        : ''
                    }`}
                    role='button'
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale('pages:navigation.Pipeline')}
                  </Link>
                )}
                {showMenuItems('jobs') && (
                  <Link
                    to='/jobs'
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema['jobs'].indexOf(pathName) > -1 ? 'active' : ''
                    }`}
                    role='button'
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale('pages:navigation.Jobs')}
                  </Link>
                )}
                {showMenuItems('campaigns') && (
                  <Link
                    to='/campaign'
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema['campaign'].indexOf(pathName) > -1
                        ? 'active'
                        : ''
                    }`}
                    role='button'
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale('pages:navigation.Campaigns')}
                  </Link>
                )}
                {showMenuItems('workflows') && (
                  <Link
                    to='/workflow'
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema['workflow'].indexOf(pathName) > -1
                        ? 'active'
                        : ''
                    }`}
                    role='button'
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale('pages:navigation.Workflows')}
                  </Link>
                )}
                {showMenuItems('engagements') && (
                  <Link
                    to='/engagements'
                    state={{ industry: industry }}
                    className={`nav-link ${
                      urlSchema['engagements'].indexOf(pathName) > -1
                        ? 'active'
                        : ''
                    }`}
                    role='button'
                    onClick={() => navigationDrawerHide()}
                  >
                    {locale('pages:navigation.engagements')}
                  </Link>
                )}
                {showMenuItems('catalog') && (
                  <React.Fragment>
                    {industryInfo.ecommerce && (
                      <Link
                        to='/ecommerce'
                        state={{ industry: industry }}
                        className={`nav-link ${
                          urlSchema['catalog'].indexOf(pathName) > -1
                            ? 'active'
                            : ''
                        }`}
                        role='button'
                        onClick={() => navigationDrawerHide()}
                      >
                        {locale('pages:navigation.Ecommerce')}
                      </Link>
                    )}
                    {industryInfo.service && (
                      <Link
                        to='/services'
                        state={{ industry: industry }}
                        role='button'
                        className={`nav-link ${
                          urlSchema['catalog'].indexOf(pathName) > -1
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => navigationDrawerHide()}
                      >
                        {locale('pages:navigation.Catalog')}
                      </Link>
                    )}
                    {industryInfo.subscription && (
                      <Link
                        to='/subscription'
                        state={{ industry: industry }}
                        className={`nav-link ${
                          urlSchema['catalog'].indexOf(pathName) > -1
                            ? 'active'
                            : ''
                        }`}
                        role='button'
                        onClick={() => navigationDrawerHide()}
                      >
                        {locale('pages:navigation.Catalog')}
                      </Link>
                    )}
                  </React.Fragment>
                )}
                {showMenuItems('social_media') && (
                  <NavDropdown
                    title={locale('pages:navigation.Social Media')}
                    renderMenuOnMount={true}
                    className={
                      urlSchema['social'].indexOf(pathName) > -1 ? 'active' : ''
                    }
                  >
                    <Link
                      to='/social-media/add-account'
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        ['social-media/add-account'].indexOf(pathName) > -1
                          ? 'active'
                          : ''
                      }`}
                      role='button'
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale('pages:navigation.Add Account')}
                    </Link>
                    <Link
                      to='/social-media/social-post'
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        ['social-media/social-post'].indexOf(pathName) > -1
                          ? 'active'
                          : ''
                      }`}
                      role='button'
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale('pages:navigation.Schedule Posts')}
                    </Link>
                    <Link
                      to='/social-media/monitoring'
                      state={{ industry: industry }}
                      className={`dropdown-item ${
                        ['social-media/monitoring'].indexOf(pathName) > -1
                          ? 'active'
                          : ''
                      }`}
                      role='button'
                      onClick={() => navigationDrawerHide()}
                    >
                      {locale('pages:navigation.Monitor Accounts')}
                    </Link>
                  </NavDropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </React.Fragment>
        )}
        {hasClient(userData) && (
          <Navbar.Collapse
            id='basic-navbar-nav'
            className={'primary-navbar'}
          >
            <Nav className='me-auto ms-xl-3 ps-xl-3'>
              <Link
                to='/client-dashboard'
                state={{ industry: industry }}
                className={`nav-link ${
                  urlSchema['dashboard'].indexOf(pathName) > -1 ? 'active' : ''
                }`}
                role='button'
                onClick={() => navigationDrawerHide()}
              >
                {locale('pages:navigation.Dashboard')}
              </Link>
            </Nav>
          </Navbar.Collapse>
        )}
        <Nav className='admin-action-navbar'>
          {!hasClient(userData) && (
            <React.Fragment>
              <div className='mobile-search-toggle d-xl-none'>
                <Button
                  variant=''
                  onClick={handleToggle}
                >
                  <i className='ri-search-line'></i>
                </Button>
              </div>
              <div
                className={isActive ? 'search-bar active' : ' search-bar'}
                onBlur={() => setHandleSearch(false)}
              >
                <FormControl
                  type='text'
                  placeholder={locale('pages:navigation.Search')}
                  className='show-search-bar'
                  // onFocus={() => setHandleSearch(false)}
                  onChange={(e) => {
                    getSearchValue(e ? e.target.value : '');
                  }}
                  onBlur={(e) => {
                    e.target.value = '';
                  }}
                  id={'globalSearchBar'}
                  autoComplete='off'
                />
                <Button variant=''>
                  <i className='ri-search-line'></i>
                </Button>

                <SearchListing
                  handleSearch={handleSearch}
                  setHandleSearch={setHandleSearch}
                  searchData={searchData}
                  industry={industry}
                  loading={loading}
                  allowedPages={allowedPages}
                  setOnPage={setOnPage}
                />
              </div>
            </React.Fragment>
          )}{' '}
          <React.Fragment>
            <Notification
              currentCompanySetting={currentCompanySetting}
              userData={userData}
              locale={locale}
              industry={industry}
            />
            <NavDropdown
              title={userImage}
              align='end'
              id='basic-nav-dropdown'
              className='admin-action-dropdown'
            >
              <NavDropdown.Item className={'dropdown-item'}>
                <i className='ri-key-fill'></i> {locale('pages:navigation.Pin')}
                <span className='ms-2'>{savedPin}</span>
                <i
                  className='ri-refresh-line ms-2'
                  onClick={refreshPin}
                ></i>
                <i
                  className='ri-file-copy-line text-primary cursor-pointer'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCopyMessage(savedPin);
                  }}
                ></i>
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to='/my-profile'
                state={{ industry: industry }}
                className={`dropdown-item ${
                  urlSchema['profile'].indexOf(pathName) > -1 ? 'active' : ''
                }`}
              >
                <i className='ri-user-6-line'></i>{' '}
                {locale('pages:navigation.Profile')}
              </NavDropdown.Item>
              <React.Fragment>
                {getUserTeams(userData).length > 1 && (
                  <NavDropdown.Item onClick={handleShowSwitchCompany}>
                    <i className='ri-building-line'></i>{' '}
                    {locale('pages:navigation.Switch Company')}
                  </NavDropdown.Item>
                )}
                {!hasClient(userData) && (
                  <NavDropdown.Item
                    as={Link}
                    to='/settings'
                    state={{ industry: industry }}
                    className={`dropdown-item ${
                      urlSchema['settings'].indexOf(pathName) > -1
                        ? 'active'
                        : ''
                    }`}
                  >
                    <i className='ri-settings-5-line'></i>{' '}
                    {locale('pages:navigation.Settings')}
                  </NavDropdown.Item>
                )}
              </React.Fragment>
              <NavDropdown.Divider />{' '}
              <NavDropdown.Item onClick={logOutUser}>
                <i className='ri-logout-circle-line'></i>{' '}
                {locale('pages:navigation.Logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </React.Fragment>
        </Nav>
      </Navbar>

      <Modal
        className={'common modalLg choose-company-modal'}
        show={showSwitchCompany}
        onHide={handleCloseSwitchCompany}
      >
        <Modal.Header
          closeVariant='white'
          closeButton
          className={'bg-dark'}
        >
          <Modal.Title className={'text-white secondary-font text-uppercase'}>
            {locale('pages:navigation.Choose Company')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChooseCompany
            mode={'popup'}
            handleClose={handleCloseSwitchCompany}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-secondary btn-w-icon btn-ms-icon'
            onClick={handleCloseSwitchCompany}
          >
            <i className={'ri-close-line'}></i> <span>{locale('cancel')}</span>
          </Button>
        </Modal.Footer>
      </Modal>
      <AnnouncementPreview
        show={showAnnouncements}
        handleClose={handleClose}
        currentCompanySetting={currentCompanySetting}
        announcements={announcements}
      />
    </React.Fragment>
  );
};

export default Navigation;
