import React, { useReducer } from 'react';
import { /*getUser,*/ logout } from '../RestApi/RestApi';
import {
  apiToken,
  currentTeamId,
  hasAffiliate,
  isObjectEmpty,
  publicRoutesName,
} from '../Utils/utils';
import Session from '../Utils/Session';
import { USER_SETTING } from '../GraphApi/TeamMembers';
import { useLazyQuery } from '@apollo/client';

const initialState = {
  isFetching: false,
  authData: null,
  error: null,
  authenticated: false,
  token: apiToken,
};

export const AuthContext = React.createContext();

function AuthReducer(state, action) {
  switch (action.type) {
    case 'AUTH_REQUEST':
      return { ...state, isFetching: true };
    case 'AUTH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        authData: action.payload,
        authenticated: action.payload ? true : false,
        error: null,
      };
    case 'AUTH_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        authenticated: false,
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}

// AuthContext.displayName = "Auth";

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState, undefined);

  const [getUserData] = useLazyQuery(USER_SETTING, {
    fetchPolicy: 'no-cache',
  });

  const fetchUserSettings = (team_id, flag) => {
    // console.log("Here::", team_id);
    getUserData({
      variables: { team_id: team_id, isTeamId: flag },
      fetchPolicy: 'no-cache',
    })
      .then((result) => {
        const settingData = result.data;
        if (!isObjectEmpty(settingData) && !isObjectEmpty(settingData.me)) {
          if (!hasAffiliate()) {
            const currentTeamData = settingData.me.teams.filter(
              (item) => parseInt(item.id) === parseInt(currentTeamId)
            );
            const myPermissions = settingData.getMyPermissions;
            const profilePicture =
              settingData.getTeamUser &&
              (settingData.getTeamUser.profile_picture ||
                settingData.getTeamUser.profile_picture === '')
                ? settingData.getTeamUser.profile_picture
                : null;

            const jobProfile =
              settingData.getTeamUser &&
              (settingData.getTeamUser.job_profile ||
                settingData.getTeamUser.job_profile === '')
                ? settingData.getTeamUser.job_profile
                : null;

            const signature =
              settingData.getTeamUser &&
              (settingData.getTeamUser.signature ||
                settingData.getTeamUser.signature === '')
                ? settingData.getTeamUser.signature
                : null;

            const currentUserData = {
              token: apiToken,
              ...(currentTeamData && currentTeamData.length > 0
                ? currentTeamData[0].pivot
                : {}),
              profile_picture: profilePicture,
              signature: signature,
              job_profile: jobProfile,
              pin_data:
                settingData && settingData.me && settingData.me.pin_data
                  ? settingData.me.pin_data
                  : null,
              email_verified_at:
                settingData &&
                settingData.me &&
                settingData.me.email_verified_at
                  ? settingData.me.email_verified_at
                  : null,
              email:
                settingData && settingData.me && settingData.me.email
                  ? settingData.me.email
                  : null,
              contact_id:
                currentTeamData &&
                currentTeamData.length > 0 &&
                currentTeamData[0].pivot &&
                currentTeamData[0].pivot.contact_id
                  ? currentTeamData[0].pivot.contact_id
                  : null,
              // settingData && settingData.me && settingData.me.contact_id
              //   ? settingData.me.contact_id
              //   : null,
              currentUserPermission: myPermissions,
              currentTeam:
                currentTeamData && currentTeamData.length > 0
                  ? currentTeamData[0]
                  : '',
              teams: !isObjectEmpty(settingData.me.teams)
                ? settingData.me.teams
                : [],
            };

            dispatch({
              type: 'AUTH_SUCCESS',
              payload: currentUserData,
            });
          }
        }
      })
      .catch((error) => {
        console.log('Error', error);
        dispatch({ type: 'AUTH_FAILURE', payload: error.response });
      });
  };

  /*******/
  const value = {
    userData: {
      isFetching: state.isFetching,
      authData: state.authData,
      error: state.error,
      authenticated: state.authenticated,
      token: apiToken,
    },

    authUser: () => {
      if (
        publicRoutesName.filter(
          (item) => window.location.pathname.indexOf(item) > -1
        ).length === 0
      ) {
        if (!isNaN(currentTeamId) && currentTeamId) {
          dispatch({ type: 'AUTH_REQUEST' });
          try {
            fetchUserSettings(currentTeamId, !!currentTeamId);
          } catch (error) {
            dispatch({ type: 'AUTH_FAILURE', payload: error.response });
          }
        } else if (window.location.pathname.indexOf('account-setup') > -1) {
          dispatch({ type: 'AUTH_REQUEST' });
          try {
            fetchUserSettings(0, false);
            getUserData({
              variables: { team_id: 0, isTeamId: false },
              fetchPolicy: 'no-cache',
            });
          } catch (error) {
            dispatch({ type: 'AUTH_FAILURE', payload: error.response });
          }
        }
      }
    },

    setToken: (token) => {
      dispatch({ type: 'SET_TOKEN', payload: token });
    },

    setUser: (user) => {
      if (user.token)
        dispatch({
          type: 'SET_TOKEN',
          payload: user.token.plainTextToken
            ? user.token.plainTextToken
            : user.token,
        });
      dispatch({ type: 'AUTH_SUCCESS', payload: user });
    },

    logOut: (path = '/') => {
      try {
        logout()
          .then((user) => {
            dispatch({ type: 'SET_TOKEN', payload: null });
            dispatch({ type: 'AUTH_SUCCESS', payload: null });
            localStorage.clear();
            // Session.removeSessionVar('idToken');
            Session.removeSessionVar('equp_announcements');
            Session.removeSessionVar('currentTeam');
            Session.removeSessionVar('isAffiliate');
            Session.removeSessionVar('g2-review-url');
            // setRedirectToLogout(true);
            window.location.href = path;
          })
          .catch((error) => {
            localStorage.clear();
            // Session.removeSessionVar('idToken');
            Session.removeSessionVar('equp_announcements');
            Session.removeSessionVar('currentTeam');
            Session.removeSessionVar('isAffiliate');
            Session.removeSessionVar('g2-review-url');
            //setRedirectToLogout(true);
            window.location.href = path;
            dispatch({ type: 'AUTH_FAILURE', payload: error.response });
          });
      } catch (error) {
        dispatch({ type: 'AUTH_FAILURE', payload: error.response });
      }
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
