export const equpIndustryInfo = {
  ecommerce: false,
  services: false,
  subscriptions: false,
};
export const lifeCycleStage = [
  {
    label: 'Contact',
    value: 'contact',
  },
  {
    label: 'Lead',
    value: 'lead',
  },
  {
    label: 'Prospect',
    value: 'prospect',
  },
  {
    label: 'Customer',
    value: 'customer',
  },
];

export const industryWiseName = {
  saas: 'Plans',
  service: 'Offerings',
  services: 'Offerings',
  ecommerce: 'Products',
};
export const addressParts = ['address', 'city', 'state', 'country', 'zip'];
export const socialHandler = [
  'facebook',
  'twitter',
  'google',
  'linkedin',
  'skype',
];

export const acceptableType = (type) => {
  switch (type) {
    case 'image':
      return {
        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      };
    case 'excel':
      return {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
      };
    case 'documents':
      return {
        'text/plain': ['.txt'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
      };
    case 'video':
      return {
        'video/*': ['.mp4', '.mpeg', '.ogv', '.webm', '.3gp'],
      };
    case 'audio':
      return {
        'audio/*': ['.mp3', '.mp4', '.amr', '.ogg'],
      };
    case 'document':
      return {
        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
      };
    default:
      return {};
  }
};
