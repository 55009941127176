import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  InputGroup,
  FormControl,
  Button,
  Table,
} from 'react-bootstrap';
import valid from 'card-validator';
import XAlertMessage from '../AlertMessage/XAlertMessage';
import InLineErrorMessage from '../AlertMessage/InLineErrorMessage';
import {
  SUBSCRIPTION_INVOICE_DETAIL,
  ACCEPT_SUBSCRIPTION_PAYMENT,
} from '../../../GraphApi/Checkout';
import { COUNTRY_LIST } from '../../../GraphApi/ContactList';

import {
  getNumberByUserFormat,
  getCurrencyByUserFormat,
  getDateFormatOnly,
} from '../../../Utils/CurrentSetting';
import '../../../Pages/Settings/settings.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  getValidationMessage,
  detectCardType,
  cardTypes,
  scrollToTop,
  toWordCase,
  getMonthList,
  monthList,
  yearList,
} from '../../../Utils/utils';
import { useTranslation } from 'react-i18next';
import EqupRoundLoader from './../../Common/Loader/RoundLoader';

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/im;
const nameRegExp = /^[a-zA-Z0-9-.\\s ]*$/gm;

const ExternalSaasInvoiceCheckout = () => {
  // const location = useLocation();
  const params = useParams();
  const { t: locale } = useTranslation(['common', 'pages']);
  const refDrawer = useRef(null);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  /*****/
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [countries, setCountries] = useState([]);

  const [states, setStates] = useState([]);
  const [countryWiseStates, setCountryWiseStates] = useState([]);
  const addressField = ['country', 'address', 'city', 'state', 'zip_code'];
  const [userSetting, setUserSetting] = useState(null);
  const [status, setStatus] = useState(null);
  const [itemAble, setItemAble] = useState([]);
  /** QUERIES & MUTATION DECLARATION **/

  const [
    getSubscriptionInvoiceDetail,
    { data: transactionData, loading: pageLoading },
  ] = useLazyQuery(SUBSCRIPTION_INVOICE_DETAIL, { fetchPolicy: 'no-cache' });

  const [acceptSubscriptionPayment] = useMutation(ACCEPT_SUBSCRIPTION_PAYMENT);

  /** Country List **/
  const { data: allCountries, loading: fetchingCountries } =
    useQuery(COUNTRY_LIST);

  /******/
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(locale('messages:formik.Invalid email'))
      .required(
        locale('messages:formik.{{name}} is required', {
          name: locale('Email'),
        })
      ),
    last_name: Yup.string()
      .required(
        locale('messages:formik.{{name}} is required', {
          name: locale('last name'),
        })
      )
      .min(
        3,
        locale('messages:formik.{{name}} min {{minLength}} character long!', {
          name: locale('last name'),
          minLength: 3,
        })
      )
      .max(
        255,
        locale('messages:formik.{{name}} max {{maxLength}} character long!', {
          name: locale('last name'),
          maxLength: 255,
        })
      )
      .matches(
        nameRegExp,
        locale(
          'messages:formik.{{name}} can contains characters numbers space . -',
          { name: locale('last name') }
        )
      ),
    first_name: Yup.string()
      .min(
        3,
        locale('messages:formik.{{name}} min {{minLength}} character long!', {
          name: locale('first name'),
          minLength: 3,
        })
      )
      .max(
        255,
        locale('messages:formik.{{name}} max {{maxLength}} character long!', {
          name: locale('first name'),
          maxLength: 255,
        })
      )
      .matches(
        nameRegExp,
        locale(
          'messages:formik.{{name}} can contains characters numbers space . -',
          { name: locale('first name') }
        )
      ),
    phone: Yup.string()
      .nullable(true)
      .matches(
        phoneRegExp,
        locale('messages:formik.{{name}} is invalid', { name: locale('phone') })
      ),

    address: Yup.string()
      .max(
        255,
        locale('messages:formik.{{name}} max {{maxLength}} character long!', {
          name: locale('address'),
          maxLength: 255,
        })
      )
      .matches(
        '^[a-zA-Z0-9-#,_.\\s /]*$',
        locale(
          'messages:formik.{{name}} can contains # , _ . characters only.',
          { name: locale('address') }
        )
      ),
    city: Yup.string()
      .max(
        255,
        locale('messages:formik.{{name}} max {{maxLength}} character long!', {
          name: locale('city'),
          maxLength: 255,
        })
      )
      .matches(
        '^[a-zA-Z0-9 /s]*$',
        locale('messages:formik.Special character not allowed.', {
          name: locale('city'),
        })
      ),
    state: Yup.string()
      .max(
        255,
        locale('messages:formik.{{name}} max {{maxLength}} character long!', {
          name: locale('state'),
          maxLength: 255,
        })
      )
      .matches(
        '^[a-zA-Z0-9 /s]*$',
        locale('messages:formik.Special character and white space not allowed.')
      ),

    country: Yup.string(),
    // .max(50, "Country name max 50 character long!")
    // .matches("^[a-zA-Z0-9 /s]*$", "Special character space not allowed."),
    zip_code: Yup.string()
      .min(
        5,
        locale('messages:formik.{{name}} min {{minLength}} character long!', {
          name: locale('zip code'),
          minLength: 5,
        })
      )
      .matches(
        '^[a-zA-Z0-9]*$',
        locale(
          'messages:formik.Special character and white space not allowed.',
          { name: locale('zip code') }
        )
      ),
    card_name: Yup.string().when('new_card', {
      is: (new_card) => new_card,
      then: Yup.string()
        .required(
          locale('messages:formik.{{name}} is required', {
            name: locale('pages:checkoutField.Name on Card'),
          })
        )
        .max(
          255,
          locale('messages:formik.{{name}} max {{maxLength}} character long!', {
            name: locale('pages:checkoutField.Name on Card'),
            maxLength: 255,
          })
        )
        .matches(
          '^[a-zA-Z0-9-#,_.\\s /]*$',
          locale(
            'messages:formik.{{name}} can contains # , _ . characters only.',
            { name: locale('pages:checkoutField.Name on Card') }
          )
        ),
    }),
    card_number: Yup.string().when('new_card', {
      is: (new_card) => new_card,
      then: Yup.string().test(
        'match', // this is used internally by yup
        locale('messages:formik.{{name}} is invalid', {
          name: locale('Credit Card number'),
        }),
        (value) => {
          if (transactionData && transactionData.payment_requested === 0) {
            return true;
          }
          return valid.number(parseInt(value)).isValid;
        }
      ),
    }),
    month: Yup.string().when('new_card', {
      is: (new_card) => new_card,
      then: Yup.string()
        .min(
          1,
          locale('messages:formik.{{name}} min {{minLength}} character long!', {
            name: locale('month'),
            minLength: 1,
          })
        )
        .max(
          2,
          locale('messages:formik.{{name}} max {{maxLength}} character long!', {
            name: locale('month'),
            maxLength: 2,
          })
        )
        .test(
          'match', // this is used internally by yup
          locale('messages:formik.{{name}} is invalid', {
            name: locale('pages:checkoutField.Month'),
          }),
          (value) => {
            if (transactionData && transactionData.payment_requested === 0) {
              return true;
            }
            return valid.expirationMonth(value).isValid;
          }
        ),
    }),
    year: Yup.string().when('new_card', {
      is: (new_card) => new_card,
      then: Yup.string()
        .min(
          4,
          locale('messages:formik.{{name}} min {{minLength}} character long!', {
            name: locale('pages:checkoutField.Year'),
            minLength: 4,
          })
        )
        .test(
          'match', // this is used internally by yup
          locale('messages:formik.{{name}} is invalid', {
            name: locale('pages:checkoutField.Year'),
          }),
          (value) => {
            if (transactionData && transactionData.payment_requested === 0) {
              return true;
            }
            return valid.expirationYear(value).isValid;
          }
        ),
    }),
    cvv: Yup.string().when('new_card', {
      is: (new_card) => new_card,
      then: Yup.string()
        //.max(3, "CVV must be three digit long")
        .test(
          'match',
          locale('messages:formik.{{name}} is invalid', {
            name: locale('pages:checkoutField.CVV'),
          }),
          (value) => {
            const maxLength = formik.values.card_type === 'amex' ? 4 : 3;
            if (transactionData && transactionData.payment_requested === 0) {
              return true;
            }
            return valid.cvv(
              value,
              maxLength,
              locale(
                'messages:formik.{{name}} max {{maxLength}} character long!',
                {
                  name: locale('pages:checkoutField.CVV'),
                  maxLength: maxLength,
                }
              )
            ).isValid;
          }
        ),
    }),
  });

  /***CALL USE-EFFECT METHODS****/
  useEffect(() => {
    if (allCountries && Object.keys(allCountries).length) {
      let countryList = [];
      let states = [];
      allCountries.countryList.country.forEach((country) => {
        /** Set all Country List **/
        countryList.push({
          id: country.id,
          value: country.code,
          label: country.name,
          code: country.code,
        });
      });

      allCountries.countryList.state.forEach((state) => {
        /** Set All States **/
        states.push({
          value: state.name,
          label: state.name,
          countryId: state.country_id,
        });
      });
      setCountries(countryList);
      setStates(states);
    }
  }, [allCountries]);

  useEffect(() => {
    if (params && params.id) {
      setTransactionId(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**CALL SUBSCRIPTIOn DETAIL **/
  useEffect(() => {
    if (transactionId && countries && countries.length > 0) {
      getSubscriptionInvoiceDetail({
        variables: { transactionId: transactionId },
        fetchPolicy: 'no-cache',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, countries]);

  /**FETCH SUBSCRIPTION DETAIL **/
  useEffect(() => {
    if (transactionData) {
      const details = transactionData.getSubscriptionInvoiceDetail;
      /****/
      const setting = {
        number_format: details.number_format,
        currency: details.currency,
        sandbox_mode: details.sandbox_mode,
      };
      setItemAble(
        details.transaction_document_items && details.transaction_document_items
          ? details.transaction_document_items
          : []
      );

      setUserSetting(setting);
      setStatus(details.status);

      /*****/
      Object.keys(details).forEach((item) => {
        if (item === 'billing_address') {
          addressField.forEach((addressItem) => {
            if (addressItem === 'country') {
              handleCountry({ value: details[item]['billing_country'] });
              formik.setFieldValue(
                [addressItem],
                details[item]['billing_country']
              );
            } else if (addressItem === 'zip_code') {
              formik.setFieldValue(
                [addressItem],
                details[item]['billing_zip'] ? details[item]['billing_zip'] : ''
              );
            } else {
              formik.setFieldValue(
                [addressItem],
                details[item]['billing_' + addressItem]
                  ? details[item]['billing_' + addressItem]
                  : ''
              );
            }
          });
        } else {
          formik.setFieldValue([item], details[item]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionData]);

  /******/
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      card_name: userSetting && userSetting.sandbox_mode ? 'Dummy card' : '',
      card_number:
        userSetting && userSetting.sandbox_mode ? '4111111111111111' : '',
      cvv: userSetting && userSetting.sandbox_mode ? '121' : '',
      month: userSetting && userSetting.sandbox_mode ? '12' : '',
      year: userSetting && userSetting.sandbox_mode ? '2030' : '',
      street: '',
      city: '',
      state: '',
      country: {
        countryCode: null,
      },
      zip_code: '',
      card_type: userSetting && userSetting.sandbox_mode ? 'visa' : '',
      new_card: true,
      card_id: null,
    },
    validationSchema,
    onSubmit(values) {
      processData(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      'card_name',
      userSetting && userSetting.sandbox_mode ? 'Dummy card' : ''
    );
    formik.setFieldValue(
      'card_number',
      userSetting && userSetting.sandbox_mode ? '4111111111111111' : ''
    );
    formik.setFieldValue(
      'cvv',
      userSetting && userSetting.sandbox_mode ? '121' : ''
    );
    formik.setFieldValue(
      'month',
      userSetting && userSetting.sandbox_mode ? '12' : ''
    );
    formik.setFieldValue(
      'year',
      userSetting && userSetting.sandbox_mode ? '2030' : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSetting]);

  useEffect(() => {
    if (formik.values.card_number) {
      formik.setFieldValue(
        'card_type',
        detectCardType(formik.values.card_number)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.card_number]);

  const processData = (data) => {
    setIsSubmitting(true);
    const dataToBeProcess = {
      ...data,
      id: transactionId,
      billing_address: {
        billing_address: data.address,
        billing_city: data.city,
        billing_state: data.state,
        billing_zip: data.zip_code,
        billing_country: data.country,
      },
    };
    acceptSubscriptionPayment({
      variables: dataToBeProcess,
    })
      .then((result) => {
        handleAlertMessage(
          'success',
          locale('messages:common.made', { fieldName: locale('Payment') })
        );
        formik.resetForm();
        setStatus('completed');
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log(error, graphQLErrors);
        handleAlertMessage(
          'error',
          locale('messages:backend.' + getValidationMessage(graphQLErrors))
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  /** This Methods use in Formik Only ***/
  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
    scrollToTop(refDrawer);
  };

  /***Set Formik Field Error ***/
  const hasError = (name) => {
    return formik.touched[name] && formik.errors[name] ? 'is-invalid' : '';
  };
  /***Set Formik Field Value ***/
  const hasValue = (name) => {
    return formik.values[name] ? formik.values[name] : '';
  };
  const setValue = (name, event) => {
    return formik.setFieldValue(
      name,
      name === 'country' ? event : event.target.value
    );
  };

  const setNumberFormat = (value, type = 'number') => {
    if (value > 0) {
      return getNumberByUserFormat(userSetting, value, type);
    } else {
      return getCurrencyByUserFormat(userSetting) + '0';
    }
  };
  /**Country Bound with Country DialCode **/
  const handleCountry = (county) => {
    countries.forEach((country) => {
      if (county.value === country.value) {
        formik.setFieldValue('country', country.value);
        county = country;
      }
    });
    const stateList = states.filter(
      (state) => parseInt(state.countryId) === parseInt(county.id) && state
    );
    formik.setFieldValue('state', '');
    setCountryWiseStates(stateList);
  };
  const getBillingCycle = (row, title) => {
    let billingCycleDate = 'Not Applicable';
    if (
      row &&
      row['billing_period'] &&
      row['billing_period'].trim().length > 1
    ) {
      const dateArray = row['billing_period'].split(' - ');
      const DateA = new Date(dateArray[0].trim());
      const DateB = new Date(dateArray[1].trim());
      billingCycleDate =
        getDateFormatOnly(userSetting, DateA) +
        ' - ' +
        getDateFormatOnly(userSetting, DateB);
    } else {
      billingCycleDate = title;
    }
    return billingCycleDate;
  };
  /*** RENDER HTML *****/
  if (
    fetchingCountries ||
    pageLoading ||
    (countries && countries.length === 0)
  ) {
    return <EqupRoundLoader />;
  }
  if (status === 'paid') {
    return (
      <div
        className='container'
        style={{ textAlign: 'center', margin: '100px auto' }}
      >
        <h5 className='text-center border-all p-2 bg-light m-5'>
          {formik && formik.values.document_type
            ? toWordCase(formik.values.document_type)
            : ''}{' '}
          already paid.
        </h5>
      </div>
    );
  }
  if (status === 'completed') {
    return (
      <div
        className='container'
        style={{ textAlign: 'center', margin: '100px auto' }}
      >
        <h5 className='text-center border-all p-2 bg-light m-5'>
          {transactionData &&
          transactionData.getSubscriptionInvoiceDetail &&
          transactionData.getSubscriptionInvoiceDetail.document_type
            ? toWordCase(
                transactionData.getSubscriptionInvoiceDetail.document_type
              )
            : ''}{' '}
          paid.
        </h5>
      </div>
    );
  }
  return (
    <React.Fragment>
      <Container>
        <Row className={''}>
          <Col className={''}>
            <Card className={'form-white-card'}>
              <Card.Header
                className={'bg-dark text-white text-center pt-3 pb-3'}
              >
                {locale(
                  'messages:checkout.Fill the order form below to complete your purchase'
                )}
                ;
              </Card.Header>
              <Card.Body ref={refDrawer}>
                <XAlertMessage
                  type={alertType}
                  message={alertMessage}
                  setAlertShow={setAlertShow}
                  alertShow={alertShow}
                />
                <Row className={''}>
                  <Col className={''}>
                    <h4
                      className={
                        'font-weight-semibold mb-4 title-with-count text-primary'
                      }
                    >
                      {locale('messages:checkout.Your basic information')}
                    </h4>
                    <Form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.First name')}
                            </Form.Label>
                            <Form.Control
                              className={hasError('first_name')}
                              id={'first_name'}
                              type='text'
                              placeholder={locale(
                                'pages:checkoutField.Enter First Name'
                              )}
                              value={hasValue('first_name')}
                              onChange={(e) => setValue('first_name', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'first_name'}
                              formik={formik}
                              id={'first_name'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.Last name')}
                            </Form.Label>
                            <Form.Control
                              className={hasError('last_name')}
                              id={'last_name'}
                              type='text'
                              placeholder={locale(
                                'pages:checkoutField.Enter Last Name'
                              )}
                              value={hasValue('last_name')}
                              onChange={(e) => setValue('last_name', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'last_name'}
                              formik={formik}
                              id={'last_name'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.Email Address')}
                            </Form.Label>
                            <Form.Control
                              className={hasError('email')}
                              id={'email'}
                              type='text'
                              placeholder={locale(
                                'pages:checkoutField.Email Address'
                              )}
                              value={hasValue('email')}
                              disabled={true}
                              readOnly
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'email'}
                              formik={formik}
                              id={'email'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4
                        className={
                          'font-weight-semibold mt-4 mb-4 title-with-count text-primary'
                        }
                      >
                        {locale('pages:checkoutField.Billing Address')}
                      </h4>
                      <Row>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.Street')}
                            </Form.Label>
                            <Form.Control
                              className={hasError('address')}
                              type='text'
                              id={'address'}
                              placeholder={locale(
                                'pages:checkoutField.Enter Address'
                              )}
                              value={hasValue('address')}
                              onChange={(e) => setValue('address', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'address'}
                              formik={formik}
                              id={'address'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.City')}
                            </Form.Label>
                            <Form.Control
                              className={hasError('address')}
                              type='text'
                              placeholder={locale(
                                'pages:checkoutField.Enter City'
                              )}
                              id={'city'}
                              value={hasValue('city')}
                              onChange={(e) => setValue('city', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'city'}
                              formik={formik}
                              id={'city'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.Country')}
                            </Form.Label>
                            <Select
                              className={hasError('country')}
                              options={countries}
                              placeholder={locale(
                                'pages:checkoutField.Enter Country'
                              )}
                              id='country'
                              name='country'
                              value={countries.filter(
                                (item) => item.value === formik.values.country
                              )}
                              onChange={(e) => handleCountry(e)}
                              isSearchable
                            />

                            <InLineErrorMessage
                              type='error'
                              name={'country'}
                              formik={formik}
                              id={'country'}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          xs='12'
                          sm='6'
                        >
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.State')}
                            </Form.Label>
                            <Select
                              className={hasError('state')}
                              options={countryWiseStates}
                              placeholder={locale(
                                'pages:checkoutField.Enter State'
                              )}
                              id='state'
                              name='state'
                              value={countryWiseStates.filter(
                                (item) => item.value === formik.values.state
                              )}
                              onChange={(e) =>
                                formik.setFieldValue('state', e.value)
                              }
                              isSearchable
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'state'}
                              formik={formik}
                              id={'state'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='form-group'>
                            <Form.Label>
                              {locale('pages:checkoutField.Zip code')}
                            </Form.Label>
                            <Form.Control
                              className={hasError('zip_code')}
                              type='text'
                              id={'zip_code'}
                              placeholder={locale(
                                'pages:checkoutField.Enter Zip code'
                              )}
                              value={hasValue('zip_code')}
                              onChange={(e) => setValue('zip_code', e)}
                            />
                            <InLineErrorMessage
                              type='error'
                              name={'zip_code'}
                              formik={formik}
                              id={'zip_code'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <h4
                        className={
                          'font-weight-semibold mt-4 mb-4 title-with-count text-primary'
                        }
                      >
                        {locale('messages:checkout.Your payment information')}
                      </h4>
                      {userSetting && userSetting.sandbox_mode === true && (
                        <div className='note-box'>
                          {locale(
                            'messages:checkout.This is under SandBox for test Payment'
                          )}
                        </div>
                      )}
                      {transactionData &&
                        transactionData.getSubscriptionInvoiceDetail &&
                        transactionData.getSubscriptionInvoiceDetail
                          .cardDetails &&
                        transactionData.getSubscriptionInvoiceDetail.cardDetails
                          .length > 0 && (
                          <>
                            <Row>
                              <Col>
                                <h6>
                                  {locale('messages:checkout.Select Card')}:
                                </h6>
                              </Col>
                            </Row>
                            {transactionData &&
                              transactionData.getSubscriptionInvoiceDetail &&
                              transactionData.getSubscriptionInvoiceDetail
                                .cardDetails &&
                              transactionData.getSubscriptionInvoiceDetail.cardDetails.map(
                                (item, idx) => (
                                  <Row key={idx}>
                                    <Col>
                                      <div className='saved-card-details'>
                                        <div className='saved-card-detail-list'>
                                          <input
                                            type='radio'
                                            name='choose_card'
                                            id={'masterCard' + idx}
                                            checked={
                                              parseInt(
                                                formik.values.card_id
                                              ) === parseInt(item.id)
                                            }
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                'card_id',
                                                parseInt(item.id)
                                              );
                                              formik.setFieldValue(
                                                'new_card',
                                                false
                                              );
                                            }}
                                          />
                                          <label htmlFor={'masterCard' + idx}>
                                            <div className='card-details-left'>
                                              <span>
                                                {cardTypes[
                                                  item.card_type.toLowerCase()
                                                ] && (
                                                  <img
                                                    src={
                                                      cardTypes[
                                                        item.card_type.toLowerCase()
                                                      ]
                                                    }
                                                    alt={item.card_type}
                                                  />
                                                )}
                                              </span>
                                              <div className='mb-2 mb-md-0 me-md-2'>
                                                {item.card_type ===
                                                  'unknown' && (
                                                  <h6>
                                                    {toWordCase(
                                                      item.card_type
                                                    ) + ' Card'}
                                                  </h6>
                                                )}
                                                <h5>
                                                  {'**** **** **** ' +
                                                    item.card_number}{' '}
                                                </h5>
                                              </div>
                                              <div>
                                                <h6>{locale('Exp. Date')}</h6>
                                                <h5>{item.expiry_date}</h5>
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              )}
                            <Row>
                              <Col>
                                <div className='saved-card-details'>
                                  <div className='saved-card-detail-list'>
                                    <input
                                      type='radio'
                                      name='choose_card'
                                      id={'new_card'}
                                      checked={formik.values.new_card}
                                      onChange={(e) => {
                                        formik.setFieldValue('card_id', null);
                                        formik.setFieldValue('new_card', true);
                                      }}
                                    />
                                    <label htmlFor='new_card'>
                                      <div className='card-details-left'>
                                        <h6>
                                          {locale(
                                            'messages:checkout.Add new card information'
                                          )}
                                        </h6>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      {formik.values.new_card && (
                        <>
                          <Row className='mt-3'>
                            <Col md='6'>
                              <Form.Group className='form-group'>
                                <Form.Label>
                                  {locale('pages:checkoutField.Name on Card')}
                                </Form.Label>
                                <div className='field-icon-right'>
                                  <Form.Control
                                    type='text'
                                    placeholder={locale(
                                      'pages:checkoutField.Name on Card'
                                    )}
                                    value={
                                      formik.values.card_name
                                        ? formik.values.card_name
                                        : ''
                                    }
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        'card_name',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <InLineErrorMessage
                                  type='error'
                                  name={'card_name'}
                                  formik={formik}
                                  id={'card_name'}
                                />
                              </Form.Group>
                            </Col>
                            <Col md='6'>
                              <Form.Group className='form-group'>
                                <Form.Label>
                                  {locale('pages:checkoutField.Card Number')}
                                </Form.Label>
                                <div className='field-icon-right'>
                                  <Form.Control
                                    className={hasError('card_number')}
                                    id={'card_number'}
                                    type='text'
                                    placeholder={locale(
                                      'pages:checkoutField.Enter card number'
                                    )}
                                    value={hasValue('card_number')}
                                    onChange={(e) => {
                                      setValue('card_number', e);
                                    }}
                                  />
                                  {formik.values.card_type && (
                                    <span className='icon-right'>
                                      <img
                                        src={cardTypes[formik.values.card_type]}
                                        alt={formik.values.card_type}
                                      />
                                    </span>
                                  )}
                                </div>
                                <InLineErrorMessage
                                  type='error'
                                  name={'card_number'}
                                  formik={formik}
                                  id={'card_number'}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs='6'
                              sm='4'
                            >
                              <Form.Group className='form-group'>
                                <Form.Label>
                                  {locale('pages:checkoutField.Month')}
                                </Form.Label>
                                <Select
                                  options={getMonthList()}
                                  placeholder={locale(
                                    'pages:checkoutField.Enter month'
                                  )}
                                  className={hasError('month')}
                                  value={monthList.filter(
                                    (item) =>
                                      formik.values.month &&
                                      parseInt(item.value) ===
                                        parseInt(formik.values.month)
                                  )}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'month',
                                      e.value.toString()
                                    );
                                  }}
                                />
                                <InLineErrorMessage
                                  type='error'
                                  name={'month'}
                                  formik={formik}
                                  id={'month'}
                                />
                              </Form.Group>
                            </Col>
                            <Col
                              xs='6'
                              sm='4'
                            >
                              <Form.Group className='form-group'>
                                <Form.Label>
                                  {locale('pages:checkoutField.Year')}
                                </Form.Label>
                                <Select
                                  options={yearList}
                                  placeholder={locale(
                                    'pages:checkoutField.Enter year'
                                  )}
                                  className={hasError('year')}
                                  value={yearList.filter(
                                    (item) =>
                                      formik.values.year &&
                                      parseInt(item.value) ===
                                        parseInt(formik.values.year)
                                  )}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'year',
                                      e.value.toString()
                                    );
                                  }}
                                />

                                <InLineErrorMessage
                                  type='error'
                                  name={'year'}
                                  formik={formik}
                                  id={'year'}
                                />
                              </Form.Group>
                            </Col>
                            <Col
                              xs='12'
                              sm='4'
                            >
                              <Form.Group className='form-group'>
                                <Form.Label>
                                  {locale('pages:checkoutField.CVV')}
                                </Form.Label>
                                <InputGroup className='mb-2'>
                                  <FormControl
                                    className={hasError('cvv')}
                                    id={'cvv'}
                                    placeholder={locale(
                                      'pages:checkoutField.CVV'
                                    )}
                                    value={hasValue('cvv')}
                                    onChange={(e) => setValue('cvv', e)}
                                  />

                                  <InputGroup.Text>
                                    <i
                                      className={'ri-bank-card-line ri-lg'}
                                    ></i>
                                  </InputGroup.Text>
                                </InputGroup>
                                <InLineErrorMessage
                                  type='error'
                                  name={'cvv'}
                                  formik={formik}
                                  id={'cvv'}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col className='mb-3 mb-md-0 text-end'>
                          <Button
                            variant='primary btn-lg mt-5 ps-5 pe-5 btn-ms-icon'
                            type='submit'
                            disabled={isSubmitting}
                          >
                            <span>
                              {locale('pages:checkoutField.Checkout Now')}
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col sm={4}>
                    <h4 className={'font-weight-semibold mb-4 text-primary'}>
                      {locale('messages:checkout.Purchase Details')}
                    </h4>
                    {transactionData &&
                      transactionData.getSubscriptionInvoiceDetail && (
                        <Table className={'custom-table'}>
                          <tbody>
                            {transactionData.getSubscriptionInvoiceDetail
                              .document_type === 'invoice' && (
                              <tr>
                                <td className={'ps-0 pe-0 pt-3'}>
                                  <h5 className={'font-weight-semibold mb-0'}>
                                    {locale(
                                      'pages:checkoutField.Invoice number'
                                    )}
                                  </h5>
                                </td>
                                <td className={'ps-0 pe-0 pt-3 text-end'}>
                                  {transactionData &&
                                  transactionData.getSubscriptionInvoiceDetail &&
                                  transactionData.getSubscriptionInvoiceDetail
                                    .name
                                    ? transactionData
                                        .getSubscriptionInvoiceDetail.name
                                    : ''}
                                </td>
                              </tr>
                            )}
                            {itemAble &&
                              itemAble.map((item, idx) => (
                                <tr key={idx}>
                                  <td className={'ps-0 pe-1 pt-3'}>
                                    <h5 className={'font-weight-semibold mb-0'}>
                                      {item.itemable && item.itemable.name
                                        ? item.itemable.name
                                        : ''}
                                    </h5>
                                    <p className='text-small text-uppercase'>
                                      {getBillingCycle(
                                        item.itemable,
                                        item.title
                                      )}
                                    </p>
                                  </td>
                                  <td className={'ps-0 pe-0 pt-3 text-end'}>
                                    {item.itemable && item.itemable.price
                                      ? setNumberFormat(
                                          item.itemable.price,
                                          'currency'
                                        )
                                      : item.line_price !== null
                                        ? setNumberFormat(
                                            item.line_price,
                                            'currency'
                                          )
                                        : ''}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td
                                className={
                                  'ps-0 pe-0 pt-3 pb-3 font-weight-semibold'
                                }
                              >
                                {locale('Total')}
                              </td>
                              <td
                                className={
                                  'ps-0 pe-0 pt-3 pb-3 text-end font-weight-semibold'
                                }
                              >
                                {setNumberFormat(
                                  transactionData.getSubscriptionInvoiceDetail
                                    .total,
                                  'currency'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={
                                  'ps-0 pe-0 pt-3 pb-3 font-weight-semibold'
                                }
                              >
                                {locale(
                                  'pages:checkoutField.Payment Requested'
                                )}
                              </td>

                              <td
                                className={
                                  'ps-0 pe-0 pt-3 pb-3 text-end font-weight-semibold'
                                }
                              >
                                {setNumberFormat(
                                  transactionData.getSubscriptionInvoiceDetail
                                    .payment_requested,
                                  'currency'
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <div className={''}>
              <div className={''}></div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default ExternalSaasInvoiceCheckout;
