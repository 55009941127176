import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DuplicateContactsContext } from '../../../Context/DuplicateContactsContext';
import { isObjectEmpty } from '../../../Utils/utils';

const DuplicateContactsNotification = ({ url }) => {
  const { t: locale } = useTranslation(['common', 'pages']);
  const [manualRemoved, setManualRemoved] = useState(false);

  /******/
  const { duplicateData, getDuplicateData } = useContext(
    DuplicateContactsContext
  );

  useEffect(() => {
    getDuplicateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******/
  if (
    ['duplicate-contacts', '/duplicate-contacts'].indexOf(url) > -1 ||
    isObjectEmpty(duplicateData) ||
    (duplicateData && !duplicateData.contacts) ||
    (duplicateData &&
      duplicateData.contacts &&
      duplicateData.contacts.length === 0) ||
    manualRemoved
  ) {
    return <React.Fragment></React.Fragment>;
  } else
    return (
      <>
        <div className='bg-light text-dark pt-2 pb-3 pb-sm-2 px-4 top-navigation'>
          <Row className='align-items-center'>
            <Col>
              <div className='d-flex align-items-center'>
                <i className='ri-information-fill me-2'></i>
                <p className='m-0'>
                  {locale(
                    'messages:notification.It seems like there are duplicate contacts in your CRM, that you may wanna fix.'
                  )}
                </p>
              </div>
            </Col>
            <Col
              sm='auto'
              className='pe-0 ps-5 ps-sm-2'
            >
              <Link
                className='btn btn-primary btn-outline-primary btn-sm'
                to='/duplicate-contacts'
              >
                {locale('Check & Fix Now')}
              </Link>
            </Col>
            <Col
              sm='auto'
              className='ps-0 top-nav-close'
            >
              <Button
                variant='link'
                className='text-black btn-sm top-bar-close px-0'
                onClick={() => {
                  setManualRemoved(true);
                }}
              >
                <i className='ri-close-line ri-xl ms-2'></i>
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
};

export default DuplicateContactsNotification;
