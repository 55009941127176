import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { TrialPlanChecker } from '../../../Context/TrialPlanChecker';
import { EXTEND_PLAN_REQUEST } from '../../../GraphApi/EqupPlans/Plan';
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from '../../../Utils/utils';
import InLineError from '../AlertMessage/InLineError';
import XAlertMessage from '../AlertMessage/XAlertMessage';
import { UpgradePlan } from '../../Pages/Settings/AccountAndBilling/UpgradePlan';

const textArea = '^[a-zA-Z0-9-#*&^$+=|~?(){}%&@!"<>/\\\',_.-:;\\s /]*$';

export const IsTrialExpired = () => {
  const { t: locale } = useTranslation();
  const [show, setShow] = useState(false);
  const [redirectTo] = useState(false);
  const [isTrialExtend, setIsTrialExtend] = useState(false);
  const [requestMessage, setRequestMessage] = useState('');
  const [error, setError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  /** Alert Message State **/
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);

  const { planData, isPlanExpired } = useContext(TrialPlanChecker);
  const [setTrialExtendRequest] = useMutation(EXTEND_PLAN_REQUEST);
  const [showModal] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    if (isPlanExpired && planData !== null) {
      setShow(true);
    }
  }, [isPlanExpired, planData]);

  const closePopup = () => {
    setShow(false);
  };

  /**Error Message Settings and methods For Formik **/
  const handleAlertMessage = (type, message) => {
    setType(type);
    setMessage(message);
    setAlertShow(true);
  };

  const sendRequest = () => {
    setIsSubmitting(true);

    if (isObjectEmpty(requestMessage)) {
      setError(locale('Message is required.'));
      return false;
    } else if (!requestMessage.match(textArea)) {
      setError(locale('Message contain some unknown or hidden character.'));
      return false;
    } else {
      setTrialExtendRequest({
        variables: { team_id: currentTeamId, description: requestMessage },
      })
        .then((result) => {
          handleAlertMessage(
            'success',
            locale('Your trial plan extend request sent successfully.')
          );
          setIsSubmitting(false);
          setError(null);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          setError(
            locale('messages:backend.' + getValidationMessage(graphQLErrors))
          );
          setIsSubmitting(false);
        });
    }
  };

  /******/
  if (redirectTo) {
    return (
      <Navigate
        to={'/renew/' + planData.subscription_id}
        state={{
          redirectUrl: window.location.href,
        }}
      />
    );
  }

  if (showUpgradeModal)
    return (
      <Modal
        className={'common modalXl edit-columns-modal'}
        onHide={() => {
          setShow(true);
          setShowUpgradeModal(false);
        }}
        show={showModal}
        backdrop={'static'}
        keyboard={false}
      >
        <Modal.Header
          closeVariant='white'
          closeButton
          className={'bg-dark'}
        >
          <Modal.Title className={'text-white secondary-font text-uppercase'}>
            {locale('pages:setting.account.plan.Upgrade Plan')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpgradePlan
            subscriptionId={planData ? planData.subscription_id : ''}
            locale={locale}
          />
        </Modal.Body>
      </Modal>
    );
  return (
    <Modal
      show={show}
      onHide={closePopup}
      centered
      style={{ zIndex: 9999 }}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{locale('Notification')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <XAlertMessage
          type={type}
          message={message}
          setAlertShow={setAlertShow}
          alertShow={alertShow}
        />
        {isTrialExtend ? (
          <Form.Group className='form-group'>
            <Form.Label>
              {locale('Why would you like to extend the trial?')}
            </Form.Label>
            <Form.Control
              id={'message'}
              className={error ? 'is-invalid' : ''}
              as='textarea'
              rows='5'
              placeholder={locale('Type something here...')}
              value={requestMessage}
              onChange={(e) => {
                setRequestMessage(e.target.value);
              }}
            />
            <InLineError
              type='error'
              name='message'
              message={error}
            />
          </Form.Group>
        ) : (
          <div>
            {planData && planData.trial_expiry_date !== null && (
              <h2>{locale('Your trial plan has expired.')}</h2>
            )}
            {planData && planData.trial_expiry_date === null && (
              <h2>{locale('Your subscription has expired.')}</h2>
            )}
            <p>{locale('You can choose one of the following options')}</p>
            <ul>
              <li>{locale('Send request to extend trial')}</li>
              <li>{locale('Upgrade plan')}</li>
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isTrialExtend ? (
          <React.Fragment>
            <Button
              variant='secondary'
              onClick={() => setIsTrialExtend(false)}
            >
              {locale('cancel')}
            </Button>
            <Button
              variant='primary'
              disabled={isSubmitting}
              onClick={() => !isSubmitting && sendRequest()}
            >
              {locale('Extend My Trial')}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              variant='secondary'
              onClick={() => setIsTrialExtend(true)}
            >
              {locale('Extend My Trial')}
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                closePopup();
                setShowUpgradeModal(true);
              }}
            >
              {locale('Upgrade Plan')}
            </Button>
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};
