import React, { Suspense, useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ExternalSaasInvoiceCheckout from '../Components/Common/Checkout/ExternalSaasInvoiceCheckout';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import { DialerApp } from '../Components/Common/Twilio/Twilio';
import { AuthContext } from '../Context/AuthContext';
import { isObjectEmpty, publicRoutesName } from '../Utils/utils';
import RetryFallback from './RetryFallback';
import retryLazy from './useRetryLazy';

const retryCount = 2;
const Callbacks = retryLazy(() => import('../Pages/Callbacks/Callbacks'));
/*** SignIn **/
const SignIn = retryLazy(() => import('../Pages/Login/SignIn'));
const LoginWithPin = retryLazy(() => import('../Pages/Login/LoginWithPin'));
const ForgotPassword = retryLazy(() => import('../Pages/Login/ForgotPassword'));
const ResetPassword = retryLazy(() => import('../Pages/Login/ResetPassword'));
const Unsubscribe = retryLazy(() => import('../Pages/Contact/Unsubscribe'));
/*****/
const SetPassword = retryLazy(() => import('../Pages/Signup/SetPassword'));
const ActivateTeamMember = retryLazy(() => import('../Pages/Signup/Activate'));
/***SignUp***/
// const SignUp = retryLazy(() => import("../Pages/Signup/SignUp"));
const NewSignUp = retryLazy(() => import('../Pages/Signup/NewSignUp'));
const LinkedinPopup = retryLazy(() => import('../Pages/Login/LinkedinPopup'));
const AccountSetupNew = retryLazy(
  () => import('../Pages/Setup/AccountSetupNew')
);
const Conversation = retryLazy(() => import('../Pages/Conversation/Index'));
const MicrosoftRedirectScreen = retryLazy(
  () => import('../Pages/Dashboard/MicrosoftRedirectScreen')
);
/***Affiliate***/
const SetAffiliatePassword = retryLazy(
  () => import('../Pages/Login/SetAffiliatePassword')
);
const AffiliateSignIn = retryLazy(
  () => import('../Pages/Login/AffiliateSignIn')
);

const AffiliateCrm = retryLazy(() => import('../Pages/Affiliate/AffiliateCrm'));

const MarketingMaterial = retryLazy(
  () => import('../Pages/Affiliate/MarketingMaterial')
);
/***SUPER ADMIN ***/
const OwnersList = retryLazy(() => import('../Pages/SuperAdmin/OwnersList'));
const CompanyDetailAdmin = retryLazy(
  () => import('../Pages/SuperAdmin/CompanyDetailAdmin')
);
/*****/
const PageNotFound = retryLazy(
  () => import('../Pages/PageNotFound/PageNotFound')
);
const ChooseCompany = retryLazy(
  () => import('../Components/Pages/SwitchCompany/ChooseCompany')
);
const MyProfile = retryLazy(() => import('../Components/Profile/Profile'));
const Dashboard = retryLazy(() => import('../Pages/Dashboard/Dashboard'));
const NewDashboard = retryLazy(() => import('../Pages/Dashboard/NewDashboard'));
const AddNewDashboard = retryLazy(
  () => import('../Pages/Dashboard/AddNewDashboard')
);

const CrmDashboard = retryLazy(() => import('../Pages/Crm/CrmList'));
const CrmDetails = retryLazy(() => import('../Pages/Crm/CrmDetails'));
const CompanyManagement = retryLazy(
  () => import('../Pages/CompanyManagement/CompanyManagement')
);
const CompanyDetails = retryLazy(
  () => import('../Pages/CompanyManagement/CompanyDetails')
);
/**Ecommerce**/
const Ecommerce = retryLazy(() => import('../Pages/Ecommerce/Ecommerce'));

/**Services**/
const Services = retryLazy(() => import('../Pages/Services/Services'));

/** SaasAndSubscription **/
const SaasAndSubscription = retryLazy(
  () => import('../Pages/SaasAndSubscription/SaasAndSubscription')
);
const AddNewPlan = retryLazy(
  () => import('../Components/SaasAndSubscription/Plan/AddNewPlan')
);
const AddLayout = retryLazy(
  () => import('../Components/SaasAndSubscription/Layout/AddLayout')
);
const PlanPreview = retryLazy(
  () => import('../Components/Common/Preview/PlanPreview')
);
/*** Pipeline ***/
const Pipeline = retryLazy(() => import('../Pages/Pipeline/Pipeline'));
/** Campaign **/
const Campaign = retryLazy(() => import('../Pages/Campaign/Campaign'));
const CampaignAnalytics = retryLazy(
  () => import('../Pages/Campaign/CampaignAnalytics')
);
const CampaignCompare = retryLazy(
  () => import('../Pages/Campaign/CampaignCompare')
);
const CampaignBuilder = retryLazy(
  () => import('../Pages/Campaign/CampaignBuilder')
);
const CampaignTemplate = retryLazy(
  () => import('../Pages/Campaign/CampaignTemplate')
);

/*** Workflow **/
const Workflow = retryLazy(() => import('../Pages/Workflow/Workflow'));
const WorkflowBuilder = retryLazy(
  () => import('../Pages/Workflow/WorkflowBuilder')
);
const WorkflowTemplate = retryLazy(
  () => import('../Components/Workflow/WorkflowTemplate')
);

/*** OTHERS **/
const Bookings = retryLazy(() => import('../Pages/Booking/Bookings'));
const Settings = retryLazy(() => import('../Pages/Settings/Settings'));
const TeamMemberDetails = retryLazy(
  () => import('../Components/Pages/Settings/TeamMember/TeamMemberDetails')
);
const EmailBuilder = retryLazy(
  () => import('../Components/Pages/EmailBuilder/EmailBuilder')
);
const LandingPageBuilder = retryLazy(
  () => import('../Components/Pages/LandingPageBuilder/LandingPageBuilder')
);

const Notifications = retryLazy(
  () => import('../Components/Profile/Notifications')
);
/*****/
const ResponsivePreview = retryLazy(
  () => import('../Components/Common/Preview/ResponsivePreview')
);
const XChart = retryLazy(() => import('../Components/Common/XChart/XChart'));
const ExternalCheckoutPage = retryLazy(
  () => import('../Components/Common/Checkout/ExternalCheckoutPage')
);
const ExternalInvoiceCheckout = retryLazy(
  () => import('../Components/Common/Checkout/ExternalInvoiceCheckout')
);
// const Dialer = retryLazy(() =>
//   import("../Pages/Twilio/Dialer")
// );
const SocialLayout = retryLazy(
  () => import('../Pages/SocialMedia/SocialLayout')
);
const DuplicateContactsListPage = retryLazy(
  () => import('../Components/Common/Notification/DuplicateContactsListPage')
);
const VerificationContactsListing = retryLazy(
  () => import('../Components/Common/Modals/VerificationContactsListing')
);
const VerifyContactsNotification = retryLazy(
  () =>
    import(
      '../Components/Common/Notification/TeamNotification/VerifyContactsNotifications'
    )
);

const ScheduleAMeeting = retryLazy(
  () => import('../Components/Pages/UserAvailability/ScheduleAMeeting')
);

const CancelMeeting = retryLazy(
  () => import('../Components/Pages/UserAvailability/CancelMeeting')
);

const AcceptInvitation = retryLazy(
  () => import('../Components/Pages/Appointments/AcceptAppointmentInvitation')
);

const EqupForm = retryLazy(() => import('../Components/Pages/Forms/EqupForm'));
const ClientDetails = retryLazy(
  () => import('../Pages/ClientPortal/ClientDetails')
);

const AgreementBuilder = retryLazy(
  () =>
    import('../Pages/AgreementsManagement/AgreementBuilder/AgreementBuilder')
);

// () => import("../Pages/EstimateManagement/EstimateBuilder"),
const EstimateBuilder = retryLazy(
  () => import('../Pages/EstimateManagement/EstimateBuilder/EstimateBuilder')
);

// () => import("../Pages/EstimateManagement/EstimateTemplateBuilder"),
const EstimateTemplateBuilder = retryLazy(
  () =>
    import(
      '../Pages/EstimateManagement/EstimateBuilder/EstimateTemplateBuilder'
    )
);

const AgreementSigner = retryLazy(
  () => import('../Pages/AgreementsManagement/SignaturePortal/AgreementSigner')
);

const AgreementReviewPage = retryLazy(
  () =>
    import('../Pages/AgreementsManagement/AgreementBuilder/AgreementReviewPage')
);

const EstimateSigner = retryLazy(
  () => import('../Pages/EstimateManagement/EstimateSigner')
);

const EstimateReviewPage = retryLazy(
  () => import('../Pages/EstimateManagement/EstimateReviewPage')
);

/*** JOB Management ***/
const JobManagement = retryLazy(
  () => import('../Pages/JobManagement/JobManagement')
);
const NewJob = retryLazy(() => import('../Pages/JobManagement/NewJob'));
/******/
const AppRoutes = (props) => {
  const { logOut } = useContext(AuthContext);

  /**** LOGOUT ****/
  useEffect(() => {
    let expiredTime = 0;
    if (!isObjectEmpty(localStorage.getItem('_expiredTime'))) {
      expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);
    }

    if (expiredTime > 0 && expiredTime < Date.now()) {
      logOutUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** ON TIME OUT LOGOUT THE USER **/
  const logOutUser = async () => {
    const redirectTo =
      publicRoutesName.filter((item) => window.location.href.includes(item))
        .length > 0;
    !redirectTo && (await logOut(redirectTo));
  };

  /********/
  return (
    <Suspense
      fallback={<RetryFallback retry={() => window.location.reload()} />}
    >
      <Routes>
        {/*** PUBLIC ROUTES ***/}
        <Route
          path='/sign-in'
          element={
            <PublicRoutes
              exact
              path='/sign-in'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <SignIn {...props} />}
            />
          }
        />
        <Route
          path='/signup'
          element={
            <PublicRoutes
              exact
              path='/signup'
              hasNavigation={false}
              component={(props) => <NewSignUp {...props} />}
            />
          }
        />
        <Route
          path='/signup/:emailId'
          element={
            <PublicRoutes
              exact
              path='/signup/:emailId'
              hasNavigation={false}
              component={(props) => <NewSignUp {...props} />}
            />
          }
        />
        <Route
          path='/signup/:emailId/:status'
          element={
            <PublicRoutes
              exact
              path='/signup/:emailId/:status'
              hasNavigation={false}
              component={(props) => <NewSignUp {...props} />}
            />
          }
        />
        <Route
          path='/reset-password'
          element={
            <PublicRoutes
              exact
              path='/reset-password'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <SetPassword {...props} />}
            />
          }
        />
        <Route
          path='/reset/password'
          element={
            <PublicRoutes
              exact
              path='/reset/password'
              hasNavigation={false}
              component={(props) => <ResetPassword {...props} />}
            />
          }
        />
        <Route
          path='/forgot-password'
          element={
            <PublicRoutes
              exact
              path='/forgot-password'
              hasNavigation={false}
              component={(props) => <ForgotPassword {...props} />}
            />
          }
        />
        <Route
          path='/client-login'
          element={
            <PublicRoutes
              exact
              path='/client-login'
              hasNavigation={false}
              component={(props) => <LoginWithPin {...props} />}
            />
          }
        />
        <Route
          path='/callbacks/:id'
          element={
            <PublicRoutes
              exact
              path='/callbacks/:id'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <Callbacks {...props} />}
            />
          }
        />
        <Route
          path='/schedule-appointment/:userAvailabilityId/'
          element={
            <PublicRoutes
              exact
              path='/schedule-appointment/:userAvailabilityId/'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <ScheduleAMeeting {...props} />}
            />
          }
        />
        <Route
          path='/cancel-appointment/:appointmentId/'
          element={
            <PublicRoutes
              exact
              path='/cancel-appointment/:appointmentId/'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <CancelMeeting {...props} />}
            />
          }
        />
        <Route
          path='/schedule-appointment/:userAvailabilityId/:bookingId'
          element={
            <PublicRoutes
              exact
              path='/schedule-appointment/:userAvailabilityId/:bookingId'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <ScheduleAMeeting {...props} />}
            />
          }
        />
        <Route
          path='/reschedule-appointment/:userAvailabilityId/:appointmentId'
          element={
            <PublicRoutes
              exact
              path='/reschedule-appointment/:userAvailabilityId/:appointmentId'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <ScheduleAMeeting {...props} />}
            />
          }
        />
        <Route
          path='/bookings/:id'
          element={
            <PublicRoutes
              exact
              path='/bookings/:id'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <Bookings {...props} />}
            />
          }
        />
        <Route
          path='/appointment/:appId/:contactId'
          element={
            <PublicRoutes
              exact
              path='/appointment/:appId/:contactId'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <AcceptInvitation {...props} />}
            />
          }
        />
        <Route
          path='/affiliate/login'
          element={
            <PublicRoutes
              exact
              path='/affiliate/login'
              hasNavigation={false}
              component={(props) => <AffiliateSignIn {...props} />}
            />
          }
        />
        <Route
          path='/affiliate-signup'
          element={
            <PublicRoutes
              exact
              path='/affiliate-signup'
              hasNavigation={false}
              component={(props) => <SetAffiliatePassword {...props} />}
            />
          }
        />
        <Route
          path='/unsubscribe/:contactId/:emailId'
          element={
            <PublicRoutes
              exact
              path='/unsubscribe/:contactId/:emailId'
              hasNavigation={false}
              component={(props) => <Unsubscribe {...props} />}
            />
          }
        />
        <Route
          path='/activate'
          element={
            <PublicRoutes
              exact
              path='/activate'
              hasNavigation={false}
              component={(props) => <ActivateTeamMember {...props} />}
            />
          }
        />
        <Route
          path='/login-linkedin'
          element={
            <PublicRoutes
              exact
              path='/login-linkedin'
              hasNavigation={false}
              component={(props) => <LinkedinPopup {...props} />}
            />
          }
        />
        <Route
          path='/checkout/:id'
          element={
            <PublicRoutes
              exact
              path='/checkout/:id'
              hasNavigation={false}
              component={(props) => <PlanPreview {...props} />}
            />
          }
        />
        <Route
          path='/checkout/:id/:plan_id/:plan_type/:email?'
          element={
            <PublicRoutes
              exact
              path='/checkout/:id/:plan_id/:plan_type/:email?'
              hasNavigation={false}
              component={(props) => <PlanPreview {...props} />}
            />
          }
        />
        <Route
          path='/renew/:id'
          element={
            <PublicRoutes
              exact
              path='/renew/:id'
              hasNavigation={false}
              component={(props) => <ExternalCheckoutPage {...props} />}
            />
          }
        />
        <Route
          path='/renew-subscription/:id'
          element={
            <PublicRoutes
              exact
              path='/renew-subscription/:id'
              hasNavigation={false}
              component={(props) => <ExternalCheckoutPage {...props} />}
            />
          }
        />
        <Route
          path='/invoice/:id'
          element={
            <PublicRoutes
              exact
              path='/invoice/:id'
              hasNavigation={false}
              component={(props) => <ExternalInvoiceCheckout {...props} />}
            />
          }
        />
        <Route
          path='/quote/:id'
          element={
            <PublicRoutes
              exact
              path='/quote/:id'
              hasNavigation={false}
              component={(props) => <ExternalInvoiceCheckout {...props} />}
            />
          }
        />
        <Route
          path='/subscription/:id'
          element={
            <PublicRoutes
              exact
              path='/subscription/:id'
              hasNavigation={false}
              component={(props) => <ExternalSaasInvoiceCheckout {...props} />}
            />
          }
        />
        <Route
          path='/form/:id'
          element={
            <PublicRoutes
              exact
              path='/orm/:id'
              hasNavigation={false}
              component={(props) => <EqupForm {...props} />}
            />
          }
        />

        <Route
          path='/agreement-signing/:id'
          element={
            <PublicRoutes
              hasNavigation={false}
              exact
              path='/agreement-signing/:id'
              hasDefault={true}
              component={(props) => <AgreementSigner {...props} />}
            />
          }
        />
        <Route
          path='/estimate-signing/:id'
          element={
            <PublicRoutes
              hasNavigation={false}
              exact
              path='/estimate-signing/:id'
              hasDefault={true}
              component={(props) => <EstimateSigner {...props} />}
            />
          }
        />
        {/****** PROTECTED ROUTES *****/}
        <Route
          path=''
          element={
            <ProtectedRoutes
              exact
              path=''
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <Dashboard {...props} />}
            />
          }
        />
        <Route
          path='/'
          element={
            <ProtectedRoutes
              exact
              path='/'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <Dashboard {...props} />}
            />
          }
        />
        <Route
          path='/choose-company'
          element={
            <ProtectedRoutes
              exact
              path='/choose-company'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <ChooseCompany {...props} />}
            />
          }
        />
        <Route
          path='/account-setup'
          element={
            <ProtectedRoutes
              exact
              path='/account-setup'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <AccountSetupNew {...props} />}
            />
          }
        />
        <Route
          path='/engagements'
          element={
            <ProtectedRoutes
              exact
              path='/engagements'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <Conversation {...props} />}
            />
          }
        />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoutes
              exact
              path='/dashboard'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <Dashboard {...props} />}
            />
          }
        />
        <Route
          path='/new-dashboard'
          element={
            <ProtectedRoutes
              exact
              path='/new-dashboard'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <NewDashboard {...props} />}
            />
          }
        />
        <Route
          path='/add-new-dashboard'
          element={
            <ProtectedRoutes
              exact
              path='/add-new-dashboard'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <AddNewDashboard {...props} />}
            />
          }
        />
        <Route
          path='/add-dashboard'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              hasDefault={false}
              component={(props) => <AddNewDashboard {...props} />}
            />
          }
        />
        <Route
          path='/edit-dashboard'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              path='/edit-dashboard'
              hasDefault={false}
              component={(props) => <AddNewDashboard {...props} />}
            />
          }
        />
        <Route
          path='/notifications'
          element={
            <ProtectedRoutes
              exact
              path='/notifications'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <Notifications {...props} />}
            />
          }
        />
        <Route
          path='/crm'
          element={
            <ProtectedRoutes
              exact
              path='/crm'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CrmDashboard {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/contact-details'
          element={
            <ProtectedRoutes
              exact
              path='/contact-details'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CrmDetails {...props} />}
            />
          }
        />
        <Route
          path='/contact-details/:id'
          element={
            <ProtectedRoutes
              exact
              path='/contact-details/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CrmDetails {...props} />}
            />
          }
        />
        <Route
          path='/task/:id'
          element={
            <ProtectedRoutes
              exact
              path='/task/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CrmDetails {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/appointment/:id'
          element={
            <ProtectedRoutes
              exact
              path='/appointment/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CrmDetails {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/agreement/:id/:agId'
          element={
            <ProtectedRoutes
              exact
              path='/agreement/:id/:agId'
              hasDefault={true}
              component={(props) => <CrmDetails {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/company-management'
          element={
            <ProtectedRoutes
              exact
              path='/company-management'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CompanyManagement {...props} />}
            />
          }
        />
        <Route
          path='/company-details'
          element={
            <ProtectedRoutes
              exact
              path='/company-details'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CompanyDetails {...props} />}
            />
          }
        />
        <Route
          path='/owners'
          element={
            <ProtectedRoutes
              exact
              path='/owners'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <OwnersList {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/owner-details'
          element={
            <ProtectedRoutes
              exact
              path='/owner-details'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <CompanyDetailAdmin {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/dialer'
          element={
            <ProtectedRoutes
              exact
              path='/dialer'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <DialerApp {...props} />}
            />
          }
        />
        <Route
          path='/affiliate-crm'
          element={
            <ProtectedRoutes
              exact
              path='/affiliate-crm'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <AffiliateCrm {...props} />}
            />
          }
        />
        <Route
          path='/marketing-material'
          element={
            <ProtectedRoutes
              exact
              path='/marketing-material'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <MarketingMaterial {...props} />}
            />
          }
        />
        <Route
          path='/login-microsoft'
          element={
            <ProtectedRoutes
              exact
              path='/login-microsoft'
              hasNavigation={false}
              hasDefault={true}
              component={(props) => <MicrosoftRedirectScreen {...props} />}
            />
          }
        />
        <Route
          path='/pipeline'
          element={
            <ProtectedRoutes
              exact
              path='/pipeline'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Pipeline {...props} />}
            />
          }
        />
        <Route
          path='/deal/:id'
          element={
            <ProtectedRoutes
              exact
              path='/deal/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Pipeline {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/campaign'
          element={
            <ProtectedRoutes
              exact
              path='/campaign'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Campaign {...props} />}
            />
          }
        />
        <Route
          path='/campaign-analytics'
          element={
            <ProtectedRoutes
              exact
              path='/campaign-analytics'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CampaignAnalytics {...props} />}
            />
          }
        />
        <Route
          path='/campaign-compare'
          element={
            <ProtectedRoutes
              exact
              path='/campaign-compare'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CampaignCompare {...props} />}
            />
          }
        />
        <Route
          path='/choose-prebuilt-campaigns'
          element={
            <ProtectedRoutes
              exact
              path='/choose-prebuilt-campaigns'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CampaignTemplate {...props} />}
            />
          }
        />
        <Route
          path='/campaign-builder'
          element={
            <ProtectedRoutes
              exact
              path='/campaign-builder'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <CampaignBuilder {...props} />}
            />
          }
        />
        <Route
          path='/workflow'
          element={
            <ProtectedRoutes
              exact
              path='/workflow'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Workflow {...props} />}
            />
          }
        />
        <Route
          path='/choose-prebuilt-workflows'
          element={
            <ProtectedRoutes
              exact
              path='/choose-prebuilt-workflows'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <WorkflowTemplate {...props} />}
            />
          }
        />
        <Route
          path='/workflow-builder'
          element={
            <ProtectedRoutes
              exact
              path='/workflow-builder'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <WorkflowBuilder {...props} />}
            />
          }
        />

        <Route
          path='/ecommerce'
          element={
            <ProtectedRoutes
              exact
              path='/ecommerce'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Ecommerce {...props} />}
            />
          }
        />
        <Route
          path='/services'
          element={
            <ProtectedRoutes
              exact
              path='/services'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Services {...props} />}
            />
          }
        />
        <Route
          path='/quotes'
          element={
            <ProtectedRoutes
              exact
              path='/quotes'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Services {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/quotes/:id'
          element={
            <ProtectedRoutes
              exact
              path='/quotes/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Services {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/projects'
          element={
            <ProtectedRoutes
              exact
              path='/projects'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Services {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/projects/:id'
          element={
            <ProtectedRoutes
              exact
              path='/projects/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Services {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/subscription'
          element={
            <ProtectedRoutes
              exact
              path='/subscription'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <SaasAndSubscription {...props} />}
            />
          }
        />
        <Route
          path='/add-plan'
          element={
            <ProtectedRoutes
              exact
              path='/add-plan'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <AddNewPlan {...props} />}
            />
          }
        />
        <Route
          path='/add-layout'
          element={
            <ProtectedRoutes
              exact
              path='/add-layout'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <AddLayout {...props} />}
            />
          }
        />
        <Route
          path='/responsive-preview'
          element={
            <ProtectedRoutes
              exact
              path='/responsive-preview'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <ResponsivePreview {...props} />}
            />
          }
        />
        <Route
          path='/social-media/:id'
          element={
            <ProtectedRoutes
              exact
              path='/social-media/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <SocialLayout {...props} />}
            />
          }
        />

        <Route
          path='/settings'
          element={
            <ProtectedRoutes
              exact
              path='/settings'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <Settings {...props} />}
            />
          }
        />

        <Route
          path='/settings/:id'
          element={
            <ProtectedRoutes
              exact
              path='/settings/:id'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Settings {...props} />}
            />
          }
        />
        <Route
          path='/buy-addon'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/buy-addon'
              hasDefault={false}
              component={(props) => <Settings {...props} />}
            />
          }
        />
        <Route
          path='/buy-plan'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/buy-plan'
              hasDefault={false}
              component={(props) => <Settings {...props} />}
            />
          }
        />
        <Route
          path='/website-tracking-details'
          element={
            <ProtectedRoutes
              exact
              path='/website-tracking-details'
              hasNavigation={true}
              hasDefault={false}
              component={(props) => <Settings {...props} />}
              {...props}
            />
          }
        />
        <Route
          path='/team-member'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              path='/team-member'
              hasDefault={true}
              component={(props) => <TeamMemberDetails {...props} />}
            />
          }
        />
        <Route
          path='/my-profile'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              path='/my-profile'
              hasDefault={true}
              component={(props) => <MyProfile {...props} />}
            />
          }
        />
        <Route
          path='/email-builder'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/email-builder'
              hasDefault={false}
              component={(props) => <EmailBuilder {...props} />}
            />
          }
        />
        <Route
          path='/test-chart'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              path='/test-chart'
              hasDefault={false}
              component={(props) => <XChart {...props} />}
            />
          }
        />
        <Route
          path='/landing-page-builder'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/landing-page-builder'
              hasDefault={false}
              component={(props) => <LandingPageBuilder {...props} />}
            />
          }
        />
        <Route
          path='/contacts-verification'
          element={
            <ProtectedRoutes
              exact
              path='/contacts-verification'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <VerificationContactsListing {...props} />}
            />
          }
        />
        <Route
          path='/email-verification-notification'
          element={
            <ProtectedRoutes
              exact
              path='/email-verification-notification'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <VerifyContactsNotification {...props} />}
            />
          }
        />
        <Route
          path='/duplicate-contacts'
          element={
            <ProtectedRoutes
              exact
              path='/duplicate-contacts'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <DuplicateContactsListPage {...props} />}
            />
          }
        />

        <Route
          path='/client-dashboard'
          element={
            <ProtectedRoutes
              exact
              path='/client-dashboard'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <ClientDetails {...props} />}
            />
          }
        />
        <Route
          path='/agreement-builder'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/agreement-builder'
              hasDefault={true}
              component={(props) => <AgreementBuilder {...props} />}
            />
          }
        />

        <Route
          path='/agreement-review/:id'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              path='/agreement-review/:id'
              hasDefault={true}
              component={(props) => <AgreementReviewPage {...props} />}
            />
          }
        />
        <Route
          path='/estimate-builder'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/estimate-builder'
              hasDefault={true}
              component={(props) => <EstimateBuilder {...props} />}
            />
          }
        />

        <Route
          path='/estimate-template-builder'
          element={
            <ProtectedRoutes
              hasNavigation={false}
              exact
              path='/estimate-template-builder'
              hasDefault={true}
              component={(props) => <EstimateTemplateBuilder {...props} />}
            />
          }
        />

        <Route
          path='/estimate-review/:id'
          element={
            <ProtectedRoutes
              hasNavigation={true}
              exact
              path='/estimate-review/:id'
              hasDefault={true}
              component={(props) => <EstimateReviewPage {...props} />}
            />
          }
        />
        <Route
          path='/jobs'
          element={
            <ProtectedRoutes
              exact
              path='/jobs'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <JobManagement {...props} />}
            />
          }
        />
        <Route
          path='/create-job'
          element={
            <ProtectedRoutes
              exact
              path='/create-job'
              hasNavigation={true}
              hasDefault={true}
              component={(props) => <NewJob {...props} />}
            />
          }
        />
        {/** DEFAULT ROUTES FOR PAGE NOT FOUND **/}
        <Route
          path='*'
          hasNavigation={true}
          hasDefault={true}
          element={
            <ProtectedRoutes
              path='*'
              component={PageNotFound}
            />
          }
        />
      </Routes>{' '}
    </Suspense>
  );
};

export default AppRoutes;
