export const groupColourStyles = {
  group: (styles) => ({
    paddingBottom: '4px',
  }),
  groupHeading: (styles) => ({
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: '#7f8590',
    padding: '0.5rem 0.85rem 0.5rem 1.05rem',
    letterSpacing: '1px',
  }),
  option: (styles) => ({
    padding: '0.25rem 0.85rem 0.25rem 1.75rem',
    '&:hover': {
      backgroundColor: '#e0ebf8',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '99',
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 'calc(1.6em + 0.75rem + 2px)',
    borderRadius: '0.15rem',
    border: '0.063rem solid $border-color',
    boxShadow: '0 0 0!important',
    '&:focus': {
      borderColor: '#4391e6',
    },
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '150px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '0.85rem',
    paddingRight: '0.85rem',
    height: '34px',
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans, sans-serif',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'defaultColor',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    height: '34px',
    '> div': {
      padding: '0',
      '&:last-child': {
        paddingRight: '8px',
      },
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'red',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#e0ebf8',
    border: '0.063rem solid #dce4ec',
    color: '#6c8cb5',
    margin: '-5px 14px 0 -9px',
    '&:first-of-type': {
      color: '#6c8cb5',
    },
    '&:last-child': {
      backgroundColor: 'transparent',
      svg: {
        fill: '#6c8cb5',
      },
    },
  }),
};

export const nonGroupColourStyles = {
  group: (styles) => ({
    paddingBottom: '4px',
  }),
  groupHeading: (styles) => ({
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: '#7f8590',
    padding: '0.5rem 0.85rem 0.5rem 1.05rem',
    letterSpacing: '1px',
  }),
  option: (styles) => ({
    padding: '0.25rem 1rem',
    '&:hover': {
      backgroundColor: '#e0ebf8',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '99',
  }),
  container: (styles) => ({
    ...styles,
    flex: '1 1 auto',
    userSelect: 'none',
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 'calc(1.6em + 0.75rem + 2px)',
    borderRadius: '0.15rem',
    border: '0.063rem solid $border-color',
    boxShadow: '0 0 0!important',
    fontWeight: '500',
    fontSize: '0.875rem',
    fontFamily: "'Montserrat', sans-serif",
    userSelect: 'none',
    '&:focus': {
      borderColor: '#4391e6',
    },
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '150px',
    userSelect: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '0.85rem',
    paddingRight: '0.85rem',
    height: '34px',
    userSelect: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    userSelect: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'defaultColor',
    userSelect: 'none',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
    userSelect: 'none',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    height: '34px',
    userSelect: 'none',
    '> div': {
      padding: '0',
      userSelect: 'none',
      '&:last-child': {
        paddingRight: '8px',
        userSelect: 'none',
      },
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#e0ebf8',
    border: '0.063rem solid #dce4ec',
    color: '#6c8cb5',
    margin: '-5px 14px 0 -9px',
    '&:first-of-type': {
      color: '#6c8cb5',
    },
    '&:last-child': {
      backgroundColor: 'transparent',
      svg: {
        fill: '#6c8cb5',
      },
    },
  }),
};
export const nonGroupColourStylesPreview = {
  group: (styles) => ({
    paddingBottom: '4px',
  }),
  groupHeading: (styles) => ({
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: '#7f8590',
    padding: '0.5rem 0.85rem 0.5rem 1.05rem',
    letterSpacing: '1px',
  }),
  option: (styles) => ({
    padding: '0.25rem 1rem',
    '&:hover': {
      backgroundColor: '#e0ebf8',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '99',
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 'calc(1.6em + 0.75rem + 2px)',
    borderRadius: '0',

    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '0.063rem solid transparent',
    boxShadow: 'none',
    fontSize: '1rem',
    '&:hover': {
      borderBottom: '0.063rem solid #e3e3e3',

      indicatorsContainer: (styles) => ({
        ...styles,
        opacity: '1',
      }),
    },
    '&:focus': {
      borderBottom: '0.063rem solid #4391e6',
    },
  }),

  menuList: (styles) => ({
    ...styles,
    maxHeight: '150px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '0',
    paddingRight: '0.85rem',
    height: '34px',
    borderBottom: '0.063rem solid transparent',
    '&:focus': {
      borderBottom: '0.063rem solid #4391e6',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans, sans-serif',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'defaultColor',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    height: '34px',
    opacity: '0',
    '> div': {
      padding: '0',
      '&:last-child': {
        paddingRight: '8px',
      },
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#e0ebf8',
    border: '0.063rem solid #dce4ec',
    color: '#6c8cb5',
    // margin: "-5px 14px 0 -9px",
    margin: '0px',
    '&:first-of-type': {
      color: '#6c8cb5',
    },
    '&:last-child': {
      backgroundColor: 'transparent',
      svg: {
        fill: '#6c8cb5',
      },
    },
  }),
  input: (styles) => ({
    ...styles,
    userSelect: 'none',
  }),
};

export const autoHeightMultiValue = {
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '0.85rem',
    paddingRight: '0.85rem',
    minHeight: '34px',
    paddingTop: '0',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#e0ebf8',
    border: '0.063rem solid #dce4ec',
    color: '#6c8cb5',
    margin: '2px',
    '&:first-of-type': {
      color: '#6c8cb5',
    },
    '&:last-child': {
      backgroundColor: 'transparent',
      svg: {
        fill: '#6c8cb5',
      },
    },
  }),
  input: (styles) => ({
    ...styles,
    minHeight: '21px',
    padding: 0,
    margin: 0,
  }),
};

export const formControlView = {
  control: (styles) => ({
    ...styles,
    borderWidth: ' 0.094rem !important',
    backgroundColor: 'transparent !important',
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderBottomColor: 'transparent !important',
    boxShadow: 'none !important',
    fontSize: '1rem',
    borderRadius: '0',

    '&:hover': {
      borderBottom: '1px solid #e3e3e3 !important',
    },
  }),

  indicatorsContainer: (styles) => ({
    ...styles,
    opacity: '0',
  }),

  valueContainer: (styles) => ({
    ...styles,
    padding: '0!important',
  }),
};

export const bpFieldStyles = {
  control: (styles) => ({
    ...styles,
    height: '40px',
    borderColor: '#eee',
  }),
};

export const overTheNextElement = {
  control: (styles) => ({
    ...styles,
    height: '40px',
    borderColor: '#eee',
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    zIndex: '99999',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '150px',
    position: 'relative',
    zIndex: '999999',
  }),
};

export const smallElement = {
  group: (styles) => ({
    paddingBottom: '4px',
  }),
  groupHeading: (styles) => ({
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: '#7f8590',
    padding: '0.5rem',
    letterSpacing: '1px',
  }),
  option: (styles) => ({
    padding: '0.25rem',
    '&:hover': {
      backgroundColor: '#e0ebf8',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '99',
  }),
  container: (styles) => ({
    ...styles,
    flex: '1 1 auto',
    userSelect: 'none',
    height: '25px',
  }),
  control: (styles) => ({
    ...styles,
    // minHeight: "calc(1.6em + 0.75rem + 2px)",
    borderRadius: '0.15rem',
    border: '0.063rem solid $border-color',
    boxShadow: '0 0 0!important',
    fontWeight: '500',

    fontFamily: "'Montserrat', sans-serif",
    userSelect: 'none',
    minHeight: '25px',
    '&:focus': {
      borderColor: '#4391e6',
    },
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '150px',
    userSelect: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '0.85rem',
    paddingRight: '0.85rem',
    height: '25px',
    userSelect: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    userSelect: 'none',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'defaultColor',
    userSelect: 'none',
    height: '25px',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
    userSelect: 'none',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    height: '25px',
    userSelect: 'none',
    '> div': {
      padding: '0',
      userSelect: 'none',
      '&:last-child': {
        paddingRight: '8px',
        userSelect: 'none',
      },
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#e0ebf8',
    border: '0.063rem solid #dce4ec',
    color: '#6c8cb5',
    margin: '-5px 14px 0 -9px',
    '&:first-of-type': {
      color: '#6c8cb5',
    },
    '&:last-child': {
      backgroundColor: 'transparent',
      svg: {
        fill: '#6c8cb5',
      },
    },
  }),
};

export const cardStyles = {
  card: {
    position: 'relative',
    display: 'flex', // Set to flex to properly use flex properties
    flexDirection: 'column',
    height: '250px',
    width: '240px',
    border: '1px solid #ccc',
    margin: '10px',
  },
  image: {
    width: '100%',
    height: '100%', // Make the image take the full height of the card
    objectFit: 'contain',
  },
  cardContent: {
    flex: '1', // Make the card content (image) take remaining space
    overflow: 'hidden',
    position: 'relative', // Position relative for absolute positioning of overlay
  },
  overlay: {
    position: 'absolute',
    top: '0',
    right: '0', // Position the overlay in the top right corner
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '10px',
    boxSizing: 'border-box',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  eyeIcon: {
    color: '#4391e6', // Color for the eye icon
    fontSize: '24px',
    cursor: 'pointer',
    backgroundColor: '#fff', // White background for the eye icon
    padding: '0.2rem 0.7rem 0', // Add padding to the eye icon
    borderRadius: '5px', // Make the background circular
    position: 'absolute', // Position the eye icon within the overlay
    top: '10px', // Adjust the top and right values as needed for positioning
    right: '10px',
  },
  cardFooter: {
    // position: 'relative', // Position relative to allow z-index on overlay
    padding: '10px',
    // backgroundColor: '#f1f1f1',
    textAlign: 'center',
    // zIndex: '1', // Ensure cardFooter is above overlay
  },
  title: {
    margin: '0',
    color: '#000', // Black color for the title text
    backgroundColor: '#fff', // White background for the title area
  },
  activeCard: {
    border: '2px solid #4391e6', // Blue border for the active card
  },
};
