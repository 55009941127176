import axios from "axios";
import Cookies from 'js-cookie'; // To access cookies
import { API_URL, hasAffiliate } from "../Utils/utils";

// Set up Axios instance with `withCredentials` to enable session-based auth
export const RestApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

// Load the CSRF token before making any authenticated requests
export const loadCsrfToken = async () => {
  try {
    await RestApi.get("/sanctum/csrf-cookie"); // This ensures the CSRF token is loaded in cookies
  } catch (error) {
    console.error('Failed to load CSRF token:', error);
  }
};
loadCsrfToken(); // Load CSRF token on app load

// Add request interceptor to automatically include CSRF token in headers
RestApi.interceptors.request.use(
  function (config) {
    // Get the XSRF token from cookies
    const xsrfToken = Cookies.get('XSRF-TOKEN');
    if (xsrfToken) {
      // Add the CSRF token to the X-XSRF-TOKEN header
      config.headers['X-XSRF-TOKEN'] = decodeURIComponent(xsrfToken);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Removed the Bearer token logic as we're using session-based auth now
export const getIdToken = async () => {
  try {
    // CSRF token is loaded automatically in cookies via `withCredentials`
    RestApi.defaults.headers.common = {};
  } catch (e) {
    return null;
  }
};

// Registration function
export function register(data) {
  return RestApi.post("register", data);
}

// Paid registration function
export function paidRegister(data) {
  return RestApi.post("/set-my-password", data);
}

// Fetch logged-in user details after loading CSRF
export function getUser() {
  if (!hasAffiliate()) return RestApi.get("/api/me");
}

// Logout function to destroy the session
export function logout() {
  return RestApi.post("/logout", {});
}

// Login function to authenticate the user
export function login(data) {
  return RestApi.post("/login", data, { withCredentials: true });
}

// Login using a PIN
export function pinLogin(data) {
  return RestApi.post("/pin-login", data);
}

// get access token for linkedin
export function getLinkedInToken(data, type = "linkedin") {
  let url = "http://127.0.0.1:8000/api/linkedin-token";
  let request_url = "https://www.linkedin.com/oauth/v2/accessToken";
  // if (type === "twitter") {
  //   url = "http://127.0.0.1:8000/api/twitter-token";
  //   request_url = "https://api.twitter.com/2/oauth2/token";
  // }
  // const url = "/api/linkedin-auth";
  const dataToBeSent = {
    data: data,
    request_url: request_url,
  };
  return RestApi.post(url, dataToBeSent);
}

// Social login
export function socialLogin(data) {
  return RestApi.post("/social-login", data);
}

// Social login
export function globalSearch(data) {
  return RestApi.post("/api/g-search", data);
}

// to login a Affiliate to dashboard...
export function affiliateLogin(data) {
  return RestApi.post("/affiliate/login", data);
}

export function getCallToken(data) {
  return RestApi.post("/api/call/token", data);
}
/****Social MEdia****/
export function twitterRequestToken(data) {
  // const data = { redirect_uri: redirectUrl };
  return RestApi.post("/api/twitter-request-token", data);
}
export function twitterAccessToken(data) {
  return RestApi.post("/api/twitter-access-token", data);
}
export function getChannelAccessToken(data, channel) {
  if (channel === "facebook") {
    return RestApi.post("/api/facebook-token", data);
  } else if (channel === "twitter") {
    return RestApi.post("/api/twitter-token", data);
  } else if (channel === "linkedin") {
    let request_url = "https://www.linkedin.com/oauth/v2/accessToken";
    const dataToBeSent = {
      data: data,
      request_url: request_url,
    };
    return RestApi.post("/api/linkedin-token", dataToBeSent);
  }
}

// tracking script
export function startTracking(data) {
  return RestApi.get("/saleoidTracker", {
    params: data,
  });
}

// tracking script
export function updateTracking(data) {
  return RestApi.get("/jsonCloseCallback", {
    params: data,
  });
}

//Upload Temporary File.
export function getFileUploadRequest(data = null, operation = null) {
  const url = "/api/upload-temp-document";
  return RestApi.post(url, { ...data, ba_type: "AppModelsTeamUser" });
}
