import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import InLineErrorMessage from '../../../Common/AlertMessage/InLineErrorMessage';
import XAlertMessage from '../../../Common/AlertMessage/XAlertMessage';
import {
  getValidationMessage,
  detectCardType,
  cardTypes,
  getMonthList,
  monthList,
  yearList,
  currentTeamId,
} from '../../../../Utils/utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COUNTRY_LIST } from '../../../../GraphApi/ContactList';
import {
  getCountryByCode,
  getCountriesList,
  getStateList,
} from '../../../../Utils/Countries';
import valid from 'card-validator'; //import statement
import { FormLoader } from '../../../Common/Loader/SkeletonLoader';
import { ADDON_LIST, ADD_PAYMENT_METHOD } from '../../../../GraphApi/Inventory';
import { useTranslation } from 'react-i18next';

export const AddCard = ({ handleCloseAddPaymentMethod, hideClose }) => {
  const { t: locale } = useTranslation(['common', 'pages']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCreditCard] = useState(true);
  const [showPayPal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countryWiseStates, setCountryWiseStates] = useState([]);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const [addNewPaymentMethod] = useMutation(ADD_PAYMENT_METHOD);
  /****/
  /** Country List **/
  const [getCountry, { data: countriesData }] = useLazyQuery(COUNTRY_LIST);

  const [getEqupAddons, { data: equpAddonsData }] = useLazyQuery(ADDON_LIST, {
    fetchPolicy: 'network-only',
  });

  const getAddons = () => {
    getEqupAddons({
      variables: { team_id: currentTeamId },
      fetchPolicy: 'network-only',
    });
  };

  const numberExp = /^[0-9]*$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(
        locale('messages:payment.{{name}} is required', { name: 'Name' })
      )
      .max(
        50,
        locale('messages:payment.{{name}} max {{maxLength}} character long!', {
          name: 'Name',
          maxLength: 50,
        })
      )
      .matches(
        '^[a-zA-Z0-9-._# /s]*$',
        locale(
          'messages:payment.{{name}} can contains # , - _  / . characters only.',
          {
            name: 'Name',
          }
        )
      ),

    card_number: Yup.string()
      .required(
        locale('messages:payment.{{name}} is required', {
          name: 'Credit Card number',
        })
      )
      .test(
        'match', // this is used internally by yup
        locale('messages:payment.{{name}} is invalid', {
          name: locale('Credit Card number'),
        }),
        (value) => {
          return valid.number(parseInt(value)).isValid;
        }
      ),

    month: Yup.string()
      .required(
        locale('messages:payment.{{name}} is required', {
          name: locale('month'),
        })
      )
      .test(
        'match', // this is used internally by yup
        locale('messages:payment.{{name}} is invalid', {
          name: locale('month'),
        }),
        (value) => {
          return valid.expirationMonth(value).isValid;
        }
      ),
    year: Yup.string()
      .required(
        locale('messages:payment.{{name}} is required', {
          name: locale('Year'),
        })
      )
      .test(
        'match', // this is used internally by yup
        locale('messages:payment.{{name}} is invalid', {
          name: locale('Year'),
        }),
        (value) => {
          return valid.expirationYear(value).isValid;
        }
      ),
    cvv: Yup.string()
      .required(
        locale('messages:payment.{{name}} is required', {
          name: locale('cvv number'),
        })
      )
      .test(
        'match', // this is used internally by yup
        locale('messages:payment.{{name}} is invalid', {
          name: locale('cvv number'),
        }),
        (value) => {
          const cvvNum = value ? value.toString() : '';
          const maxLength = formik.values.card_type === 'amex' ? 4 : 3;
          return valid.cvv(cvvNum, maxLength).isValid;
        }
      ),

    address: Yup.string().when('new_address', {
      is: (new_address) => new_address,
      then: Yup.string().required(
        locale('messages:payment.{{name}} is required', {
          name: locale('address'),
        })
      ),
    }),
    city: Yup.string().when('new_address', {
      is: (new_address) => new_address,
      then: Yup.string().required(
        locale('messages:payment.{{name}} is required', {
          name: locale('city'),
        })
      ),
    }),

    state: Yup.string().when('new_address', {
      is: (new_address) => new_address,
      then: Yup.string().required(
        locale('messages:payment.{{name}} is required', {
          name: locale('state'),
        })
      ),
    }),

    country: Yup.object().when('new_address', {
      is: (new_address) => new_address,
      then: Yup.object().required(
        locale('messages:payment.{{name}} is required', {
          name: locale('country'),
        })
      ),
    }),

    zip_code: Yup.string().when('new_address', {
      is: (new_address) => new_address,
      then: Yup.string()
        .required(
          locale('messages:payment.{{name}} is required', {
            name: locale('zip code'),
          })
        )
        .min(
          5,
          locale(
            'messages:payment.{{name}} min {{minLength}} character long!',
            {
              name: locale('zip code'),
              minLength: 5,
            }
          )
        )
        .max(
          6,
          locale(
            'messages:payment.{{name}} max {{maxLength}} character long!',
            {
              name: locale('zip code'),
              maxLength: 6,
            }
          )
        ),
    }),

    // new_address: Yup.nullable(),
    address_id: Yup.string()
      .nullable()
      .when('new_address', {
        is: (new_address) => !new_address,
        then: Yup.string().required(
          locale('messages:payment.{{name}} is required', {
            name: locale('address'),
          })
        ),
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      card_number: '',
      cvv: '',
      month: '',
      year: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      card_type: 'unknown',
      address_id: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      processData(values);
    },
  });

  useEffect(() => {
    if (!localStorage.country) {
      getCountry();
    } else {
      const country = getCountriesList(
        JSON.parse(localStorage.country).countryList
      );
      setCountries(country);
      setCountries(country);
      let allStates = getStateList(
        JSON.parse(localStorage.country).countryList
      );
      setStates(allStates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processData = (data) => {
    setIsSubmitting(true);
    let dataToBeProcess = {
      ...data,
      team_id: currentTeamId,
      expiry_date: data.month + '/' + data.year,
      address_type: data.new_address ? 'billing' : 'billing',
      ...{ country: data.country.countryCode },
    };

    // return false;
    if (!data.new_address) {
      delete dataToBeProcess.new_address;
      delete dataToBeProcess.address;
      delete dataToBeProcess.city;
      delete dataToBeProcess.state;
      delete dataToBeProcess.country;
      delete dataToBeProcess.zip_code;
    } else {
      delete dataToBeProcess.address_id;
    }
    addNewPaymentMethod({
      variables: dataToBeProcess,
    })
      .then((result) => {
        formik.resetForm();
        handleCloseAddPaymentMethod();
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage('error', getValidationMessage(graphQLErrors));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    getAddons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (equpAddonsData) {
      setAddressList(equpAddonsData.getAddresses);
    }
  }, [equpAddonsData]);
  const getAddress = (address) => {
    let myAddress = [];
    if (address) {
      if (address.address) myAddress.push(address.address);
      if (address.city) myAddress.push(address.city);
      if (address.state) myAddress.push(address.state);
      if (address.zip) {
        myAddress.push(address.zip);
      } else if (address.post_code) {
        myAddress.push(address.post_code);
      }
      if (address.country)
        myAddress.push(getCountryByCode(countries, address.country).label);
    }
    return myAddress !== null && myAddress !== undefined
      ? myAddress.join(', ')
      : '';
  };

  useEffect(() => {
    if (countriesData) {
      localStorage.setItem('country', JSON.stringify(countriesData));
      const country = getCountriesList(countriesData.countryList);
      setCountries(country);
      let allStates = getStateList(countriesData.countryList);
      setStates(allStates);
    }
  }, [countriesData]);

  /**Country Bound with Country DialCode **/
  const handleCountry = (county) => {
    formik.setFieldValue('country', county);
    const stateList = states.filter(
      (state) => parseInt(state.countryId) === parseInt(county.value) && state
    );
    formik.setFieldValue('state', '');
    setCountryWiseStates(stateList);
  };
  /*****/
  /**ALERT MESSAGE ***/
  const handleAlertMessage = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
    // scrollToTop(refDrawer);
  };
  /********/
  return (
    <>
      <Modal.Header
        closeVariant='white'
        closeButton={!hideClose}
        className={'bg-dark'}
      >
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          {locale('pages:setting.account.billing.Payment Method')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSubmitting ? (
          <FormLoader />
        ) : (
          <Form>
            <Row style={{ margin: '10px 0' }}>
              <Col>
                <XAlertMessage
                  type={'warning'}
                  message={'Please update your card to continue using EQUP'}
                  setAlertShow={() => {}}
                  alertShow={true}
                  timeToWait={1000000}
                  maxWidth='100%'
                />
                <XAlertMessage
                  type={alertType}
                  message={alertMessage}
                  setAlertShow={setAlertShow}
                  alertShow={alertShow}
                  timeToWait={10000}
                />
              </Col>
            </Row>

            {showCreditCard && (
              <div className='payment-method-details'>
                <Row>
                  <Col md='6'>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        {locale('pages:setting.account.billing.Name on Card')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={locale(
                          'pages:setting.account.billing.Enter name'
                        )}
                        value={formik.values.name ? formik.values.name : ''}
                        onChange={(e) =>
                          formik.setFieldValue('name', e.target.value)
                        }
                      />
                      <InLineErrorMessage
                        type='error'
                        name={'name'}
                        formik={formik}
                        id={'name'}
                      />
                    </Form.Group>
                  </Col>
                  <Col md='6'>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        {locale('pages:setting.account.billing.Card Number')}
                      </Form.Label>
                      <div className='field-icon-right'>
                        <Form.Control
                          type='text'
                          placeholder={locale(
                            'pages:setting.account.billing.Enter card number'
                          )}
                          value={
                            formik.values.card_number
                              ? formik.values.card_number
                              : ''
                          }
                          onChange={(e) => {
                            formik.setFieldValue('card_number', e.target.value);
                            formik.setFieldValue(
                              'card_type',
                              detectCardType(e.target.value)
                            );
                          }}
                        />
                        {formik.values.card_type && (
                          <span className='icon-right'>
                            <img
                              src={cardTypes[formik.values.card_type]}
                              alt={formik.values.card_type}
                            />
                          </span>
                        )}
                      </div>
                      <InLineErrorMessage
                        type='error'
                        name={'card_number'}
                        formik={formik}
                        id={'card_number'}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        {locale('pages:setting.account.billing.CVV')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder={locale(
                          'pages:setting.account.billing.Enter CVV'
                        )}
                        value={formik.values.cvv ? formik.values.cvv : ''}
                        onChange={(e) => {
                          if (
                            e.target.value.length <= 4 &&
                            e.target.value.match(numberExp)
                          ) {
                            formik.setFieldValue(
                              'cvv',
                              e ? e.target.value : null
                            );
                          }
                        }}
                      />
                      <InLineErrorMessage
                        type='error'
                        name={'cvv'}
                        formik={formik}
                        id={'cvv'}
                      />
                    </Form.Group>
                  </Col>
                  <Col md='3'>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        {locale('pages:setting.account.billing.Month')}
                      </Form.Label>
                      <Select
                        options={getMonthList()}
                        placeholder={locale(
                          'pages:setting.account.billing.Select Month'
                        )}
                        value={monthList.filter(
                          (item) =>
                            formik.values.month &&
                            item.value === formik.values.month
                        )}
                        onChange={(e) => {
                          formik.setFieldValue('month', e.value);
                        }}
                      />
                      <InLineErrorMessage
                        type='error'
                        name={'month'}
                        formik={formik}
                        id={'month'}
                      />
                    </Form.Group>
                  </Col>
                  <Col md='3'>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        {locale('pages:setting.account.billing.Year')}
                      </Form.Label>
                      <Select
                        options={yearList}
                        placeholder={locale(
                          'pages:setting.account.billing.Select Year'
                        )}
                        value={yearList.filter(
                          (item) =>
                            formik.values.year &&
                            item.value === formik.values.year
                        )}
                        onChange={(e) => {
                          formik.setFieldValue('year', e.value);
                        }}
                      />
                      <InLineErrorMessage
                        type='error'
                        name={'year'}
                        formik={formik}
                        id={'year'}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h6>
                      {locale('pages:setting.account.billing.Billing Address')}
                    </h6>
                    {addressList &&
                      addressList.map((item, idx) => (
                        <Form.Group
                          className='form-group'
                          key={idx}
                        >
                          <div className='choose-billing-address'>
                            <Form.Check
                              type='radio'
                              name='group1'
                              aria-label='radio 11'
                              checked={
                                parseInt(formik.values.address_id) ===
                                  parseInt(item.id) &&
                                !formik.values.new_address !== undefined &&
                                formik.values.new_address === false
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'address_id',
                                  parseInt(item.id)
                                );
                                formik.setFieldValue('new_address', false);
                              }}
                            />
                            <Form.Control
                              type='text'
                              placeholder=''
                              value={getAddress(item)}
                              onChange={(e) => {}}
                            />
                          </div>
                        </Form.Group>
                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='form-group'>
                      <div className='choose-billing-address'>
                        <Form.Check
                          type='radio'
                          name='group1'
                          aria-label='radio 1'
                          checked={formik.values.new_address}
                          onChange={(e) => {
                            formik.setFieldValue('address_id', null);
                            formik.setFieldValue('new_address', true);
                          }}
                        />
                        <h6>
                          {locale(
                            'pages:setting.account.billing.Create New Billing Address'
                          )}
                        </h6>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <InLineErrorMessage
                  type='error'
                  name={'address_id'}
                  formik={formik}
                  id={'address_id'}
                />
                {formik.values.new_address && (
                  <Row>
                    <Col md='12'>
                      <Form.Group className='form-group'>
                        <Form.Label>
                          {locale('pages:setting.account.billing.New Address')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={locale(
                            'pages:setting.account.billing.Enter new address'
                          )}
                          value={
                            formik.values.address ? formik.values.address : ''
                          }
                          onChange={(e) =>
                            formik.setFieldValue('address', e.target.value)
                          }
                        />
                        <InLineErrorMessage
                          type='error'
                          name={'address'}
                          formik={formik}
                          id={'address'}
                        />
                      </Form.Group>
                    </Col>
                    <Col md='6'>
                      <Form.Group className='form-group'>
                        <Form.Label>
                          {locale('pages:setting.account.billing.Country')}
                        </Form.Label>
                        <Select
                          options={countries}
                          placeholder={locale(
                            'pages:setting.account.billing.Select Country'
                          )}
                          value={formik.values.country}
                          onChange={(e) => {
                            handleCountry(e);
                          }}
                        />
                        <InLineErrorMessage
                          type='error'
                          name={'country'}
                          formik={formik}
                          id={'country'}
                        />
                      </Form.Group>
                    </Col>
                    <Col md='6'>
                      <Form.Group className='form-group'>
                        <Form.Label>
                          {locale('pages:setting.account.billing.State')}
                        </Form.Label>
                        <Select
                          options={countryWiseStates}
                          placeholder={locale(
                            'pages:setting.account.billing.Enter state'
                          )}
                          id='state'
                          name='state'
                          value={countryWiseStates.filter(
                            (item) => item.value === formik.values.state
                          )}
                          onChange={(e) =>
                            formik.setFieldValue('state', e.value)
                          }
                          isSearchable
                        />
                        <InLineErrorMessage
                          type='error'
                          name={'state'}
                          formik={formik}
                          id={'state'}
                        />
                      </Form.Group>
                    </Col>
                    <Col md='6'>
                      <Form.Group className='form-group'>
                        <Form.Label>
                          {locale('pages:setting.account.billing.City')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={locale(
                            'pages:setting.account.billing.Enter city'
                          )}
                          value={formik.values.city ? formik.values.city : ''}
                          onChange={(e) =>
                            formik.setFieldValue('city', e.target.value)
                          }
                        />
                        <InLineErrorMessage
                          type='error'
                          name={'city'}
                          formik={formik}
                          id={'city'}
                        />
                      </Form.Group>
                    </Col>
                    <Col md='6'>
                      <Form.Group className='form-group'>
                        <Form.Label>
                          {locale('pages:setting.account.billing.Zipcode')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={locale(
                            'pages:setting.account.billing.Enter zipcode'
                          )}
                          value={
                            formik.values.zip_code ? formik.values.zip_code : ''
                          }
                          onChange={(e) =>
                            formik.setFieldValue('zip_code', e.target.value)
                          }
                        />
                        <InLineErrorMessage
                          type='error'
                          name={'zip_code'}
                          formik={formik}
                          id={'zip_code'}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            )}
            {showPayPal && (
              <div className='payment-method-paypal'>
                <Row className='align-items-center justify-content-center'>
                  <Col
                    md='6'
                    lg='5'
                  >
                    <div className='setting-add-payment-method'>
                      <div className='payment-icon'>
                        <span>
                          <i className='ri-paypal-fill'></i>
                        </span>
                      </div>
                      <p>
                        {locale(
                          'pages:setting.account.billing.Login To paypal'
                        )}
                      </p>
                      <Button
                        variant='outline-primary'
                        className='btn-ms-icon'
                      >
                        <i className='ri-login-box-line'></i>{' '}
                        <span>
                          {locale('pages:setting.account.billing.Login')}
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        )}
      </Modal.Body>
      {!isSubmitting ? (
        <Modal.Footer>
          {!hideClose && (
            <Button
              variant='outline-secondary btn-ms-icon'
              onClick={!isSubmitting && handleCloseAddPaymentMethod}
              disabled={isSubmitting}
            >
              <i className={'ri-close-line'}></i>{' '}
              <span>{locale('cancel')}</span>
            </Button>
          )}
          <Button
            variant='outline-primary btn-ms-icon'
            onClick={() => formik.handleSubmit()}
          >
            <i className={'ri-save-2-line font-weight-normal'}></i>{' '}
            <span>{locale('save')}</span>
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}
    </>
  );
};
