import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {
  createMarkup,
  creditsListing,
  getTermsAnPolicy,
} from '../../../../Utils/utils';
import { nonGroupColourStyles } from '../../DropDown/Style';
import { Billing } from '../../../Pages/Settings/AccountAndBilling/Billings';
import { PaymentMethodLoader } from '../../Loader/SkeletonLoader';
import {
  ADD_PAYMENT_METHOD,
  DEFAULT_PAYMENT_METHOD,
  PAYMENT_METHOD_ADDRESS_DETAILS,
} from '../../../../GraphApi/Inventory';
import { useLazyQuery, useMutation } from '@apollo/client';
import InLineError from '../../AlertMessage/InLineError';

export const BuyCreditsForm = ({
  team_id,
  locale,
  creditToBuy,
  setCreditToBuy,
  setNoOfCredits,
  isSubmitting,
  latestAvailableCredits = 0,
  handleAlertMessage,
  buyCreditsFunction = () => {},
}) => {
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [tos, setTos] = useState(false);
  const [tosError, setTosError] = useState(null);

  const [addNewPaymentMethod] = useMutation(ADD_PAYMENT_METHOD);
  const [makeDefaultPaymentMethod, { loading: setDefaultLoading }] =
    useMutation(DEFAULT_PAYMENT_METHOD);
  const [
    getPaymentMethodAddressDetails,
    { data: equpAddonsData, loading: billingLoading },
  ] = useLazyQuery(PAYMENT_METHOD_ADDRESS_DETAILS, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (equpAddonsData) {
      setPaymentMethodList(equpAddonsData.getPaymentMethods);
      setAddressList(equpAddonsData.getAddresses);
      equpAddonsData.getPaymentMethods.forEach((element) => {
        if (element.default === true) {
          setHasDefaultPaymentMethod(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equpAddonsData]);

  useEffect(() => {
    if (
      (addressList && addressList.length === 0) ||
      (paymentMethodList && paymentMethodList.length === 0)
    )
      updatePaymentMethodDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePaymentMethodDetails = () => {
    getPaymentMethodAddressDetails({
      variables: { team_id: team_id },
      fetchPolicy: 'no-cache',
    });
  };

  const makePayment = () => {
    buyCreditsFunction();
  };
  /******/
  return (
    <div className='buy-credit-wrap'>
      <Container fluid>
        <div className='p-4 border bg-light'>
          <Row className='justify-content-between align-items-center mb-4'>
            <Col>
              <h3 className='font-weight-normal'>
                {locale('pages:setting.credits.Available Credits.')}
                <span className='text-size-s text-danger'>
                  {' '}
                  {latestAvailableCredits}{' '}
                  {locale('pages:setting.credits.Credit')}
                </span>
              </h3>
            </Col>
          </Row>

          <Row className='justify-content-between align-items-center mb-2'>
            <Col md='6'>
              <Form.Group className='form-group'>
                <Form.Label>
                  {locale('pages:setting.credits.Choose Credits')}
                </Form.Label>
                <Select
                  key={'credit_count'}
                  options={creditsListing}
                  placeholder={locale('pages:setting.credits.Choose Credits')}
                  // value={}
                  defaultValue={'100credits'}
                  onChange={(e) => {
                    setCreditToBuy(e.value);
                    setNoOfCredits(parseInt(e.label));
                  }}
                  styles={nonGroupColourStyles}
                  disabled={isSubmitting}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='justify-content-between align-items-center mb-4'>
            <Col md='6'>
              <div className='add-ons-price h4 font-weight-normal'>
                <span>{locale('Price')}</span> <span>${creditToBuy}</span>
              </div>
            </Col>
          </Row>
          {setDefaultLoading || billingLoading ? (
            <PaymentMethodLoader />
          ) : (
            <>
              <Billing
                paymentMethodList={{
                  paymentMethodList: paymentMethodList,
                  loading: setDefaultLoading || billingLoading,
                  error: null,
                }}
                addNewPaymentMethod={addNewPaymentMethod}
                handleAlertMessage={handleAlertMessage}
                addressList={addressList}
                teamId={team_id}
                makeDefaultPaymentMethod={makeDefaultPaymentMethod}
                locale={locale}
                getAddons={updatePaymentMethodDetails}
              />
              <Row className='my-3'>
                <Col>
                  <Form.Group className='form-group my-3'>
                    <Form.Check // prettier-ignore
                      type={'checkbox'}
                      id='terms_and_condition'
                      checked={tos}
                      label={createMarkup(getTermsAnPolicy(locale))}
                      onChange={() => {
                        setTos(!tos);
                        setTosError(null);
                      }}
                    />
                    <InLineError
                      type='error'
                      name='terms_and_condition'
                      message={tosError}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='justify-content-between align-items-center mb-4 mt-3'>
                <Col>
                  <Button
                    className={'btn btn-outline-primary btn-w-icon btn-ms-icon'}
                    type={'button'}
                    onClick={() => {
                      if (tos) {
                        makePayment();
                      } else if (!tos) {
                        setTosError('Accept Terms & condition');
                      }
                    }}
                    disabled={!hasDefaultPaymentMethod}
                  >
                    <i className={'ri-save-2-line'}></i>{' '}
                    <span>{locale('pages:setting.credits.Add Credits')}</span>
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
