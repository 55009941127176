import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'remixicon/fonts/remixicon.css';
import './Assets/Styles/style.scss';
import './Assets/Styles/responsive.scss';

import ParentContext from './Context/MainContext';
import PipelineContextProvider from './Context/PipelineContext';
import Layouts from './Layouts/Layouts';
import DuplicateContactsContextProvider from './Context/DuplicateContactsContext';
import UnverifiedContactsContextProvider from './Context/UnverifiedContactContext';

import * as Sentry from '@sentry/react';
// import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_ENV === 'production')
  Sentry.init({
    dsn: 'https://ec003705e14e495e95024489da7e250a@o4504394832543744.ingest.sentry.io/4504734671634432',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

const App = () => (
  <ParentContext>
    <PipelineContextProvider>
      <DuplicateContactsContextProvider>
        <UnverifiedContactsContextProvider>
          <Router>
            {/* Need to change layout name at the time of ui implementation */}
            <Layouts />
          </Router>
        </UnverifiedContactsContextProvider>
      </DuplicateContactsContextProvider>
    </PipelineContextProvider>
  </ParentContext>
);

export default App;
