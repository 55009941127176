import React from "react";
import PropTypes from "prop-types";
import { createMarkup } from "../../../Utils/utils";

const alertType = {
  error: { variant: "text-danger", icon: "ri-alert-line", title: "Error!" },
  success: {
    variant: "text-success",
    icon: "ri-checkbox-circle-line",
    title: "Success",
  },
  warning: {
    variant: "text-warning",
    icon: "ri-alert-line",
    title: "Warning!",
  },
};
const style = {
  position: "absolute",
};
const InLineErrorMessage = ({
  type,
  name,
  formik = {},
  id = "",
  extraStyle = {},
  specialPermissions = false,
  touched = false,
}) => {
  /** Style **/
  if (specialPermissions)
    return (
      <React.Fragment>
        <span
          className={"small " + alertType[type].variant}
          style={{ ...style, ...extraStyle }}
          id={id ? id : ""}
        >
          {createMarkup(
            touched && formik && formik.errors[name]
              ? typeof formik.errors[name] === "object"
                ? formik.errors[name].value
                : formik.errors[name]
              : "",
            "emoji"
          )}
        </span>
      </React.Fragment>
    );
  else
    return (
      <React.Fragment>
        <span
          className={"small " + alertType[type].variant}
          style={{ ...style, ...extraStyle }}
          id={id ? id : name}
        >
          {createMarkup(
            formik && formik.touched[name] && formik.errors[name]
              ? typeof formik.errors[name] === "object"
                ? formik.errors[name].value
                : formik.errors[name]
              : "",
            "emoji"
          )}
        </span>
      </React.Fragment>
    );
};

InLineErrorMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default InLineErrorMessage;
