import dayjs from 'dayjs';
import { getLanguage } from './utils';
import { handleTimezoneLocale } from '../i18n';

const utc = require('dayjs/plugin/utc');
const timeZone = require('dayjs/plugin/timezone');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(utc);
dayjs.extend(timeZone);
dayjs.extend(relativeTime);
export const defaultTimeFormat = 'HH:mm:ss';
export const defaultFormat = 'YYYY-MM-DD HH:mm:ss';
export const defaultDateFormat = 'YYYY-MM-DD';
export const availableDateFormat = [
  'DD/MM/YYYY',
  'MMM/DD/YYYY',
  'DD-MM-YYYY',
  'MMM-DD-YYYY',
  'dddd, MMMM DD YYYY',
  'DD/MM/YYYY h:mm:ss A',
  'MMM/DD/YYYY h:mm:ss A',
  'DD-MM-YYYY h:mm:ss A',
  'MMM-DD-YYYY h:mm:ss A',
  'dddd, MMMM DD YYYY, h:mm:ss A',
];

export const getTimeZOneList = () => {
  const aryIanaTimeZones = Intl.supportedValuesOf('timeZone');
  // aryIanaTimeZones.forEach((timeZone) => {
  //   console.log(timeZone);
  // });
  return aryIanaTimeZones;
};
// Reset timezone
export const setDefaultTimezone = () => {
  dayjs.tz.setDefault();
};
// Set User Timezone
export const getUserTimeZone = (currentCompanySetting) => {
  const language = getLanguage(currentCompanySetting);
  handleTimezoneLocale(language);
  return currentCompanySetting && currentCompanySetting.time_zone
    ? currentCompanySetting.time_zone
    : dayjs.tz.guess();
};

export const showTimeZoneString = (
  timezone = dayjs.tz.guess(),
  format = defaultFormat
) => {
  return dayjs().tz(timezone).format(format);
};
// Create new Date Object
export const newDate = (date) => {
  return dayjs(date);
};
// Convert Date with Format
export const getDateformat = (date = null, format = null) => {
  if (date && format !== null) {
    return dayjs(date, format);
  } else if (date && format === null) {
    return dayjs(date);
  } else if (date === null && format !== null) {
    return dayjs({}, format);
  }
};
// Convert Date with Strict Mode
export const getStrictDateformat = (date = null, flag = false) => {
  if (date && flag) {
    return dayjs(date, flag);
  } else if (date) {
    return dayjs(date);
  } else {
    return curentDateObject();
  }
};
// Convert Date with Format
export const getDateformatWise = (date = null, format) => {
  if (date) return dayjs(date).format(format);
  else return curentDateObject().format(format);
};

export const getUnixDate = (dateTime) => {
  dayjs.unix();
};
// Current Date Object
export const curentDateObject = () => {
  return dayjs();
};
//Start Date of Current Month
export const startDateOfCurrentMonth = dayjs().startOf('month');

export const getDuration = (diffValue) => {
  return dayjs.duration(diffValue);
};

// Get the current UTC time
export const getUTCDateTime = (dateTime = null) => {
  if (dateTime) {
    return dayjs(dateTime).utc();
  } else {
    return dayjs.utc();
  }
};

// Format the UTC time as needed
export const formattedUTCDateTime = (dateTime = null, format = null) => {
  if (format) {
    return getUTCDateTime(dateTime).format(format);
  }
  return getUTCDateTime(dateTime);
};

// Convert the Unix timestamp to a dayjs object
export const timeStampToDateDate = (unixTimestamp = null, format = null) => {
  if (unixTimestamp) {
    const dateObj = dayjs.unix(unixTimestamp);
    if (format) {
      return dateObj.format(format);
    }
    return dateObj;
  } else {
    if (format) {
      return dayjs().format(format);
    }
    return dayjs();
  }
};

//Convert Timestamp to Date Time in Desired Timezone and Desired Format.
export const dateInTimezone = (
  timestamp = null,
  timezone = null,
  format = null
) => {
  let dateObj = dayjs();
  if (timestamp) {
    dateObj = timeZone(timestamp);
    if (timezone) {
      dateObj = dateObj.tz(timezone);
      if (format) {
        return dateObj.tz(timezone).format(format);
      }
      return dateObj;
    } else if (format) {
      return dateObj.format(format);
    }
    return dateObj;
  } else {
    if (timezone) {
      dateObj = dateObj.tz(timezone);
      if (format) {
        return dateObj.tz(timezone).format(format);
      }
      return dateObj;
    } else if (format) {
      return dateObj.format(format);
    }
    return dateObj;
  }
};

//Already Used In CurrentSetting.js
export const convertToTimestamp = (dateTime, isUtc = false) => {
  const isoFormats = {
    am: 'YYYY-MM-DD HH:mm a',
    pm: 'YYYY-MM-DD HH:mm a',
    AM: 'YYYY-MM-DD HH:mm A',
    PM: 'YYYY-MM-DD HH:mm A',
  };

  const formatKeys = Object.keys(isoFormats);
  let selectedFormat = formatKeys.filter((item) => dateTime.includes(item));
  selectedFormat =
    selectedFormat.length > 0 ? isoFormats[selectedFormat[0]] : defaultFormat;
  if (isUtc) {
    return dayjs(dateTime, selectedFormat).valueOf();
  } else {
    return dayjs(dateTime, selectedFormat).valueOf();
  }
};
