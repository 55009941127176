import { gql } from "@apollo/client";

export const TEAM_MEMBER_LIST = gql`
  query getTeamMembers(
    $first: Int!
    $page: Int
    $search: String
    $team_id: Int
  ) {
    getTeamMembers(
      first: $first
      page: $page
      search: $search
      team_id: $team_id
    ) {
      data {
        id
        first_name
        last_name
        name
        email
        company
        website
        phone_no
        team_id
        contact_assigned
        member_since
        profile_picture
        role {
          id
          name
          permissions {
            id
            name
            label
            industry
            pivot {
              access_type
            }
          }
        }
        permissions {
          id
          name
          label
          industry
          pivot {
            access_type
          }
        }
        status
        last_logged_in_at
        created_at
        updated_at
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
    getAllRoles(team_id: $team_id) {
      id
      team_id
      name
      editable
      role_type
      no_of_users
      status
      permissions {
        id
        name
        label
        industry
        pivot {
          access_type
        }
      }
      created_at
      updated_at
    }
  }
`;
/*****/
export const TEAM_MEMBER_CREATE = gql`
  mutation createTeamUser(
    $first_name: String
    $last_name: String
    $email: String
    $company: String
    $phone_no: String
    $role_id: Int
    $team_id: Int
  ) {
    createTeamUser(
      team_id: $team_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      company: $company
      phone_no: $phone_no
      role_id: $role_id
    ) {
      id
      first_name
      last_name
      name
      email
      company
      website
      phone_no
      recommended_tasks
      industry_info
      import_asset
      team_id
      role {
        id
        name
      }
      status
      contact_assigned
      member_since
      last_logged_in_at
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($team_id: Int, $settings: String) {
    updateSettings(team_id: $team_id, settings: $settings) {
      id
    }
  }
`;

export const MEMBER_UPDATE = gql`
  mutation updateTeamUser(
    $id: ID
    $team_id: Int
    $first_name: String
    $last_name: String
    $company: String
    $website: String
    $phone_no: String
    $role_id: Int
    $permissions: [PermissionInnerObj]
    $recommended_tasks: String
    $enabled_notifications: [EnabledNotifications]
    $profile_picture: Upload
    $signature: String
    $job_profile: String
  ) {
    updateTeamUser(
      id: $id
      team_id: $team_id
      first_name: $first_name
      last_name: $last_name
      company: $company
      website: $website
      phone_no: $phone_no
      role_id: $role_id
      permissions: $permissions
      recommended_tasks: $recommended_tasks
      enabled_notifications: $enabled_notifications
      profile_picture: $profile_picture
      signature: $signature
      job_profile: $job_profile
    ) {
      id
      first_name
      last_name
      name
      email
      company
      website
      phone_no
      recommended_tasks
      industry_info
      import_asset
      team_id
      profile_picture
      signature
      job_profile
      role {
        id
        name
        permissions {
          id
          name
          label
          industry
          pivot {
            access_type
          }
        }
      }
      permissions {
        id
        name
        label
        industry
        pivot {
          access_type
        }
      }
      status
      contact_assigned
      member_since
      last_logged_in_at
      created_at
      updated_at
    }
  }
`;

export const MEMBER_DELETE = gql`
  mutation deleteMember($id: ID, $assign_to: Int, $team_id: Int) {
    deleteTeamUser(id: $id, assign_to: $assign_to, team_id: $team_id) {
      status
    }
  }
`;

export const USER_SETTING = gql`
  query getUserSetting($team_id: Int, $isTeamId: Boolean!) {
    me {
      pin_data
      email
      email_verified_at
      contact_id
      teams {
        id
        company_name
        company_logo
        lt
        is_equp_team
        require_card
        industry_info
        pivot {
          id
          user_id
          contact_id
          team_id
          role_id
          first_name
          last_name
          name
          company
          website
          phone_no
          recommended_tasks
          industry_info
          import_asset
          status
          last_logged_in_at
          enabled_notifications
          profile_picture
          signature
          job_profile
          is_activated
          is_super_admin
          role {
            id
            name
            role_type
            permissions {
              id
              name
              label
              industry
              pivot {
                access_type
              }
            }
          }
        }
        settings {
          setting_name
          setting_value
        }
      }
    }
    getMyPermissions(team_id: $team_id) @include(if: $isTeamId) {
      id
      name
      label
      industry
      pivot {
        access_type
      }
    }
    getTeamUser(team_id: $team_id) {
      profile_picture
      signature
      job_profile
    }
  }
`;

export const SAVE_SETTING = gql`
  mutation updateCompanySettings(
    $phone_number_format: String
    $number_format: String
    $date_format: String
    $time_zone: String
    $language: String
    $currency: String
    $company_logo: Upload
    $company_name: String
    $company_website: String
    $company_address: Address
    $team_id: Int
    $scoring_meter: String
    $sandbox_mode: String
    $phone_number: String
  ) {
    updateCompanySettings(
      phone_number_format: $phone_number_format
      number_format: $number_format
      date_format: $date_format
      time_zone: $time_zone
      language: $language
      currency: $currency
      company_logo: $company_logo
      company_name: $company_name
      company_website: $company_website
      company_address: $company_address
      team_id: $team_id
      scoring_meter: $scoring_meter
      sandbox_mode: $sandbox_mode
      phone_number: $phone_number
    ) {
      id
      setting_name
      setting_value
      created_at
      updated_at
    }
  }
`;

/** GET TEAM USER INFO**/
export const TEAM_USER_INFO = gql`
  query getTeamUserInfo($s: String, $team_id: Int) {
    getTeamUserInfo(s: $s, team_id: $team_id) {
      company
      logo
      role
      first_name
      last_name
      email
      team {
        company_name
      }
    }
  }
`;

/** GET TEAM USER INFO**/
export const GET_TEAM_USER = gql`
  query getTeamUser($team_id: Int) {
    getTeamUser(team_id: $team_id) {
      id
      name
      phone_no
      role {
        id
        name
      }
      first_name
      last_name
      email
      enabled_notifications
      profile_picture
      signature
      job_profile
      last_logged_in_at
      team {
        company_address
        company_name
      }
    }
  }
`;
/** SET TEAM USER PASSWORD **/
export const SET_PASSWORD = gql`
  mutation setTeamUserPassword($s: String, $password: String, $team_id: Int) {
    setTeamUserPassword(s: $s, password: $password, team_id: $team_id) {
      id
      first_name
      last_name
      name
      email
      company
      website
      phone_no
      recommended_tasks
      industry_info
      import_asset
      team_id
      role {
        id
        name
      }
      currentUserPermission: permissions {
        id
        name
        label
        industry
        pivot {
          access_type
        }
      }
      status
      token
      contact_assigned
      member_since
      last_logged_in_at
      created_at
      updated_at
    }
  }
`;

/** SET TEAM SWITCH COMPANY **/
export const SWITCH_COMPANY = gql`
  mutation chooseCompany($s: String, $team_id: Int) {
    setCurrentTeam(s: $s, team_id: $team_id) {
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($s: String, $password: String, $team_id: Int) {
    resetTeamUserPassword(s: $s, password: $password, team_id: $team_id) {
      id
      first_name
      last_name
      name
      email
      company
      website
      phone_no
      recommended_tasks
      industry_info
      import_asset
      team_id
      role {
        name
      }
      status
      contact_assigned
      member_since
      last_logged_in_at
      token
      created_at
      updated_at
    }
  }
`;

export const COMPANY_ACCOUNTS = gql`
  query getTeamsListing($team_id: Int) {
    getTeamsListing(team_id: $team_id) {
      id
      company_name
      created_by
      status
      created_at
      updated_at
      industry_info
      created_by_user
    }
  }
`;

export const TEAM_MEMBER_BY_ID = gql`
  query getTeamMember($team_id: Int, $id: Int) {
    getTeamMember(team_id: $team_id, id: $id) {
      id
      first_name
      last_name
      name
      email
      company
      website
      phone_no
      recommended_tasks
      industry_info
      import_asset
      team_id
      profile_picture
      role {
        id
        name
        rolePermission: permissions {
          id
          name
          label
          industry
          pivot {
            roleAccessType: access_type
          }
        }
      }
      status
      contact_assigned
      member_since
      last_logged_in_at
      myPermissions: permissions {
        id
        name
        label
        industry
        pivot {
          specialAccessType: access_type
        }
      }
      created_at
      updated_at
    }
  }
`;

/***SETUP USER DETAILS ***/
export const SETUP_USER = gql`
  mutation updateUser(
    $first_name: String
    $last_name: String
    $company: String
    $website: String
    $recommended_tasks: String
    $industry_info: String
    $import_asset: String
    $team_id: Int
  ) {
    updateUser(
      team_id: $team_id
      input: {
        first_name: $first_name
        last_name: $last_name
        company: $company
        website: $website
        recommended_tasks: $recommended_tasks
        industry_info: $industry_info
        import_asset: $import_asset
      }
    ) {
      id
      first_name
      last_name
      email
      company
      website
      remember_token
      email_verified_at
      recommended_tasks
      industry_info
      import_asset
      pin_data
      teams {
        id
        # profiles {
        #   id
        #   name
        #   description
        #   created_at
        #   updated_at
        # }
        # roles {
        #   id
        #   parentRole {
        #     id
        #     name
        #     description
        #     data_sharing
        #     hierarchy_level
        #   }
        #   childRoles {
        #     id
        #     name
        #     description
        #     data_sharing
        #     hierarchy_level
        #   }
        #   name
        #   description
        #   data_sharing
        #   hierarchy_level
        #   created_at
        #   updated_at
        # }
      }
      created_at
      updated_at
    }
  }
`;

export const GENERATE_PIN = gql`
  mutation refreshPin($team_id: Int) {
    updatePin(team_id: $team_id) {
      status
      pin
    }
  }
`;

/** RESEND EMAIL AND CHANGE EMAIL **/
export const RESEND_EMAIL = gql`
  mutation changeEmailForUnconfirmed($team_id: Int, $email: String) {
    changeEmailForUnconfirmed(team_id: $team_id, email: $email) {
      status
    }
  }
`;
