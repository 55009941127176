import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import Session from '../../../Utils/Session';
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from '../../../Utils/utils';
import InLineError from '../AlertMessage/InLineError';
import { useTranslation } from 'react-i18next';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const OverlayPopup = ({
  showPopup,
  email,
  changeEmailForUnconfirmed,
  fetchUserSettings,
  isUserActive,
}) => {
  const { t: locale } = useTranslation(['common', 'pages']);
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [resendCount, setResendCount] = useState(0);
  const [changedEmail, setChangedEmail] = useState('');

  useEffect(() => {
    let count = 0;
    try {
      count = Session.getSessionVar('resend');
      count = isObjectEmpty(count) ? 0 : count;
    } catch (e) {
      console.log(e);
      count = 1;
    }

    setResendCount(parseInt(count));
    setStatus(
      <span className='text-success font-weight-bold'>
        {locale('Verification email sent successfully to {{newEmail}}', {
          newEmail: email,
        })}
      </span>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateEmail = (value) => {
    return String(value).toLowerCase().match(emailRegex);
  };

  const resendEmail = (newEmail = null) => {
    if (resendCount >= 3) {
      setStatus(
        <span className='text-danger'>{locale('Unable to send email')}</span>
      );
      return false;
    } else if (newEmail && resendCount >= 3 && newEmail === email) {
      setError(locale('Unable to send email'));
      setStatus(
        <span className='text-danger'>{locale('Unable to send email')}</span>
      );
      return false;
    } else if (isObjectEmpty(newEmail) && !isObjectEmpty(changedEmail)) {
      if (!validateEmail(changedEmail)) {
        setError(locale('Invalid email'));
        return false;
      } else {
        newEmail = changedEmail;
      }
    } else if (!isObjectEmpty(newEmail)) {
      //DO Nothing...
    } else {
      setStatus(<span className='text-danger'>{locale('Invalid email')}</span>);
      setError(locale('Invalid email'));
      return false;
    }

    changeEmailForUnconfirmed({
      variables: {
        email: newEmail,
        team_id: currentTeamId,
      },
      update: () => newEmail !== email && fetchUserSettings,
    })
      .then((results) => {
        if (
          results.data &&
          results.data.changeEmailForUnconfirmed &&
          results.data.changeEmailForUnconfirmed.status &&
          newEmail === email
        ) {
          Session.storeSessionVar('resend', parseInt(resendCount) + 1, 3600);
          setResendCount(parseInt(resendCount) + 1);
          setStatus(
            <span className='text-success font-weight-bold'>
              {locale('Verification email sent successfully to {{newEmail}}', {
                newEmail: newEmail,
              })}
            </span>
          );
          setError('');
        } else if (
          results.data &&
          results.data.changeEmailForUnconfirmed &&
          results.data.changeEmailForUnconfirmed.status &&
          newEmail !== email
        ) {
          setStatus(
            <span className='text-success font-weight-bold'>
              {locale('Verification email sent successfully to {{newEmail}}', {
                newEmail: newEmail,
              })}
            </span>
          );
          setError('');
        } else {
          setError(locale('messages:backend.something went wrong.'));
          setStatus(
            <span className='text-danger'>
              {locale('messages:backend.something went wrong.')}
            </span>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log('Error:', graphQLErrors);
        const myError = locale(
          'messages:backend.' + getValidationMessage(graphQLErrors)
        );
        setError(myError);
        setStatus(<span className='text-danger'>{myError}</span>);
      });
  };

  //Render HTML
  return (
    <Modal
      id='verifyEmail'
      className={'common modalLg'}
      show={showPopup}
      backdrop='static'
      keyboard={false}
      centered
    >
      <Modal.Header className={'bg-dark'}>
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          {!isUserActive ? (
            <React.Fragment>Account Suspended</React.Fragment>
          ) : (
            <React.Fragment>
              {!isChangeEmail
                ? locale('Verify your email to activate your EQUP account')
                : locale('Change Email')}
            </React.Fragment>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isUserActive ? (
          <React.Fragment>
            <div className='fs-5 m-3 p-2'>
              Our system was not able to validate the email you used to sign up
              for EQUP account. Hence this account has been suspended. If you
              think it's a mistake reach us via chat or email us on
              <a
                href='mailto:info@equp.com'
                className='ps-1'
              >
                info@equp.com
              </a>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isChangeEmail ? (
              <div className={'alert-in-popup alert-in-danger'}>
                <div className={'alert-in-popup-inner'}>
                  <h3 className='mt-1'>
                    {locale('Your email is not verified.')}
                  </h3>
                  <p style={{ fontSize: '18px' }}>
                    {locale(
                      'Please check your email to verify it, refresh this page if already verified.'
                    )}
                  </p>
                  <p>{status ? status : ' '}</p>
                </div>
              </div>
            ) : (
              <Row>
                <Col>
                  <p>{status ? status : ' '}</p>
                  <Form.Group className='form-group'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      id={'email'}
                      type='text'
                      placeholder={'Email'}
                      value={changedEmail ? changedEmail : email}
                      onChange={(e) => {
                        setChangedEmail(e.target.value);
                      }}
                    />
                    <InLineError
                      type='error'
                      name='email'
                      message={error}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </Modal.Body>
      {isUserActive && (
        <Modal.Footer>
          {!isChangeEmail && resendCount < 3 ? (
            <Button
              variant='outline-primary btn-ms-icon'
              onClick={() => {
                if (resendCount < 3) {
                  resendEmail(email);
                }
              }}
              disabled={resendCount >= 3}
            >
              <i className={'ri-mail-send-line font-weight-normal'}></i>{' '}
              <span>{locale('Resend Email')}</span>
            </Button>
          ) : (
            <Button
              variant='outline-primary btn-ms-icon'
              onClick={() => resendEmail()}
            >
              <i className={'ri-mail-send-line font-weight-normal'}></i>{' '}
              <span>{locale('Change Email')}</span>
            </Button>
          )}{' '}
          {!isChangeEmail ? (
            <Button
              variant='outline-primary btn-ms-icon'
              onClick={() => {
                setIsChangeEmail(true);
                setStatus(null);
              }}
            >
              <i className={'ri-mail-add-line font-weight-normal'}></i>
              <span>{locale('Change Email')}</span>
            </Button>
          ) : (
            <Button
              variant='light outline-light'
              className={' me-2'}
              onClick={() => setIsChangeEmail(false)}
            >
              <i className={'ri-close-line font-weight-normal'}></i>
              <span>{locale('Cancel')}</span>
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default OverlayPopup;
