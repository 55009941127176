import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Container, Button } from 'react-bootstrap';
import '../../../Pages/Login/login.scss';
import { USER_SETTING, SWITCH_COMPANY } from '../../../GraphApi/TeamMembers';
import {
  toWordCase,
  getValidationMessage,
  getCurrentTeamId,
  getFirstCharacter,
  isObjectEmpty,
  currentTeamId,
} from '../../../Utils/utils';

import InLineError from '../../Common/AlertMessage/InLineError';
import { Navigate, useLocation } from 'react-router-dom';
import { ListLoader } from '../../Common/Loader/SkeletonLoader';
import { AuthContext } from '../../../Context/AuthContext';
import Session from '../../../Utils/Session';
// import { PipelineContext } from '../../../Context/PipelineContext';
import { useTranslation } from 'react-i18next';

const restrictedUrl = ['/', '/choose-company'];

const ChooseCompany = ({ mode = 'page', handleClose = () => {} }) => {
  const location = useLocation();
  const { t: locale } = useTranslation(['common', 'pages']);
  const [redirect, setRedirect] = useState(false);
  const [teamsData, setTeamsData] = useState([]);

  const [errors, setErrors] = useState(null);
  const [teamSelected, setTeamSelected] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [companyChanged, setCompanyChanged] = useState(false);
  const { userData, authUser } = useContext(AuthContext);
  // const { getPipelines } = useContext(PipelineContext);

  const [getUserData, { data: settingData, loading }] = useLazyQuery(
    USER_SETTING,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [chooseCompany, { loading: updating }] = useMutation(SWITCH_COMPANY);

  useEffect(() => {
    if (
      userData &&
      userData.authenticated &&
      userData.authData &&
      userData.authData.teams
    ) {
      setTeamsData([...userData.authData.teams]);
    } else if (userData && userData.authenticated) {
      getUserSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (!isObjectEmpty(settingData) && !isObjectEmpty(settingData.me)) {
      setTeamsData([...settingData.me.teams]);
      setTeamSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingData]);

  useEffect(() => {
    if (teamId && teamSelected) {
      switchCompany(teamId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, teamSelected]);

  const getUserSettings = (teamId = null) => {
    getUserData({
      variables: { team_id: parseInt(teamId), isTeamId: !!teamId },
      fetchPolicy: 'no-cache',
    });
  };

  const handleSaveAuthData = async () => {
    await authUser();
    // await getPipelines();
    setTimeout(() => {
      setRedirect(true);
      setCompanyChanged(false);
    }, 1000);
  };

  /*** Call Switch Company ***/
  const handleSwitchCompany = (team_id) => {
    if (parseInt(team_id) === parseInt(currentTeamId)) {
      setRedirect(true);
      setCompanyChanged(false);
      handleClose();
    } else if (team_id) {
      setTeamId(team_id);
      getUserSettings(team_id);
    } else {
      setErrors(locale('Select company to switch'));
    }
  };
  /** Apply Switch Company **/
  const switchCompany = (team_id) => {
    chooseCompany({ variables: { s: window.btoa(team_id) } })
      .then((result) => {
        setCompanyChanged(true);
        handleSaveAuthData();
        Session.removeSessionVar('equp_announcements');
        Session.removeSessionVar('currentTeam');
        Session.storeSessionVar('currentTeam', team_id);
        localStorage.setItem('currentTeam', team_id);
        getCurrentTeamId(team_id);
        // handleClose();
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        setErrors(getValidationMessage(graphQLErrors));
      });
  };

  /**** */
  if (redirect && !companyChanged) {
    if (
      location &&
      location.state &&
      location.state.landingUrl &&
      restrictedUrl.indexOf(location.state.landingUrl) === -1
    ) {
      return <Navigate to={location.state.landingUrl} />;
    } else {
      // return <Navigate to={"/dashboard"} />;
      window.location.href = '/dashboard';
    }
  }

  if (loading || updating || companyChanged) {
    return (
      <Container fluid>
        <ListLoader />
      </Container>
    );
  }
  const getCompanyName = (team) => {
    if (team && !isObjectEmpty(team.company_name)) {
      return team.company_name;
    } else if (team && team.pivot && !isObjectEmpty(team.pivot.company)) {
      return team.pivot.company;
    } else {
      return '-';
    }
  };
  /******/
  return (
    <div className='login-signup-form login-signup-form-style-2 choose-company-form'>
      <div className='form-inner-main-bar'>
        <div className='form-field-bar align-middle'>
          <div className='form-field-bar-inner'>
            {mode === 'page' && (
              <>
                <div className='brand-logo-form'>
                  <img
                    src={require('../../../Assets/Images/equp-logo.svg')}
                    alt=''
                  />
                </div>
                <React.Fragment>
                  <div className='form-welcome-text'>
                    <h2>{locale('Welcome Back')}</h2>
                    <p>
                      {locale(
                        'You Can Login to any of your companies from here.'
                      )}
                    </p>
                  </div>
                  <h3>{locale('Choose Company To Sign In')}</h3>
                </React.Fragment>
              </>
            )}
            <div className='choose-company-sign-in'>
              <div>
                <InLineError
                  type='error'
                  message={errors}
                  extraStyle={{ position: 'relative' }}
                />
              </div>
              {teamsData.length > 0 &&
                teamsData.map(
                  (team, idx) =>
                    team.pivot &&
                    team.pivot.is_activated && (
                      <div
                        className='company-sign-in-list'
                        key={idx}
                      >
                        <div className='company-logo'>
                          {team.company_logo ? (
                            <img
                              src={team.company_logo}
                              alt=''
                            />
                          ) : (
                            getFirstCharacter(getCompanyName(team))
                          )}
                        </div>
                        <div className='company-details'>
                          <h4>{getCompanyName(team)}</h4>
                          <p>
                            {team.pivot &&
                            team.pivot.role &&
                            team.pivot.role.name
                              ? toWordCase(team.pivot.role.name)
                              : ''}
                          </p>
                        </div>
                        <Button
                          variant='primary'
                          className='btn-outline-primary'
                          onClick={() => {
                            handleSwitchCompany(team && team.id ? team.id : '');
                          }}
                        >
                          <i className='ri-arrow-right-line'></i>
                        </Button>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseCompany;
