import React, { useEffect, useState } from 'react';
import { NavDropdown, Modal } from 'react-bootstrap';
import {
  checkModPermission,
  currentTeamId,
  exceptNotificationTypes,
  getNotificationContent,
  getRedirectUrl,
  getTimeAgo,
  hasClient,
} from '../Utils/utils';
import { Link, Navigate } from 'react-router-dom';
import { SearchResultLoading } from '../Components/Common/Loader/SkeletonLoader';
import { ImportSummary } from '../Components/Profile/ImportSummary';
import {
  GET_NOTIFICATION_BY_ID,
  MARK_READ_NOTIFICATION,
  NOTIFICATIONS,
} from '../GraphApi/Users/Notifications';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Media } from '../Components/Common/Media/Media';
import { CallNotifications } from './CallNotifications';
// import { useTranslation } from "react-i18next";

const avtarList = {
  note_created: 'ri-file-list-2-line',
  document_added: 'ri-folder-2-line',
  document_uploaded: 'ri-folder-2-line',
  quote_created_updated: 'ri-chat-quote-line',
  invoice_created_updated: 'ri-todo-line',
  appointment_reminder: 'ri-calendar-check-line',
  appointment_create: 'ri-calendar-check-line',
  appointment_update: 'ri-calendar-check-line',
  deal_created: 'ri-price-tag-2-fill',
  deal_assigned: 'ri-price-tag-2-fill',
  deal_creation: 'ri-price-tag-2-fill',
  task_appointment: 'ri-calendar-check-line',
  contact_assigned: 'ri-contacts-line',
  contact_lifecycle_stage_change: 'ri-organization-chart',
  contact_hot_warm_lead: 'ri-meteor-line',
  invoice_paid: 'ri-todo-line',
  contact_import: 'ri-chat-upload-line',
  deal_import: 'ri-price-tag-2-fill',
  email_received: 'ri-mail-line',
  form_submission: 'ri-checkbox-circle-line',
  contact_visits_website: 'ri-global-fill',
  last_week_saved_quotes: 'ri-chat-quote-line',
  inventory_consumption: 'ri-article-line',
  tracking_notification: 'ri-map-pin-user-line',
  total_todays_task: 'ri-calendar-check-line',
  import_error: 'ri-chat-upload-line',
  payment_failed: 'ri-bank-card-line',
  message_received: 'ri-message-fill',
  booking_purchased: 'ri-calendar-check-line',
  note_creation: 'ri-file-list-2-line',
  document_requested: 'ri-folder-2-line',
  agreement: 'ri-file-list-2-line',
  estimate: 'ri-draft-line',
  default: '',
};

const avtarListClass = {
  note_created: 'avtar-bg-yellow',
  document_added: 'avtar-bg-blue',
  document_uploaded: 'avtar-bg-blue',
  quote_created_updated: 'avtar-bg-green',
  invoice_created_updated: 'avtar-bg-purple',
  appointment_reminder: 'avtar-bg-light-green',
  appointment_create: 'avtar-bg-orange',
  appointment_update: 'avtar-bg-orange',
  deal_created: 'avtar-bg-yellow',
  deal_assigned: 'avtar-bg-red',
  deal_creation: 'avtar-bg-yellow',
  task_appointment: 'avtar-bg-orange',
  contact_assigned: 'avtar-bg-orange',
  contact_lifecycle_stage_change: 'avtar-bg-pink',
  contact_hot_warm_lead: 'avtar-bg-pink',
  invoice_paid: 'avtar-bg-light-blue',
  contact_import: 'avtar-bg-light-green',
  deal_import: 'avtar-bg-light-green',
  email_received: 'avtar-bg-purple',
  form_submission: 'avtar-bg-purple',
  contact_visits_website: 'avtar-bg-light-blue',
  last_week_saved_quotes: 'avtar-bg-green',
  inventory_consumption: 'avtar-bg-yellow',
  tracking_notification: 'avtar-bg-light-blue',
  total_todays_task: 'avtar-bg-light-green',
  import_error: 'avtar-bg-red',
  payment_failed: 'avtar-bg-red',
  booking_purchased: 'avtar-bg-orange',
  note_creation: 'avtar-bg-light-green',
  document_requested: 'avtar-bg-blue',
  default: '',
};

export const Notification = ({
  currentCompanySetting,
  userData,
  locale,
  industry,
}) => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [redirectAbleData, setRedirectAbleData] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [showImportSummary, setShowImportSummary] = useState(false);
  const [newNotifications, setNewNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [notificationDropdown] = useState(
    <svg
      fill='#fff'
      width='22'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g>
        <path
          fill='none'
          d='M0 0h24v24H0z'
        />
        <path d='M20 17h2v2H2v-2h2v-7a8 8 0 1 1 16 0v7zm-2 0v-7a6 6 0 1 0-12 0v7h12zm-9 4h6v2H9v-2z' />
      </g>
    </svg>
  );

  const [markReadNotification] = useMutation(MARK_READ_NOTIFICATION);
  const [
    refetchNotification,
    { data: lazyNotifications, loading: lazyNotificationLoading },
  ] = useLazyQuery(NOTIFICATIONS, {
    variables: {
      team_id: currentTeamId,
    },
    fetchPolicy: 'no-cache',
  });

  const [
    getNotificationById,
    { data: newNotificationsData, error: newNotificationsError },
  ] = useLazyQuery(GET_NOTIFICATION_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  /***** NEW NOTIFICATIONS ******/
  useEffect(() => {
    if (isError) {
      console.log('error:', isError);
    }
  }, [isError]);

  useEffect(() => {
    setIsLoading(lazyNotificationLoading);
  }, [lazyNotificationLoading]);

  useEffect(() => {
    if (
      lazyNotifications &&
      lazyNotifications.getNotifications &&
      lazyNotifications.getNotifications.unread_notification_count > 0
    ) {
      setNewNotifications(lazyNotifications.getNotifications.notifications);
      setUnreadCount(
        lazyNotifications.getNotifications.unread_notification_count
      );
    } else if (lazyNotifications && lazyNotifications.getNotifications) {
      setNewNotifications([]);
      setUnreadCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyNotifications]);

  useEffect(() => {
    if (newNotificationsData && newNotificationsData.getNotificationById) {
      let formattedNotificationData = newNotifications;
      formattedNotificationData =
        formattedNotificationData && formattedNotificationData.length
          ? formattedNotificationData
          : [];
      setNewNotifications([
        newNotificationsData.getNotificationById,
        ...formattedNotificationData,
      ]);
      setUnreadCount(unreadCount + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotificationsData]);
  /************/
  const fetchNewNotification = (id) => {
    getNotificationById({
      variables: { team_id: currentTeamId, notification_id: id },
    });
  };
  /***********/

  const handleNotification = (notification) => {
    const { contact_id } = notification.data ? notification.data : {};
    const hasRedirectAble =
      exceptNotificationTypes.indexOf(notification.notification_type) > -1;
    // console.log("notification:", notification, hasRedirectAble);
    const values = getRedirectUrl(notification, industry, hasClient(userData));

    if (hasRedirectAble || contact_id) {
      setRedirectAbleData(values);
    } else {
      if (values.state && values.state.nId) {
        markNotificationsAsRead(values.state.nId);
      }
    }
  };

  useEffect(() => {
    if (redirectAbleData !== null && redirectAbleData.pathname !== null) {
      if (redirectAbleData.state && redirectAbleData.state.nId) {
        markNotificationsAsRead(redirectAbleData.state.nId);
      }
      setIsRedirect(true);
    } else if (
      redirectAbleData !== null &&
      redirectAbleData.pathname === null
    ) {
      markNotificationsAsRead(redirectAbleData.nId);
      viewRow(redirectAbleData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectAbleData]);

  const viewRow = (data) => {
    if (data) {
      switch (data.notificationType) {
        case 'contact_import':
          const importData = JSON.parse(data.importData);
          setSummaryData({
            ...importData,
            type: importData.module,
          });
          setShowImportSummary(true);
          break;
        // case "deal_import":
        //   setSummaryData({
        //     ...JSON.parse(data.importData),
        //     type: data.notificationType,
        //   });
        //   setShowImportSummary(true);
        //   break;
        default:
          // console.log(data.notificationType);
          break;
      }
    }
    return { pathname: '#' };
  };

  const markNotificationsAsRead = (id) => {
    if (
      newNotifications &&
      newNotifications.filter(
        (notification) =>
          notification.id === id && notification.read_at === null
      ).length > 0
    ) {
      markReadNotification({
        variables: {
          team_id: currentTeamId,
          d: id,
        },
      }).then((result) => {
        if (
          result.data.markReadNotification &&
          result.data.markReadNotification.status
        ) {
          refetchNotification({
            variables: {
              team_id: currentTeamId,
            },
            fetchPolicy: 'no-cache',
          });
        }
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsRedirect(false);
      setRedirectAbleData(null);
    }, [1000]);
  }, [isRedirect]);

  if (isRedirect) {
    return (
      <React.Fragment>
        {userData &&
          userData.authData &&
          userData.authData.currentTeam &&
          checkModPermission(
            userData.authData.currentTeam.lt,
            'notifications'
          ) && (
            <CallNotifications
              newNotifications={newNotifications}
              setNewNotifications={setNewNotifications}
              unreadCount={unreadCount}
              setUnreadCount={setUnreadCount}
              fetchNewNotification={fetchNewNotification}
              newNotificationsError={newNotificationsError}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              isError={isError}
              setIsError={setIsError}
            />
          )}
        <NavDropdown
          title={notificationDropdown}
          align='end'
          id='basic-nav-dropdown'
          className={
            newNotifications && unreadCount > 0
              ? 'notification-dropdown new-notify'
              : 'notification-dropdown'
          }
        ></NavDropdown>
        <Navigate {...redirectAbleData} />;
      </React.Fragment>
    );
  }

  /*************************/

  return (
    <React.Fragment>
      {userData &&
        userData.authData &&
        userData.authData.currentTeam &&
        checkModPermission(
          userData.authData.currentTeam.lt,
          'notifications'
        ) && (
          <CallNotifications
            newNotifications={newNotifications}
            setNewNotifications={setNewNotifications}
            unreadCount={unreadCount}
            setUnreadCount={setUnreadCount}
            fetchNewNotification={fetchNewNotification}
            newNotificationsError={newNotificationsError}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            isError={isError}
            setIsError={setIsError}
          />
        )}
      <NavDropdown
        title={notificationDropdown}
        align='end'
        id='basic-nav-dropdown'
        className={
          newNotifications && unreadCount > 0
            ? 'notification-dropdown new-notify'
            : 'notification-dropdown'
        }
      >
        {isLoading ? (
          <React.Fragment>
            <div className='notification-title'>
              <h4>{locale('messages:notification.Notifications')}</h4>
              <span>{unreadCount ? unreadCount : '0'}</span>
            </div>
            <div className='notification-list-outer'>
              <NavDropdown.Item>
                <SearchResultLoading />
              </NavDropdown.Item>
            </div>
          </React.Fragment>
        ) : userData &&
          userData.authData &&
          userData.authData.currentTeam &&
          !checkModPermission(
            userData.authData.currentTeam.lt,
            'notifications'
          ) ? (
          <React.Fragment>
            <div className='notification-title'>
              <h4>{locale('messages:notification.Notifications')}</h4>
              <span>{unreadCount ? unreadCount : '0'}</span>
            </div>
            <div className='notification-list-outer'>
              <h5 class='text-center border-all p-2 bg-light m-5 text-danger'>
                {locale(
                  'messages:notification.You do not have access to notifications feature in your current plan. Please upgrade your plan to view notifications.'
                )}
              </h5>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='notification-title'>
              <h4>{locale('messages:notification.Notifications')}</h4>
              <span>{unreadCount ? unreadCount : '0'}</span>
            </div>
            <div className='notification-list-outer'>
              {unreadCount === 0 && (
                <h5 className='m-2 p-3 text-center text-secondary text-uppercase'>
                  You don't have any new notifications right now.
                </h5>
              )}
              {newNotifications &&
                newNotifications.map((notification, idx) => (
                  <NavDropdown.Item
                    key={notification.id + idx}
                    onClick={() => handleNotification(notification)}
                  >
                    <Media>
                      <div
                        className={
                          'small-avtar ' +
                          avtarListClass[notification.notification_type]
                        }
                      >
                        <i
                          className={avtarList[notification.notification_type]}
                        ></i>
                      </div>
                      <Media.Body>
                        <div className='notification-title-with-time'>
                          <h6>
                            {getNotificationContent(
                              notification.data && notification.data.title
                                ? notification.data.title
                                : '',
                              locale
                            )}
                          </h6>
                          <span className='notification-time'>
                            {getTimeAgo(
                              notification.created_at,
                              currentCompanySetting
                            )}
                          </span>
                        </div>
                        <p>
                          {getNotificationContent(
                            notification.data && notification.data.description
                              ? notification.data.description
                              : '',
                            locale
                          )}
                        </p>
                      </Media.Body>
                    </Media>
                  </NavDropdown.Item>
                ))}
            </div>
            <div className='view-all-notification dropdown-item'>
              <NavDropdown.Item
                as={Link}
                to='/notifications'
              >
                {locale('messages:notification.View all')}
              </NavDropdown.Item>
            </div>
          </React.Fragment>
        )}
      </NavDropdown>
      <Modal
        className={'common modalLg'}
        show={showImportSummary}
        backdrop='static'
        keyboard={false}
        onHide={() => {
          setShowImportSummary(false);
        }}
      >
        <ImportSummary data={summaryData} />
      </Modal>
    </React.Fragment>
  );
};
